<template>
  <div>
    <!-- <SideMenu /> -->
    <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('loan-read'))"
      class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('loan-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                    employeeView ? "Your loan records" : "All employees' loan records"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('loan-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All employees' loan records" : "Your loan records" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('loan-read'))"
            class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-3' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-2 row pb-0">
              <span v-if="employee_name.toLowerCase() != 'all loans'" class="col-lg-5 col-md-5 col-12">Loans for {{
                  employee_name
              }}</span>
              <span v-else class="col-lg-5 col-md-5 col-12">All Loans</span>
              <v-row class="col-md-7 col-lg-7 col-12" style="float: right;">
                <!-- <v-col :offset-lg="employee_name.toLowerCase() != 'all loans' ? null : '2'"
                  :offset-md="employee_name.toLowerCase() != 'all loans' ? null : '2'" cols="6" lg="3" md="3"
                  class="pt-0 text-center"> -->
                <v-col :offset-lg="employee_name.toLowerCase() != 'all loans' ? '1' : '2'"
                  :offset-md="employee_name.toLowerCase() != 'all loans' ? '1' : '2'" cols="6" lg="3" md="3"
                  class="pt-0 text-center">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="dateRangeText"
                        placeholder="Select dates" solo dense readonly v-bind="attrs" v-on="on"
                        :messages="date_range.length == 0 ? '*Select date range' : date_range.length == 1 ? '*Please select an end date' : '*Showing results for ' + new Date(date_range[0]).toUTCString().substring(0, 16) + ' to ' + new Date(date_range[1]).toUTCString().substring(0, 16)">
                      </v-text-field>
                    </template>
                    <v-date-picker color="#069" range v-model="date_range"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" lg="4" md="4" class="pt-0 text-right">
                  <v-select class="remove-mb" :items="allEmployees" label="All Loans" dense solo
                    messages="*Select Employees" :full-width="true" v-model="employee_name"></v-select>
                </v-col>
                <v-col v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('loan-read'))"
                  cols="6" lg="3" xl="3" md="3" class="pt-0"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'">
                  <download-excel v-if="excel_filtered_loans.length >= 1" :data="json_data" :fields="json_fields"
                    :worksheet="employee_name.toLowerCase() != 'all loans' ? employee_name : 'All Employees'"
                    :name="'Loan History for ' + (employee_name.toLowerCase() != 'all loans' ? employee_name : 'All Employees') + '.xls'"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                    Export to Excel
                  </download-excel>
                  <button v-else style="cursor: disabled; opacity: 0.5;"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                    Export to Excel
                  </button>
                </v-col>
                <v-col
                  v-if="(employee_name.toLowerCase() != 'all loans' && (userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('loan-read'))))"
                  cols="6" lg="1" xl="1" md="1" class="pt-0">
                  <button @click="reset_filter()"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Reset
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div v-if="employee_name.toLowerCase() == 'all loans'" class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">Actual Loan</th>
                          <th scope="col" class="text-left">Outstanding Balance</th>
                          <th scope="col" class="text-left">Monthly Repayable Amount</th>
                          <th scope="col" class="text-left">Start Date</th>
                          <th scope="col" class="text-left">End Date</th>
                          <th scope="col" class="text-left">Duration</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-center">Approval Status</th>
                          <th scope="col" class="text-center">Repayment Status</th>
                          <th scope="col" class="text-center">Repayment History</th>
                          <th v-if="permissionIsAssigned('liquidation-read')" scope="col" class="text-center">
                            Liquidations</th>
                          <th scope="col" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="loan in filtered_loans" :key="loan.id">
                          <td class="text-left">
                            {{ get_employee_name(loan.employee_id) }}
                          </td>
                          <td class="text-left" scope="row">₦{{ (loan.actual_loan || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" scope="row">₦{{ (loan.loan_amount || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" scope="row">₦{{ (loan.monthly_repayable_amount || 0) |
                              number_with_commas
                          }}
                          </td>
                          <td class="text-left">
                            {{ new Date(loan.fromdate).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ new Date(loan.todate).toUTCString().substring(0, 16) }}
                          </td>
                          <!-- <td v-if="!!loan.fromdate && !!loan.todate" class="text-left">
                                {{ monthDiff(new Date(loan.fromdate), new Date(loan.todate)) === 1 ? '1 month' : (monthDiff(new Date(loan.fromdate), new Date(loan.todate)) || 0) + ' months' }}
                            </td>
                            <td class="text-left" v-else>
                              <i>Loading...</i>
                            </td> -->
                          <td class="text-left">
                            {{ loan.duration + ' ' }} {{ loan.duration == 1 ? 'month' : 'months' }}
                          </td>
                          <td class="text-left">
                            {{ loan.purpose }}
                          </td>
                          <td v-if="loan.status == 0">
                            <span class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="loan.status == 1">
                            <span class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else>
                            <span class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td v-if="loan.loan_amount == 0">
                            <span class="badge badge-success text-center text-white">COMPLETE</span>
                          </td>
                          <td v-else>
                            <span class="badge badge-warning text-center text-white">IN PROGRESS</span>
                          </td>
                          <td>
                            <button 
                            type="button"
                            class="btn btn-raised btn-sm ripple btn-raised-secondary text-white"
                            @click="launchHistoryModal(loan.id, true)">
                              View
                            </button>
                          </td>
                          <td v-if="permissionIsAssigned('liquidation-read')">
                            <button v-if="loan.status == 0 || loan.loan_amount == 0" type="button"
                              class="btn btn-raised btn-sm ripple btn-raised-secondary text-white"
                              style="opacity: 0.3; cursor: default;">
                              Manage
                            </button>
                            <button v-else @click="liquidate_loan_launch_2(loan)" type="button"
                              class="btn btn-raised btn-sm ripple btn-raised-secondary text-white">
                              Manage
                            </button>
                          </td>
                          <td style="padding-top: 13px;" class="employee-options">
                            <div class="row mt-0">
                              <i @click="view_comment(loan)" class="col-3 offset-1 py-0 text-left fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="loan.status != 1 && permissionIsAssigned('loan-approve')" @click="approve_loan(loan)"
                                class="col-3 py-0 text-left fal fa-check-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="(loan.status == 0 || loan.status == 1) && permissionIsAssigned('loan-reject')" @click="disapprove_loan(loan)"
                                class="col-3 py-0 text-left fal fa-times-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">Actual Loan</th>
                          <th scope="col" class="text-left">Outstanding Balance</th>
                          <th scope="col" class="text-left">Monthly Repayable Amount</th>
                          <th scope="col" class="text-left">Start Date</th>
                          <th scope="col" class="text-left">End Date</th>
                          <th scope="col" class="text-left">Duration</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-center">Approval Status</th>
                          <th scope="col" class="text-center">Repayment Status</th>
                          <th scope="col" class="text-center">Repayment History</th>
                          <th v-if="permissionIsAssigned('liquidation-read')" scope="col" class="text-center">
                            Liquidations</th>
                          <th scope="col" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="loan in filtered_loans" :key="loan.id">
                          <td class="text-left">
                            {{ get_employee_name(loan.employee_id) }}
                          </td>
                          <td class="text-left" scope="row">₦{{ (loan.actual_loan || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" scope="row">₦{{ (loan.loan_amount || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" scope="row">₦{{ (loan.monthly_repayable_amount || 0) |
                              number_with_commas
                          }}
                          </td>
                          <td class="text-left">
                            {{ new Date(loan.fromdate).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ new Date(loan.todate).toUTCString().substring(0, 16) }}
                          </td>
                          <!-- <td v-if="!!loan.fromdate && !!loan.todate" class="text-left">
                                {{ monthDiff(new Date(loan.fromdate), new Date(loan.todate)) === 1 ? '1 month' : (monthDiff(new Date(loan.fromdate), new Date(loan.todate)) || 0) + ' months' }}
                            </td>
                            <td class="text-left" v-else>
                              <i>Loading...</i>
                            </td> -->
                          <td class="text-left">
                            {{ loan.duration + ' ' }} {{ loan.duration == 1 ? 'month' : 'months' }}
                          </td>
                          <td class="text-left">
                            {{ loan.purpose }}
                          </td>
                          <td v-if="loan.status == 0">
                            <span class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="loan.status == 1">
                            <span class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else>
                            <span class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td v-if="loan.loan_amount == 0">
                            <span class="badge badge-success text-center text-white">COMPLETE</span>
                          </td>
                          <td v-else>
                            <span class="badge badge-warning text-center text-white">IN PROGRESS</span>
                          </td>
                          <td>
                            <button 
                            type="button"
                            class="btn btn-raised btn-sm ripple btn-raised-secondary text-white"
                            @click="launchHistoryModal(loan.id, true)">
                              View
                            </button>
                          </td>
                          <td v-if="permissionIsAssigned('liquidation-read')">
                            <button v-if="loan.status == 0 || loan.loan_amount == 0" type="button"
                              class="btn btn-raised btn-sm ripple btn-raised-secondary text-white"
                              style="opacity: 0.3; cursor: default;">
                              Manage
                            </button>
                            <button v-else @click="liquidate_loan_launch_2(loan)" type="button"
                              class="btn btn-raised btn-sm ripple btn-raised-secondary text-white">
                              Manage
                            </button>
                          </td>
                          <td style="padding-top: 13px;" class="employee-options">
                            <div class="row mt-0">
                              <i @click="view_comment(loan)" class="col-3 offset-1 py-0 text-left fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="loan.status != 1 && permissionIsAssigned('loan-approve')" @click="approve_loan(loan)"
                                class="col-3 py-0 text-left fal fa-check-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="(loan.status == 0 || loan.status == 1) && permissionIsAssigned('loan-reject')" @click="disapprove_loan(loan)"
                                class="col-3 py-0 text-left fal fa-times-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('loan-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                    employeeView ? "Your loan records" : "All employees' loan records"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('loan-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All employees' loan records" : "Your loan records" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-lg-5 pr-md-5">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != 1" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-0 row pb-0">
              <span class="col-lg-5 col-md-5 col-12">Your Loan Records</span>
              <!-- <span class="col-lg-5 col-md-5 col-12" v-else>Your Loan Records (No data to display)</span> -->
              <v-row class="col-md-7 col-lg-7 col-12">
                <v-col cols="5" lg="4" md="4"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
                  class="pt-0 pr-0">

                </v-col>
                <v-col cols="7" lg="4" md="4"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
                  class="pt-0 pr-0">

                </v-col>
                <v-col cols="6" lg="4" md="4" class="text-right pt-2">
                  <button @click="loan_apply = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 mt-0 text-white">
                    Apply for Loan
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Actual Loan</th>
                          <th scope="col" class="text-left">Outstanding Balance</th>
                          <th scope="col" class="text-left">Monthly Repayable Amount</th>
                          <th scope="col" class="text-left">Start Date</th>
                          <th scope="col" class="text-left">End Date</th>
                          <th scope="col" class="text-left">Duration</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-center">Approval Status</th>
                          <th scope="col" class="text-center">Repayment Status</th>
                          <th scope="col" class="text-center">Repayment History</th>
                          <th scope="col" class="text-center">Liquidations</th>
                          <th scope="col" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="loan in filtered_loans" :key="loan.id">
                          <td class="text-left" scope="row">₦{{ (loan.actual_loan || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" scope="row">₦{{ (loan.loan_amount || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" scope="row">₦{{ (loan.monthly_repayable_amount || 0) |
                              number_with_commas
                          }}
                          </td>
                          <td class="text-left">
                            {{ new Date(loan.fromdate).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ new Date(loan.todate).toUTCString().substring(0, 16) }}
                          </td>
                          <!-- <td v-if="!!loan.fromdate && !!loan.todate" class="text-left">
                                {{ monthDiff(new Date(loan.fromdate), new Date(loan.todate)) === 1 ? '1 month' : (monthDiff(new Date(loan.fromdate), new Date(loan.todate)) || 0) + ' months' }}
                            </td>
                            <td class="text-left" v-else>
                              <i>Loading...</i>
                            </td> -->
                          <td class="text-left">
                            {{ loan.duration + ' ' }} {{ loan.duration == 1 ? 'month' : 'months' }}
                          </td>
                          <td class="text-left">
                            {{ loan.purpose }}
                          </td>
                          <td v-if="loan.status == 0">
                            <span class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="loan.status == 1">
                            <span class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else>
                            <span class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td v-if="loan.loan_amount == 0">
                            <span class="badge badge-success text-center text-white">COMPLETE</span>
                          </td>
                          <td v-else>
                            <span class="badge badge-warning text-center text-white">IN PROGRESS</span>
                          </td>
                          <td>
                            <button 
                            type="button"
                            class="btn btn-raised btn-sm ripple btn-raised-secondary text-white"
                            @click="launchHistoryModal(loan.id, false)">
                              View
                            </button>
                          </td>
                          <td>
                            <button v-if="loan.status == 0 || loan.loan_amount == 0" type="button"
                              class="btn btn-raised btn-sm ripple btn-raised-secondary text-white"
                              style="opacity: 0.3; cursor: default;">
                              Manage
                            </button>
                            <button v-else @click="liquidate_loan_launch(loan)" type="button"
                              class="btn btn-raised btn-sm ripple btn-raised-secondary text-white">
                              Manage
                            </button>
                          </td>
                          <td style="padding-top: 13px;" class="employee-options">
                            <div class="row mt-0">
                              <!-- <i
                                    @click="view_comment(loan)"
                                    class="offset-lg-1 offset-md-1 col-3 py-0 text-center fal fa-message-dots"
                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                ></i> -->
                              <i v-if="loan.status == 1" class="col-6 py-0 text-center fal fa-edit"
                                style="color: #069 !important; font-size: 125%; opacity: 0.3; cursor: default;"></i>
                              <i v-else @click="make_current_loan(loan)" class="col-6 py-0 text-center fal fa-edit"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="loan.status == 1" class="col-6 py-0 text-center fal fa-trash-alt"
                                style="color: #069 !important; font-size: 125%; opacity: 0.3; cursor: default;"></i>
                              <i v-else @click="delete_loan(loan)" class="col-6 py-0 text-center fal fa-trash-alt"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <v-dialog v-model="loan_edit" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Loan</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Loan Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.loan_amount" placeholder="Enter the loan amount" solo
                      class="mt-2" dense type="number"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" md="6" sm="6" class="remove-hint">
                        <span style="color: #111d5e;">Monthly Repayment Amount <i class="fas fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field
                        v-model="formData.monthly_repayable_amount"
                        placeholder="Enter the monthly repayable amount"
                        solo
                        class="mt-2"
                        dense
                        type="number"
                        ></v-text-field>
                    </v-col> -->
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Start Date <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="editFormData.fromdate" placeholder="Select start date" solo class="mt-2" dense
                          readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker3" v-model="editFormData.fromdate" :min="
                        new Date(new Date().getTime())
                          .toISOString()
                          .substring(0, 10)
                      " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save3"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">End Date <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="editFormData.todate" placeholder="Select end date" solo class="mt-2" dense readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker4" v-model="editFormData.todate" :min="
                        new Date(new Date().getTime())
                          .toISOString()
                          .substring(0, 10)
                      " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save4"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Repayable Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field :disabled="true"
                      :value="'₦' + number_with_commas_2(edit_monthly_repayable_amount) + ' per month'" solo
                      class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Loan Duration <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field :disabled="true"
                      :value="monthDiff(new Date(editFormData.fromdate), new Date(editFormData.todate)) === 1 ? '1 month' : (monthDiff(new Date(editFormData.fromdate), new Date(editFormData.todate)) || 0) + ' months'"
                      solo class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Loan Purpose <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="editFormData.purpose" placeholder="Enter loan purpose" solo class="mt-2">
                    </v-textarea>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="loan_edit_submit ? null : update_loan_application()" style="width: 99%;"
                      type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ loan_edit_text }}
                      <v-icon :style="
                        !loan_edit_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="loan_edit = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loan_apply" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Apply for Loan</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Loan Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.loan_amount" placeholder="Enter the loan amount" solo class="mt-2"
                      dense type="number"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" md="6" sm="6" class="remove-hint">
                        <span style="color: #111d5e;">Monthly Repayment Amount <i class="fas fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field
                        v-model="formData.monthly_repayable_amount"
                        placeholder="Enter the monthly repayable amount"
                        solo
                        class="mt-2"
                        dense
                        type="number"
                        ></v-text-field>
                    </v-col> -->
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Start Date <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="formData.fromdate" placeholder="Select start date" solo class="mt-2" dense readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker" v-model="formData.fromdate" :min="
                        new Date(new Date().getTime())
                          .toISOString()
                          .substring(0, 10)
                      " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">End Date <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="formData.todate" placeholder="Select end date" solo class="mt-2" dense readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker2" v-model="formData.todate" :min="
                        new Date(new Date().getTime())
                          .toISOString()
                          .substring(0, 10)
                      " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save2"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Repayable Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field :disabled="true"
                      :value="'₦' + number_with_commas_2(apply_monthly_repayable_amount) + ' per month'" solo
                      class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Loan Duration <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field :disabled="true"
                      :value="monthDiff(new Date(formData.fromdate), new Date(formData.todate)) === 1 ? '1 month' : (monthDiff(new Date(formData.fromdate), new Date(formData.todate)) || 0) + ' months'"
                      solo class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Loan Purpose <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="formData.purpose" placeholder="Enter loan purpose" solo class="mt-2">
                    </v-textarea>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="loan_apply_submit ? null : send_loan_application()" style="width: 99%;"
                      type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ loan_apply_text }}
                      <v-icon :style="
                        !loan_apply_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="loan_apply = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="flex-grow-1"></div>
    <v-dialog v-model="comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ approval ? 'Approve' : 'Reject' }} loan request with comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">{{ approval ? 'Approval' : 'Rejection' }} Comment <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="comment"
                      :placeholder="(userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('loan-approve'))) ? 'Loan Comment' : 'No comment'"
                      solo class="mt-2" :disabled="userType != 1 ? true : false"></v-textarea>
                  </v-col>
                  <v-col
                    v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('loan-approve'))"
                    cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="add_comment_submit ? null : loan_action()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_comment_text }}
                      <v-icon :style="
                        !add_comment_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="comment_dialog_2" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ approval_2 ? 'Approve' : 'Reject' }} loan liquidation request with comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">{{ approval_2 ? 'Approval' : 'Rejection' }} Comment <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="comment_2"
                      :placeholder="(userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('liquidation-approve'))) ? 'Loan liquidation Comment' : 'No comment'"
                      solo class="mt-2" :disabled="userType != 1 ? true : false"></v-textarea>
                  </v-col>
                  <v-col
                    v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('liquidation-approve'))"
                    cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button
                      @click="add_comment_submit_2 ? null : (approval_2 ? approve_liquidation_request() : disapprove_liquidation_request())"
                      style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_comment_text_2 }}
                      <v-icon :style="
                        !add_comment_submit_2
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="comment_dialog_2 = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loan_comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Loan Comments</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-left">Date Sent</th>
                  <th class="text-left">Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="comment in all_loan_comments" :key="comment.id">
                  <td class="text-left">
                    {{ new Date(comment.created_at).toUTCString().substring(0, 25) }}
                  </td>
                  <td class="text-left">
                    {{ comment.comments }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="loan_comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="liquidation_requests_dialog" max-width="600px">
      <v-card>
        <v-card-title class="d-block">
          <span class="headline">Liquidation requests</span>
          <span class="headline float-right">
            <button @click="add_request_launcher()" style="float: right;" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              Add Request
            </button>
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th class="text-left">Liquidation Type</th>
                  <th class="text-left">Flexibility</th>
                  <th class="text-left">Liquidation Amount</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(LR, index) in employee_liquidation_requests" :key="index">
                  <td class="text-left">
                    <span v-if="LR.liquidation_type == 1">Salary</span>
                    <span v-if="LR.liquidation_type == 2">Cash</span>
                  </td>
                  <td class="text-left">
                    <span v-if="LR.flexibility == 1">All</span>
                    <span v-if="LR.flexibility == 2">Specified amount</span>
                  </td>
                  <td class="text-left">
                    ₦{{ (LR.liquidateloan_amount || 0) | number_with_commas }}
                  </td>
                  <td v-if="LR.status == 0">
                    <span class="badge badge-warning text-center text-white">PENDING</span>
                  </td>
                  <td v-else-if="LR.status == 1">
                    <span class="badge text-center badge-success">APPROVED</span>
                  </td>
                  <td v-else>
                    <span class="badge text-center badge-danger">REJECTED</span>
                  </td>
                  <td style="padding-top: 13px;" class="employee-options">
                    <div class="row mt-0">
                      <i v-if="LR.status == 1" class="col-6 py-0 text-center fal fa-edit"
                        style="color: #069 !important; font-size: 125%; opacity: 0.3; cursor: default;"></i>
                      <i v-else @click="update_request_launcher(LR)" class="col-6 py-0 text-center fal fa-edit"
                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                      <i v-if="LR.status == 1" class="col-6 py-0 text-center fal fa-trash-alt"
                        style="color: #069 !important; font-size: 125%; opacity: 0.3; cursor: default;"></i>
                      <i v-else @click="delete_liquidation_request(LR)" class="col-6 py-0 text-center fal fa-trash-alt"
                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="liquidation_requests_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="liquidation_requests_dialog_2" max-width="600px">
      <v-card>
        <v-card-title class="d-block">
          <span class="headline">Liquidation requests</span>
          <!-- <span class="headline float-right">
            <button
              @click="add_request_launcher()"
              style="float: right;"
              type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
            >
              Add Request
            </button>
          </span> -->
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th class="text-left">Liquidation Type</th>
                  <th class="text-left">Flexibility</th>
                  <th class="text-left">Liquidation Amount</th>
                  <th class="text-left">Status</th>
                  <th v-if="permissionIsAssigned('liquidation-approve') || permissionIsAssigned('liquidation-reject')"
                    class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(LR, index) in employee_liquidation_requests" :key="index">
                  <td class="text-left">
                    <span v-if="LR.liquidation_type == 1">Salary</span>
                    <span v-if="LR.liquidation_type == 2">Cash</span>
                  </td>
                  <td class="text-left">
                    <span v-if="LR.flexibility == 1">All</span>
                    <span v-if="LR.flexibility == 2">Specified amount</span>
                  </td>
                  <td class="text-left">
                    ₦{{ (LR.liquidateloan_amount || 0) | number_with_commas }}
                  </td>
                  <td v-if="LR.status == 0">
                    <span class="badge badge-warning text-center text-white">PENDING</span>
                  </td>
                  <td v-else-if="LR.status == 1">
                    <span class="badge text-center badge-success">APPROVED</span>
                  </td>
                  <td v-else>
                    <span class="badge text-center badge-danger">REJECTED</span>
                  </td>
                  <td v-if="permissionIsAssigned('liquidation-approve') || permissionIsAssigned('liquidation-reject')"
                    style="padding-top: 13px;" class="employee-options">
                    <div class="row mt-0">
                      <i v-if="permissionIsAssigned('liquidation-approve')"
                        @click="launch_approve_liquidation_request(LR)" class="col-6 py-0 text-left fal fa-check-circle"
                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                      <i v-if="permissionIsAssigned('liquidation-reject')"
                        @click="launch_disapprove_liquidation_request(LR)"
                        class="col-6 py-0 text-left fal fa-times-circle"
                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="liquidation_requests_dialog_2 = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="add_request_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Create liquidation request</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Liquidation Type <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="addRequestForm.liquidation_type" item-color="#069" :items="['Salary', 'Cash']"
                      placeholder="Select liquidation type" solo class="mt-2" color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Flexibility <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="addRequestForm.flexibility" item-color="#069" :items="['All', 'Specify amount']"
                      placeholder="Select flexibility" solo class="mt-2" color="#069"></v-select>
                  </v-col>
                  <v-col v-if="flexibility_create == 2" cols="12" sm="12" md="12" class="remove-hint">
                    <span style="color: #111d5e;">Liquidatation Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="addRequestForm.liquidateloan_amount" placeholder="Enter liquidation" solo
                      number class="mt-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                    <button @click="add_liquidation_submit ? null : add_liquidation_request()" style="width: 100%;"
                      type="button" class="btn btn-secondary m-1 ripple">
                      {{ add_liquidation_text }}
                      <v-icon :style="
                        !add_liquidation_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="add_request_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="update_request_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Update liquidation request</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Liquidation Type <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="editRequestForm.liquidation_type" item-color="#069" :items="['Salary', 'Cash']"
                      placeholder="Select liquidation type" solo class="mt-2" color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Flexibility <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="editRequestForm.flexibility" item-color="#069" :items="['All', 'Specify amount']"
                      placeholder="Select flexibility" solo class="mt-2" color="#069"></v-select>
                  </v-col>
                  <v-col v-if="flexibility_update == 2" cols="12" sm="12" md="12" class="remove-hint">
                    <span style="color: #111d5e;">Liquidatation Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editRequestForm.liquidateloan_amount" placeholder="Enter liquidation" solo
                      number class="mt-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                    <button @click="update_liquidation_submit ? null : update_liquidation_request()"
                      style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                      {{ update_liquidation_text }}
                      <v-icon :style="
                        !update_liquidation_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="update_request_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="repaymentHistory.dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">Repayment History</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-left">Employee</th>
                  <th class="text-left">Actual Loan Amount</th>
                  <th class="text-left">Amount Paid</th>
                  <th class="text-left">Monthly Repayable Amount</th>
                  <th class="text-left">Loan Balance</th>
                  <th class="text-left">Date Paid</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(repayment, index) in filteredLoanRepayments" :key="'repayment-' + (index + 1)">
                  <td v-if="singleEmployeeObject(repayment.employee_id)" class="text-left">
                    {{ singleEmployeeObject(repayment.employee_id).employee_fristname + ' ' + singleEmployeeObject(repayment.employee_id).employee_lastname }}
                  </td>
                  <td v-else class="text-left">
                    <i>Employee not found</i>
                  </td>
                  <td class="text-left">
                    ₦{{ repayment.actual_loan | number_with_commas }}
                  </td>
                  <td class="text-left">
                    ₦{{ repayment.amount | number_with_commas }}
                  </td>
                  <td class="text-left">
                    ₦{{ repayment.monthly_repayable_amount | number_with_commas }}
                  </td>
                  <td class="text-left">
                    ₦{{ repayment.loan_balance | number_with_commas }}
                  </td>
                  <td class="text-left">
                    {{ 
                      new Date(repayment.created_at)
                      .toUTCString()
                      .substring(0, 16)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="repaymentHistory.dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      repaymentHistory: {
        forCompany: true,
        dialog: false,
        loanId: ''
      },
      current_liquidation_request: null,
      add_liquidation_text: "Submit",
      add_liquidation_submit: false,
      update_liquidation_text: "Update",
      update_liquidation_submit: false,
      addRequestForm: {
        liquidation_type: '',
        flexibility: '',
        liquidateloan_amount: ''
      },
      editRequestForm: {
        id: '',
        liquidation_type: '',
        flexibility: '',
        liquidateloan_amount: ''
      },
      add_request_dialog: false,
      update_request_dialog: false,
      liquidation_requests_dialog: false,
      liquidation_requests_dialog_2: false,
      employeeView: true,
      date_range: [],
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      formData: {
        loan_amount: "",
        fromdate: "",
        todate: "",
        purpose: ""
      },
      editFormData: {
        id: null,
        loan_amount: "",
        fromdate: "",
        todate: "",
        purpose: ""
      },
      loan_apply_text: "Submit",
      loan_apply_submit: false,
      loan_edit: false,
      loan_apply: false,
      loan_edit_text: "Update",
      loan_edit_submit: false,
      employee_name: "All Loans",
      employee: null,
      day_selected: "",
      view: "date",
      monthSelected: "",
      yearSelected: "",
      checkingDetails: null,
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      firstYear: "",
      allEmployees: [],
      allEmployees2: [],
      current_loan: {
        id: 0
      },
      approval: false,
      approval_2: false,
      add_comment_text: 'Submit',
      add_comment_submit: false,
      add_comment_text_2: 'Submit',
      add_comment_submit_2: false,
      comment: '',
      comment_2: '',
      comment_dialog: false,
      comment_dialog_2: false,
      loan_comment_dialog: false,
      all_loan_comments: []
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    },
    menu3(val) {
      val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
    },
    menu4(val) {
      val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authCompanyEmployees",
      "authEmployeeDataForCompany",
      "true_employees",
      "authCompanyLoans",
      "authLiquidationRequests",
      "authEmployeeLoanRepayments",
      "authCompanyLoanRepayments"
    ]),
    filteredLoanRepayments() {
      if (this.repaymentHistory.forCompany === true) {
        return this.filteredCompanyLoanRepayments;
      }
      return this.filteredEmployeeLoanRepayments;
    },
    filteredCompanyLoanRepayments() {
      let _this = this, result = [];
      result = _this.authCompanyLoanRepayments.filter(repayment => repayment.loan_id == _this.repaymentHistory.loanId);
      return result;
    },
    filteredEmployeeLoanRepayments() {
      let _this = this, result = [];
      result = _this.authEmployeeLoanRepayments.filter(repayment => repayment.loan_id == _this.repaymentHistory.loanId);
      return result;
    },
    liquidation_type_create() {
      let _this = this, result = null;
      if (_this.addRequestForm.liquidation_type.toLowerCase() == 'salary') {
        result = 1
      }
      if (_this.addRequestForm.liquidation_type.toLowerCase() == 'cash') {
        result = 2
      }
      return result;
    },
    flexibility_create() {
      let _this = this, result = null;
      if (_this.addRequestForm.flexibility.toLowerCase() == 'all') {
        result = 1
      }
      if (_this.addRequestForm.flexibility.toLowerCase() == 'specify amount') {
        result = 2
      }
      return result;
    },
    liquidation_type_update() {
      let _this = this, result = null;
      if (_this.editRequestForm.liquidation_type.toLowerCase() == 'salary') {
        result = 1
      }
      if (_this.editRequestForm.liquidation_type.toLowerCase() == 'cash') {
        result = 2
      }
      return result;
    },
    flexibility_update() {
      let _this = this, result = null;
      if (_this.editRequestForm.flexibility.toLowerCase() == 'all') {
        result = 1
      }
      if (_this.editRequestForm.flexibility.toLowerCase() == 'specify amount') {
        result = 2
      }
      return result;
    },
    employeeLoans() {
      let _this = this, result = [];
      result = _this.authCompanyLoans.filter(loan => loan.employee_id == _this.authEmployeeDataForCompany.id || loan.employee_id == _this.employee_id)
      return result
    },
    apply_monthly_repayable_amount() {
      let _this = this, result = 0, duration = _this.monthDiff(new Date(_this.formData.fromdate), new Date(_this.formData.todate));
      if (_this.formData.fromdate && _this.formData.todate && _this.formData.loan_amount) {
        result = parseInt(_this.formData.loan_amount) / (duration || 1);
      }
      return Math.ceil(result);
    },
    filtered_loans() {
      let _this = this;
      let loans = _this.authCompanyLoans;
      if (_this.userType != 1) {
        if (_this.employeeView) {
          loans = _this.authCompanyLoans.filter(loan => {
            return loan.employee_id == _this.authEmployeeDataForCompany.id
          });
        }
      }
      if (_this.employee_name.toLowerCase() != 'all loans') {
        loans = loans.filter(loan => {
          return loan.employee_id == _this.get_employee_id(_this.employee_name)
        });
      }
      if (_this.date_range.length == 2) {
        loans = loans.filter(loan => {
          return ((new Date(loan.fromdate).getTime() >= new Date(_this.date_range[0]).getTime()) && (new Date(loan.fromdate).getTime() <= new Date(_this.date_range[1]).getTime())) || ((new Date(loan.todate).getTime() >= new Date(_this.date_range[0]).getTime()) && (new Date(loan.todate).getTime() <= new Date(_this.date_range[1]).getTime()))
        });
      }
      return loans;
    },
    edit_monthly_repayable_amount() {
      let _this = this, result = 0, duration = _this.monthDiff(new Date(_this.editFormData.fromdate), new Date(_this.editFormData.todate));
      if (_this.editFormData.fromdate && _this.editFormData.todate && _this.editFormData.loan_amount) {
        result = parseInt(_this.editFormData.loan_amount) / (duration || 1);
      }
      return Math.ceil(result);
    },
    dateRangeText() {
      if (this.date_range) {
        return this.date_range.join(' ~ ')
      }
      return null
    },
    json_data: function () {
      return this.excel_filtered_loans;
    },
    json_fields: function () {
      let _this = this;
      if (_this.employee_name.toLowerCase() != 'all loans') {
        return {
          "Currency": "currency",
          "Loan Amount": "loan_amount",
          "Monthly Repayable Amount": "monthly_repayable_amount",
          "Start Date": "fromdate",
          "End Date": "todate",
          "Duration": "return_date",
          "Purpose": "purpose",
          "Status": "status"
        }
      } else {
        return {
          "Employee": "employee",
          "Currency": "currency",
          "Loan Amount": "loan_amount",
          "Monthly Repayable Amount": "monthly_repayable_amount",
          "Start Date": "fromdate",
          "End Date": "todate",
          "Duration": "return_date",
          "Purpose": "purpose",
          "Status": "status"
        }
      }

    },
    excel_filtered_loans() {
      let _this = this;
      return _this.filtered_loans.map(l => {
        return {
          employee: _this.get_employee_name(l.employee_id),
          currency: 'NGN (Nigerian naira)',
          loan_amount: l.loan_amount,
          monthly_repayable_amount: l.monthly_repayable_amount,
          fromdate: l.fromdate,
          todate: l.todate,
          return_date: _this.monthDiff(new Date(l.fromdate), new Date(l.todate)) === 1 ? '1 month' : (_this.monthDiff(new Date(l.fromdate), new Date(l.todate)) || 0) + ' months',
          purpose: l.purpose,
          status: l.status == 0 ? 'Pending' : l.status == 1 ? 'Approved' : 'Rejected'
        }
      })
    },
    assets_url() {
      return ASSETS_URL;
    },
    employee_id: function () {
      var t = this;
      return t.true_employees && t.employee_name.toLowerCase() != 'all loans'
        ? t.true_employees.filter(function (e) {
          return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
        })[0].id
        : null;
    },
    employee_liquidation_requests() {
      let _this = this, result = [];
      result = _this.authLiquidationRequests.filter(lr => {
        return lr.loan_id == _this.current_loan.id
      });
      return result;
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_company_loans",
      "fetch_all_liquidation_requests",
      "fetch_company_loan_repayments",
      "fetch_employee_loan_repayments"
    ]),
    singleEmployeeObject(id) {
        let _this = this, result = null;
        if (!id) {
            return result;
        }
        if (_this.authCompanyEmployees.length >= 1) {
            result = _this.authCompanyEmployees.filter(te => te.id == id)[0];
        }
        return result;
    },
    launchHistoryModal(loanId, forCompany) {
      let _this = this;
      _this.repaymentHistory.loanId = loanId;
      _this.repaymentHistory.forCompany = forCompany;
      _this.repaymentHistory.dialog = true;
    },
    get_employee_id(employee_name) {
        let _this = this,
            result = [],
            value = null;
        result = _this.true_employees.filter((te) => te.employee_fristname + " " + te.employee_lastname == employee_name);
        if (result.length) {
            value = result[0].id;
        }
        return value;
    },
    get_liquidation_type(type) {
      if (type == 1) {
        return 'Salary'
      } else {
        return 'Cash'
      }
    },
    get_flexibility(flexibility) {
      if (flexibility == 1) {
        return 'All'
      } else {
        return 'Specify amount'
      }
    },
    number_with_commas_2(t) {
      return t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    update_liquidation_request() {
      let _this = this;
      _this.update_liquidation_text = "";
      _this.update_liquidation_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}editliquidationrequest`, {
          liquidationrequest_id: _this.editRequestForm.id,
          loan_id: _this.current_loan.id,
          employee_id: _this.authEmployeeDataForCompany.id || _this.adminEmployeeData.id,
          company_id: _this.authEmployeeDataForCompany.company_id || _this.authCompanyData.id,
          liquidation_type: _this.liquidation_type_update,
          flexibility: _this.flexibility_update,
          liquidateloan_amount: _this.flexibility_update == 1 ? _this.current_loan.loan_amount : _this.editRequestForm.liquidateloan_amount
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Liquidation request updated successfully"
          });
          // FETCH EMPLOYEE IN PROJECT
          _this.fetch_all_liquidation_requests();
          _this.update_request_dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.liquidation_type ? errors.liquidation_type[0] : null
                ||
                errors.flexibility ? errors.flexibility[0] : null
                  ||
                  errors.liquidateloan_amount ? errors.liquidateloan_amount[0] : null) : null
                  || error.response.data.error || error.response.data.message || "The liquidation request couldn't be updated"
          });
        })
        .then(function () {
          _this.update_liquidation_submit = false;
          _this.update_liquidation_text = "Update";
        });
    },
    add_liquidation_request() {
      let _this = this;
      _this.add_liquidation_text = "";
      _this.add_liquidation_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}liquidationrequest`, {
          loan_id: _this.current_loan.id,
          employee_id: _this.authEmployeeDataForCompany.id || _this.adminEmployeeData.id,
          company_id: _this.authEmployeeDataForCompany.company_id || _this.authCompanyData.id,
          liquidation_type: _this.liquidation_type_create,
          flexibility: _this.flexibility_create,
          liquidateloan_amount: _this.flexibility_create == 1 ? _this.current_loan.loan_amount : _this.addRequestForm.liquidateloan_amount
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Liquidation request added successfully"
          });
          // FETCH EMPLOYEE IN PROJECT
          _this.fetch_all_liquidation_requests();
          _this.add_request_dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.liquidation_type ? errors.liquidation_type[0] : null
                ||
                errors.flexibility ? errors.flexibility[0] : null
                  ||
                  errors.liquidateloan_amount ? errors.liquidateloan_amount[0] : null) : null
                  || error.response.data.error || error.response.data.message || "The liquidation request couldn't be added"
          });
        })
        .then(function () {
          _this.add_liquidation_submit = false;
          _this.add_liquidation_text = "Submit";
        });
    },
    add_request_launcher() {
      let _this = this;
      _this.liquidation_requests_dialog = false;
      _this.add_request_dialog = true;
    },
    update_request_launcher(liquidationRequest) {
      let _this = this;
      _this.editRequestForm.id = liquidationRequest.id;
      _this.editRequestForm.liquidation_type = _this.get_liquidation_type(liquidationRequest.liquidation_type);
      _this.editRequestForm.flexibility = _this.get_flexibility(liquidationRequest.flexibility);
      _this.editRequestForm.liquidateloan_amount = liquidationRequest.liquidateloan_amount;
      _this.liquidation_requests_dialog = false;
      _this.update_request_dialog = true;
    },
    liquidate_loan_launch(loan) {
      let _this = this;
      _this.current_loan = loan;
      _this.liquidation_requests_dialog = true;
    },
    liquidate_loan_launch_2(loan) {
      let _this = this;
      _this.current_loan = loan;
      _this.liquidation_requests_dialog_2 = true;
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    reset_filter() {
      this.employee_name = 'All Loans';
      this.date_range = '';
    },
    loan_action() {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.add_comment_text = '';
      e.add_comment_submit = true;
      if (e.approval) {
        axios
          .post(`${BASE_URL}approveloan`, {
            company_id: e.authCompanyData.id,
            employee_id: e.current_loan.employee_id,
            loan_id: e.current_loan.id,
            comment: e.comment
          })
          .then(function () {
            a.fire({ icon: "success", title: "Loan approved successfully" });
            e.comment = '';
            e.comment_dialog = false;
          })
          .catch(function () {
            a.fire({ icon: "error", title: "Loan couldn't be approved" });
          })
          .then(function () {
            e.fetch_all_company_loans();
            e.add_comment_submit = false;
            e.add_comment_text = 'Submit';
          });
      } else {
        axios
          .post(`${BASE_URL}rejectloan`, {
            company_id: e.authCompanyData.id,
            employee_id: e.current_loan.employee_id,
            loan_id: e.current_loan.id,
            comment: e.comment
          })
          .then(function () {
            a.fire({ icon: "success", title: "Loan rejected successfully" });
            e.comment = '';
            e.comment_dialog = false;
          })
          .catch(function () {
            a.fire({ icon: "error", title: "Loan couldn't be rejected" });
          })
          .then(function () {
            e.fetch_all_company_loans();
            e.add_comment_submit = false;
            e.add_comment_text = 'Submit';
          });
      }
    },
    approve_loan(l) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to approve this loan?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Approve",
        })
        .then(function (s) {
          e.approval = true;
          e.current_loan = l;
          s.isConfirmed ? e.comment_dialog = true : '';
        });
    },
    disapprove_loan(l) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to reject this loan?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Reject",
        })
        .then(function (s) {
          e.approval = false;
          e.current_loan = l;
          s.isConfirmed ? e.comment_dialog = true : '';
        });
    },
    save: function (t) {
      this.$refs.menu.save(t);
    },
    save2: function (t) {
      this.$refs.menu2.save(t);
    },
    save3: function (t) {
      this.$refs.menu3.save(t);
    },
    save4: function (t) {
      this.$refs.menu4.save(t);
    },
    make_current_loan(loan) {
      this.editFormData.id = loan.id;
      this.editFormData.loan_amount = loan.loan_amount;
      this.editFormData.fromdate = loan.fromdate;
      this.editFormData.todate = loan.todate;
      this.editFormData.purpose = loan.purpose;
      this.loan_edit = true;
    },
    view_comment() {
      // let _this = this;
      // return;
    },
    hasHistory: function () {
      return window.history.length > 2;
    },
    launch_approve_liquidation_request(liquidationRequest) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to approve this loan liquidation request?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Approve",
        })
        .then(function (s) {
          e.approval_2 = true;
          e.current_liquidation_request = liquidationRequest;
          s.isConfirmed ? e.comment_dialog_2 = true : '';
        });
    },
    launch_disapprove_liquidation_request(liquidationRequest) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to reject this loan liquidation request?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Reject",
        })
        .then(function (s) {
          e.approval_2 = false;
          e.current_liquidation_request = liquidationRequest;
          s.isConfirmed ? e.comment_dialog_2 = true : '';
        });
    },
    approve_liquidation_request() {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.add_comment_text_2 = ''
      e.add_comment_submit_2 = true
      axios
        .post(`${BASE_URL}approveliquidation`, {
          loan_id: e.current_loan.id,
          liquidation_id: e.current_liquidation_request.id,
          company_id: e.authCompanyData.id,
          employee_id: e.current_liquidation_request.employee_id,
          comment: e.comment_2
        })
        .then(function () {
          a.fire({ icon: "success", title: "Liquidation request approved successfully" });
        })
        .catch(function () {
          a.fire({ icon: "error", title: "Liquidation request couldn't be approved" });
        })
        .then(function () {
          e.fetch_all_company_loans();
          e.fetch_all_liquidation_requests();
          e.liquidation_requests_dialog_2 = false;
          e.comment_dialog_2 = false;
          e.comment_2 = '';
          e.add_comment_submit_2 = false;
          e.add_comment_text_2 = 'Submit';
        });
    },
    disapprove_liquidation_request() {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.add_comment_text_2 = ''
      e.add_comment_submit_2 = true
      axios
        .post(`${BASE_URL}rejectliquidation`, {
          loan_id: e.current_loan.id,
          liquidation_id: e.current_liquidation_request.id,
          company_id: e.authCompanyData.id,
          employee_id: e.current_liquidation_request.employee_id,
          comment: e.comment_2
        })
        .then(function () {
          a.fire({ icon: "success", title: "Liquidation request rejected successfully" });
        })
        .catch(function () {
          a.fire({ icon: "error", title: "Liquidation request couldn't be rejected" });
        })
        .then(function () {
          e.fetch_all_company_loans();
          e.fetch_all_liquidation_requests();
          e.liquidation_requests_dialog_2 = false;
          e.comment_dialog_2 = false;
          e.comment_2 = '';
          e.add_comment_submit_2 = false;
          e.add_comment_text_2 = 'Submit';
        });
    },
    delete_liquidation_request(liquidationRequest) {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.$swal
        .fire({
          title: "Are you sure you want to delete this liquidation request?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Delete",
        })
        .then(function (s) {
          s.isConfirmed &&
            axios
              .post(`${BASE_URL}deleteliquidationrequest`, {
                ids: [
                  {
                    id: liquidationRequest.id
                  }
                ]
              })
              .then(function () {
                a.fire({ icon: "success", title: "Liquidation request deleted successfully" });
              })
              .catch(function () {
                a.fire({ icon: "error", title: "Liquidation request couldn't be deleted" });
              })
              .then(function () {
                e.fetch_all_liquidation_requests();
              });
        });
    },
    delete_loan(loan) {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.$swal
        .fire({
          title: "Are you sure you want to delete this loan record?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Delete",
        })
        .then(function (s) {
          s.isConfirmed &&
            axios
              .post(`${BASE_URL}deleteloan`, {
                ids: [
                  {
                    id: loan.id
                  }
                ]
              })
              .then(function () {
                a.fire({ icon: "success", title: "Loan deleted successfully" });
              })
              .catch(function () {
                a.fire({ icon: "error", title: "Loan couldn't be deleted" });
              })
              .then(function () {
                e.fetch_all_company_loans();
              });
        });
    },
    send_loan_application: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      let form_data = new FormData();
      form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
      form_data.append("employee_id", t.authEmployeeDataForCompany.id);
      form_data.append("loan_amount", t.formData.loan_amount);
      form_data.append("travel_purpose", t.formData.travel_purpose);
      form_data.append("monthly_repayable_amount", t.apply_monthly_repayable_amount);
      form_data.append("fromdate", t.formData.fromdate);
      form_data.append("todate", t.formData.todate);
      form_data.append("duration", t.monthDiff(new Date(t.formData.fromdate), new Date(t.formData.todate)) || 1);
      form_data.append("purpose", t.formData.purpose);

      t.loan_apply_submit ||
        ((t.loan_apply_text = ""),
          (t.loan_apply_submit = !0),
          axios
            .post(`${BASE_URL}loanrequest`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              e.fire({ icon: "success", title: "Loan request sent successfully" }),
                // ABOVE SHOULD FETCH all loans
                (t.formData.loan_amount = ""),
                (t.formData.fromdate = ""),
                (t.formData.todate = ""),
                (t.formData.duration = ""),
                (t.formData.purpose = "");
              t.fetch_all_company_loans();
              t.loan_apply = false;
            })
            .catch(function () {
              e.fire({ icon: "error", title: "Loan request couldn't be sent" });
            })
            .then(function () {
              (t.loan_apply_submit = !1), (t.loan_apply_text = "Submit");
            }));
    },
    update_loan_application: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      let form_data = new FormData();
      form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
      form_data.append("employee_id", t.authEmployeeDataForCompany.id);
      form_data.append("loan_id", t.editFormData.id);
      form_data.append("loan_amount", t.editFormData.loan_amount);
      form_data.append("travel_purpose", t.editFormData.travel_purpose);
      form_data.append("monthly_repayable_amount", t.edit_monthly_repayable_amount);
      form_data.append("fromdate", t.editFormData.fromdate);
      form_data.append("todate", t.editFormData.todate);
      form_data.append("duration", t.monthDiff(new Date(t.editFormData.fromdate), new Date(t.editFormData.todate)) || 1);
      form_data.append("purpose", t.editFormData.purpose);

      t.loan_edit_submit ||
        ((t.loan_edit_text = ""),
          (t.loan_edit_submit = !0),
          axios
            .post(`${BASE_URL}editloanrequest`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              e.fire({ icon: "success", title: "Loan request updated successfully" }),
                // ABOVE SHOULD FETCH all loans
                (t.editFormData.loan_amount = ""),
                (t.editFormData.fromdate = ""),
                (t.editFormData.todate = ""),
                (t.editFormData.duration = ""),
                (t.editFormData.purpose = "");
              t.fetch_all_company_loans();
              t.loan_edit = false;
            })
            .catch(function () {
              e.fire({ icon: "error", title: "Loan request couldn't be updated" });
            })
            .then(function () {
              (t.loan_edit_submit = !1), (t.loan_edit_text = "Update");
            }));
    },
    get_employee_name: function (t) {
      var e = this;
      if (e.true_employees) {
        var a = e.true_employees.filter(function (e) {
          return e.id == t;
        })[0];
        return a ? a.employee_fristname + " " + a.employee_lastname : 'Not found';
      }
      return "";
    },
  },
  mounted() {
    document.title = "Kylian ERP - Loan Management";
    1 != this.userType && (document.title = "Kylian ERP - Loan Management");
    var t = this;
    t.allEmployees.push('All Loans')
    t.userType &&
      t.true_employees.forEach(function (e) {
        t.allEmployees.push(e.employee_fristname + " " + e.employee_lastname), t.allEmployees2.push(e.employee_fristname + " " + e.employee_lastname);
      });
    this.fetch_all_company_loans();
    this.fetch_all_liquidation_requests();
    this.fetch_company_loan_repayments();
    this.fetch_employee_loan_repayments();
  },
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
