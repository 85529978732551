<template>
  <div
    class="tab-pane fade"
    id="list-settings11-role-mgt"
    role="tabpanel"
    aria-labelledby="list-settings-list11-role-mgt"
  >
    <h4>
      <div class="row">
        <div class="col-lg-6" style="align-self: center;">
          Roles Management
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-3 offset-9 py-4">
              <button
                v-if="create_custom_role_form"
                @click="create_custom_role_form = false"
                style="float: right;"
                type="button"
                class="btn btn-sm btn-danger m-1 text-white btn-raised ripple"
              >
                Cancel
              </button>
              <button
                v-else
                @click="create_custom_role_form = true"
                style="float: right;"
                type="button"
                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
              >
                Create Role
              </button>
            </div>
          </div>
        </div>
      </div>
    </h4>
    <div v-if="create_custom_role_form" class="main-content mt-0 px-0">
      <div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;"
                  >Name
                  <i
                    class="fal fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                  ></i
                ></span>
                <v-text-field
                  v-model="custom_role_creation_data.name"
                  placeholder="Enter the role name"
                  solo
                  class="mt-2"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;"
                  >Role Display Name
                  <i
                    class="fal fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                  ></i
                ></span>
                <v-text-field
                  v-model="custom_role_creation_data.display_name"
                  placeholder="Enter role display name"
                  solo
                  class="mt-2"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;"
                  >Role Description
                  <i
                    class="fal fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                  ></i
                ></span>
                <v-text-field
                  v-model="custom_role_creation_data.description"
                  placeholder="Enter Role description"
                  solo
                  class="mt-2"
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="4"
                offset-md="4"
                offset-sm="4"
                class="remove-hint"
              >
                <button
                  @click="
                    custom_role_create_submit ? null : create_custom_role()
                  "
                  style="width: 99%;"
                  type="button"
                  class="btn btn-secondary m-1 text-white btn-raised ripple"
                >
                  {{ custom_role_create_text }}
                  <v-icon
                    :style="
                      !custom_role_create_submit
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                    "
                  >
                    fal fa-circle-notch fa-spin
                  </v-icon>
                </button>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
    </div>
    <div v-else class="main-content mt-0 px-0">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="text-left">
                  Role Name
                </th>
                <th scope="col" class="text-left">
                  Role Description
                </th>
                <th scope="col" class="text-center" style="width: 110px;">
                  Permissions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="role in authAllRoles" :key="role.id">
                <td class="text-left">
                  {{ role.name }}
                </td>
                <td class="text-left">
                  {{ role.description }}
                </td>
                <td class="py-1 text-center">
                  <button
                    @click="managePermission(role)"
                    type="button"
                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                  >
                    Manage Permissions
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <h4>
          <div class="row">
            <div class="col-lg-6" style="align-self: center;">
              Custom Roles
            </div>
          </div>
        </h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="text-left">
                  Role Name
                </th>
                <th scope="col" class="text-left">
                  Role Description
                </th>
                <th scope="col" class="text-left">
                  Manage Role
                </th>
                <th scope="col" class="text-center" style="width: 110px;">
                  Permissions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!authCompanyRoles.length">
                <td colspan="4" class="py-5 text-center">
                  No Custom Roles
                </td>
              </tr>
              <tr v-else v-for="role in authCompanyRoles" :key="role.id">
                <td class="text-left">
                  {{ role.name }}
                </td>
                <td class="text-left">
                  {{ role.description }}
                </td>
                <td class="text-left">
                  <span>
                    <i
                      @click="
                        !update_custom_role_dialog
                          ? launch_update_custom_role(role)
                          : null
                      "
                      class="col-3 py-1 px-0 text-center fal fa-edit"
                      style="color: #069 !important; font-size: 125%; cursor: pointer;"
                    ></i>
                    <i
                      @click="delete_custom_role(role)"
                      class="col-3 py-1 px-0 text-center fal fa-trash"
                      style="color: #069 !important; font-size: 125%; cursor: pointer;"
                    ></i
                  ></span>
                </td>
                <td class="py-1 text-center">
                  <button
                    @click="managePermission(role)"
                    type="button"
                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                  >
                    Manage Permissions
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <v-dialog v-model="update_custom_role_dialog" max-width="600px">
      <v-card
        ><v-card-title>
          <div style="display:flex; justify-content:space-between; width:100%">
            <v-col cols="6">
              <span class="headline">Update Custom Role</span>
            </v-col>
            <v-col cols="2"> </v-col>
          </div>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Name
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="custom_role_update_data.role.name"
                      placeholder="Enter name"
                      solo
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Role Display Name
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="custom_role_update_data.role.display_name"
                      placeholder="Enter display name"
                      solo
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Role Description
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="custom_role_update_data.role.description"
                      placeholder="Enter Role Description"
                      solo
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="
                        custome_role_update_submit ? null : update_custom_role()
                      "
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ custom_role_update_text }}
                      <v-icon
                        :style="
                          !custome_role_update_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="update_custom_role_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="permission_dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Manage Permissions ({{
              currentRole ? currentRole.name : ""
            }})</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div>
                <template
                  v-for="s in authAllAvailableServices.human_resources_services"
                >
                  <v-row
                    v-if="
                      check_all_services(s.service_name) &&
                        (currentRole
                          ? currentRole.id == 2
                            ? s.service_name
                                .toLowerCase()
                                .indexOf('projects') != -1
                            : true
                          : null)
                    "
                    :key="s.id"
                  >
                    <v-col class="py-4" cols="12">
                      <h4 class="font-bold">
                        {{ s.service_name }} Permissions
                      </h4>
                    </v-col>
                    <v-col class="mb-2" cols="12">
                      <v-checkbox
                        :disabled="
                          currentRole
                            ? currentRole.id == 1 ||
                              (currentRole.id == 2 &&
                                s.service_name
                                  .toLowerCase()
                                  .indexOf('projects') == -1)
                            : false
                        "
                        v-for="p in specificPermissions(s.id)"
                        :key="p.id"
                        class="mt-0 d-inline-block mr-5"
                        :label="p.display_name"
                        v-model="currentPermission(p.id).checked"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </template>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    offset-md="3"
                    offset-sm="3"
                    class="remove-hint"
                  >
                    <button
                      :disabled="currentRole ? currentRole.id == 1 : null"
                      @click="
                        apply_permissions_submit ? null : apply_permissions()
                      "
                      style="width: 100%;"
                      type="button"
                      class="btn btn-secondary m-1 ripple"
                    >
                      {{ apply_permissions_text }}
                      <v-icon
                        :style="
                          !apply_permissions_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="permission_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {
      availablePermissions: [],
      currentRole: null,
      apply_permissions_text: "Apply",
      apply_permissions_submit: false,
      custom_role_creation_data: {
        name: "",
        description: "",
        display_name: ""
      },
      custom_role_update_data: {
        role: {},
        role_id: "",

        name: "",
        description: "",
        display_name: ""
      },
      create_custom_role_form: false,
      custom_role_create_text: "Submit",
      custom_role_create_submit: false,
      custom_role_update_text: "Update",
      custome_role_update_submit: false,
      permission_dialog: false,
      update_custom_role_dialog: false
    };
  },
  computed: {
    ...mapGetters([
      "authCompanyData",
      "authUserData",
      "authAllRoles",
      "authCompanyRoles",
      "authCompanyPermissions",
      "authAssignedPermissions",
      "authAllAvailableServices"
    ]),
    permission_ids() {
      let _this = this,
        ids = [];
      if (_this.availablePermissions) {
        ids = _this.availablePermissions.filter((p) => p.checked);
        ids = ids.map((i) => {
          return {
            permission_id: i.id
          };
        });
      }
      return ids;
    }
  },
  methods: {
    ...mapActions([
      "fetch_company_roles",
      "fetch_assigned_permissions",
      "fetch_all_permissions",
      "fetch_available_services"
    ]),
    delete_custom_role(custom_role) {
      let _this = this;
      console.log(custom_role);

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        company_id: _this.authCompanyData.id,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to delete " +
            custom_role.name +
            " from your custom roles?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Delete`
        })
        .then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            try {
              axios
                .post(`${BASE_URL}deleterole`, {
                  role_id: custom_role.id,
                  company_id: custom_role.company_id,
                  employee_id: _this.authUserData.id
                })
                .then(function() {
                  _this.fetch_company_roles().then(() => {
                    Toast.fire({
                      icon: "success",
                      title: "Custom role deleted successfully"
                    });
                    _this.update_custom_role_dialog = false;
                  });
                });
            } catch (error) {
              Toast.fire({
                icon: "error",
                title: "Invalid Field"
              });
            }
          }
        });
    },
    apply_permissions() {
      let _this = this;
      _this.apply_permissions_text = "";
      _this.apply_permissions_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      if (_this.permission_ids.length == 0) {
        Toast.fire({
          icon: "error",
          title: "You have to select at least 1 permission for this role"
        });
        _this.apply_permissions_submit = false;
        _this.apply_permissions_text = "Apply";
        return;
      }

      _this.$swal
        .fire({
          title:
            "Are you sure you want to apply these updates to the permissions for this role (" +
            _this.currentRole.name +
            ")?",
          showCancelButton: true,
          confirmButtonText: `Yes, Apply`
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}assignpermission`, {
                company_id: _this.authCompanyData.id,
                role_id: _this.currentRole.id,
                permissions: _this.permission_ids
              })
              .then(function() {
                Toast.fire({
                  icon: "success",
                  title:
                    "The permissions for the role - " +
                    _this.currentRole.name +
                    " have been updated successfully"
                });
                _this.fetch_assigned_permissions();
                _this.fetch_all_permissions();
              })
              .catch(function(error) {
                Toast.fire({
                  icon: "error",
                  title:
                    "The permissions for the role - " +
                    _this.currentRole.name +
                    " couldn't be updated"
                });
                console.log(error);
              })
              .then(function() {
                // NO API TO CALL?
                _this.apply_permissions_submit = false;
                _this.apply_permissions_text = "Apply";
              });
          } else {
            _this.apply_permissions_submit = false;
            _this.apply_permissions_text = "Apply";
          }
        });
    },
    managePermission(role) {
      let _this = this;
      // DO BELOW BEFORE CHECKING FOR ACTIVE PERMISSIONS
      _this.availablePermissions = _this.authCompanyPermissions.map((p) => {
        return {
          created_at: p.created_at,
          description: p.description,
          display_name: p.display_name,
          id: p.id,
          name: p.name,
          service_id: p.service_id,
          updated_at: p.updated_at,
          checked: _this.foundPermission(role.id, p.id)
        };
      });
      //
      _this.currentRole = role;
      _this.permission_dialog = true;
    },
    launch_update_custom_role(role) {
      this.custom_role_update_data.role_id = role.id;
      this.custom_role_update_data.role = role;
      this.update_custom_role_dialog = true;
    },
    create_custom_role() {
      const _this = this;
      _this.custom_role_create_text = "";
      _this.custom_role_create_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}createrole`, {
          company_id: _this.authCompanyData.id,
          display_name: _this.custom_role_creation_data.display_name,
          name: _this.custom_role_creation_data.name,
          employee_id: _this.authUserData.id,
          description: _this.custom_role_creation_data.description
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "Custom role has been created sccessfully"
          });
          _this.custom_role_creation_data.name = "";
          _this.custom_role_creation_data.display_name = "";
          _this.custom_role_creation_data.description = "";

          _this.fetch_company_roles();
          _this.create_custom_role_form = false;
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.id
                ? errors.id[0]
                : null || errors.title
                ? errors.title[0]
                : null || errors.description
                ? errors.description[0]
                : null || errors.start
                ? errors.start[0]
                : null || errors.end
                ? errors.end[0]
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "The custom role couldn't be created"
          });
        })
        .then(function() {
          _this.custom_role_create_submit = false;
          _this.custom_role_create_text = "Submit";
        });
    },
    update_custom_role() {
      const _this = this;
      _this.custom_role_update_text = "";
      _this.custome_role_update_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updaterole`, {
          name: _this.custom_role_update_data.role.name,
          display_name: _this.custom_role_update_data.role.display_name,
          description: _this.custom_role_update_data.role.description,
          role_id: _this.custom_role_update_data.role_id,
          employee_id: _this.authUserData.id,
          company_id: _this.authCompanyData.id
        })
        .then(function() {
          _this.update_custom_role_dialog = false;

          Toast.fire({
            icon: "success",
            title: "The custom role has been updated successfully"
          });
          _this.fetch_company_roles();

          _this.custom_role_update_data = {
            role: {},
            name: "",
            display_name: "",
            discription: "",
            role_id: ""
          };
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.user_id
                ? errors.user_id[0]
                : null || errors.role_id
                ? errors.role_id[0]
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "The custom role couldn't be updated"
          });
        })
        .then(function() {
          _this.custome_role_update_submit = false;
          _this.custom_role_update_text = "Update";
        });
    },
    foundPermission(role_id, permission_id) {
      let _this = this,
        found;
      found = _this.authAssignedPermissions.filter((p) => {
        return p.role_id == role_id && p.permission_id == permission_id;
      });
      if (found.length >= 1) {
        return true;
      } else {
        return false;
      }
    },
    currentPermission(id) {
      let _this = this,
        currentP = {};
      if (_this.availablePermissions) {
        currentP = _this.availablePermissions.filter((p) => p.id == id)[0];
      }
      return currentP;
    },
    specificPermissions(service_id) {
      let _this = this,
        permissions = [];
      if (_this.availablePermissions.length >= 1) {
        permissions = _this.availablePermissions.filter(
          (p) => p.service_id == service_id
        );
      }
      return permissions;
    }
  },
  mounted() {
    const _this = this;
    if (_this.authCompanyPermissions) {
      _this.availablePermissions = _this.authCompanyPermissions.map((p) => {
        return {
          created_at: p.created_at,
          description: p.description,
          display_name: p.display_name,
          id: p.id,
          name: p.name,
          service_id: p.service_id,
          updated_at: p.updated_at,
          checked: false
        };
      });
    }
    _this.fetch_available_services();
    _this.fetch_company_roles();
  }
};
</script>

<style></style>
