<template>
  <div class="" style="margin-top: 100px;">
    <v-app class="">

      <v-row>
        <v-col class="px-0 d-none d-md-block" cols="12" md="6">
          <img src="/img/auth.png" style="height: 88vh; width: 100%;" />
        </v-col>
        <v-col class="px-0 py-0" cols="12" md="6" style="background: #FFF; border-radius: 10px;"
          :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? '' : 'position: absolute; top: 100px; right: 200px;'">
          <div class="card"
            style="background: #FFFFFF; box-shadow: 2px 6px 50px rgba(0, 0, 43, 0.15); border-radius: 10px;">
            <div class="card-body py-0">
              <v-form ref="form" @submit.prevent="isLoading ? null : auth_request(formData)" class="mt-10 mt-md-0">
                <v-container
                  :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'padding: 20px 0px;' : 'padding: 80px 12px;'">
                  <v-row
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'px-4' : null">

                    <v-col cols="12" class="text-center mt-10 mt-md-0">
                      <h3 class="text-new-primary" style="color: #111023; font-weight: 500; font-size: 33.18px;">Welcome
                        back!</h3>
                      <p style="font-size: 19.2px; color: #6B6A7D; font-weight: 500;">Sign in by entering information
                        below</p>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field v-model="formData.email" :rules="[
                        v => !!v || 'Phone number is required',
        v => /^[0-9]+$/.test(v) || 'Phone number must contain only digits',
        v => (v && v.length >= 9 && v.length <= 11) || 'Phone number must be between 10 and 11 digits',
                      ]" label="Mobile Number" outlined dense placeholder="Enter your mobile number"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field v-model="formData.password" :rules="[
                        v => !!v || 'Password is required',
                        v => (v.length >= 8) || 'Password must be at least 8 characters long'
                      ]" label="Password" outlined dense :append-icon="show3 ? 'fal fa-eye' : 'fal fa-eye-slash'"
                        :type="show3 ? 'text' : 'password'" @click:append="show3 = !show3"
                        placeholder="Enter your password"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <router-link tag="a" :to="{ name: 'ForgotPwd' }" style="float: right;">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8 0.5C3.58203 0.5 0 4.08332 0 8.5C0 12.9193 3.58203 16.5 8 16.5C12.418 16.5 16 12.9193 16 8.5C16 4.08332 12.418 0.5 8 0.5ZM8 4.04839C8.74826 4.04839 9.35484 4.65497 9.35484 5.40323C9.35484 6.15148 8.74826 6.75806 8 6.75806C7.25174 6.75806 6.64516 6.15148 6.64516 5.40323C6.64516 4.65497 7.25174 4.04839 8 4.04839ZM9.80645 12.2419C9.80645 12.4557 9.63313 12.629 9.41935 12.629H6.58065C6.36687 12.629 6.19355 12.4557 6.19355 12.2419V11.4677C6.19355 11.254 6.36687 11.0806 6.58065 11.0806H6.96774V9.01613H6.58065C6.36687 9.01613 6.19355 8.84281 6.19355 8.62903V7.85484C6.19355 7.64106 6.36687 7.46774 6.58065 7.46774H8.64516C8.85894 7.46774 9.03226 7.64106 9.03226 7.85484V11.0806H9.41935C9.63313 11.0806 9.80645 11.254 9.80645 11.4677V12.2419Z"
                            fill="#6B6A7D" />
                        </svg>
                        <small class="ml-2" style="color: #6B6A7D; font-size: 13px; font-family: Poppins !important;">
                          Forgotten Password
                        </small>
                      </router-link>
                    </v-col>
                    <v-col class="pb-2 pt-6 text-center" cols="12" sm="12">
                      <v-btn style="background: #0B54C0 !important; height: 40px; border-radius: 4px !important;"
                        type="submit" x-large dark class="new-create-submit w-100"
                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'w-100' : 'w-50'">
                        {{ btnTxt }}
                        <v-icon :style="!isLoading ? 'display: none' : ''">fas fa-circle-notch fa-spin</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <p class="text-center" style="color: #6B6A7D; font-size: 16px;">Don't have an account? Create one
                        <router-link style="text-decoration: underline; color: #2A2858;" tag="a"
                          :to="{ name: 'Register' }">Here</router-link>
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="text-center">
        <v-dialog v-model="incompleteDialog" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img src="@/assets/graphics/cross-check.png" alt="" class="img-fluid" />
              </div>
              
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                Please ensure all fields are filled
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="incompleteDialog = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img src="@/assets/graphics/cross-check.png" alt="" class="img-fluid" />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                {{ authFailed }}
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="dialog = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirm_two_fa_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Enter 2FA PIN (Check your email)</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <div class="container-fluid">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" class="remove-hint">
                          <span style="color: #111d5e;">2FA Code {{ twofaCountdownString }}</span>
                          <v-text-field v-model="pinCodes" solo dense
                            :append-icon="show5 ? 'fal fa-eye' : 'fal fa-eye-slash'" :type="show5 ? 'text' : 'password'"
                            @click:append="show5 = !show5" class="mt-2" placeholder="Enter 2FA code"></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" class="text-center">
                          <div class="mx-5">
                            <v-row>
                              <v-col v-for="(pin, i) in confirmTruePinCodes" :key="i" cols="2" class="align-self-center">
                                <span v-if="pin" style="font-size: 30px;">*</span>
                                <span v-else style="font-size: 30px;">-</span>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col v-for="(number, i) in confirmPinNumbers" :key="i" cols="4" class="text-center">
                          <button @click="confirmAddPinNumber(number)" type="button"
                            :class="confirmPinCodes.length >= 6 && 'disabled'"
                            class="btn btn-md btn-raised ripple btn-raised-secondary m-1">{{ number }}</button>
                        </v-col>
                        <v-col offset="4" cols="4" class="text-center">
                          <button @click="confirmAddPinNumber('0')" type="button"
                            :class="confirmPinCodes.length >= 6 && 'disabled'"
                            class="btn btn-md btn-raised ripple btn-raised-secondary m-1">0</button>
                        </v-col>
                        <v-col cols="4" class="text-center align-self-center">
                          <i @click="confirmPinCodes.splice(confirmPinCodes.length - 1, 1)" style="cursor: pointer;"
                            class="fas fa-delete-left fa-2x m-1"></i>
                        </v-col> -->
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint mt-5">
                      <button @click="
                        twofa_confirm_submit
                          ? null
                          : twofa_confirm()
                        " :class="pinCodes.length < 6 && 'disabled'" style="width: 99%;" type="button"
                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                        {{ twofa_confirm_text }}
                        <v-icon
                          :style="!twofa_confirm_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                          fal fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="confirm_two_fa_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center">
        <v-dialog v-model="unverifiedDialog" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img src="@/assets/graphics/attention.png" alt="" class="img-fluid" />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                Please verify your account
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="unverifiedDialog = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      checkbox: false,
      incompleteDialog: false,
      dialog: false,
      unverifiedDialog: false,
      confirm_two_fa_dialog: false,
      formData: {
        email: "",
        password: ""
      },
      show1: false,
      show2: true,
      show3: false,
      show4: false,
      show5: false,
      password: "Password",
      btnTxt: "Continue",
      isLoading: false,
      confirmPinCodes: [],
      confirmPinNumbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      twofa_confirm_text: "Submit",
      twofa_confirm_submit: false,
      pinCodes: '',
      twofaCountdown: 0,
      twofaInterval: null
    };
  },
  computed: {
    ...mapGetters([
      "authStatus",
      "authCompanyData",
      "authCompanyServices",
      "authUserData",
      "authSubscriptionExpired",
      "two_fa_enabled"
    ]),
    twofaCountdownString() {
      const _this = this;
      if (_this.twofaCountdown >= 1) {
        return _this.secondsToTimeString(_this.twofaCountdown);
      }
      return '00:00';
    },
    authFailed() {
      if (window.navigator.onLine) {
        return 'Authentication failed';
      } else {
        return 'Please check your internet connectivity';
      }
    },
    confirmTruePinCodes() {
      const _this = this;
      let truePinCode = [];
      for (let index = 0; index < 6; index++) {
        if (_this.confirmPinCodes[index]) {
          truePinCode.push(_this.confirmPinCodes[index]);
        } else {
          truePinCode.push('');
        }
      }
      return truePinCode;
    },
    confirmPinCodesString() {
      return this.confirmTruePinCodes.join('');
    }
  },
  watch: {
    confirm_two_fa_dialog(newValue) {
      const _this = this;
      if (newValue == false) {
        _this.isLoading = false;
        _this.btnTxt = "Continue";
        _this.formData.email = '';
        _this.formData.password = '';
        _this.logout_request();
      }
    },
    authStatus(newValue) {
      let _this = this;
      if (newValue == "success") {
        // if (this.authUserData && this.authCompanyData) { //WORKED BEFOREHAND
        if (_this.authUserData) {
          console.log(
            "Is subscription expired: " + _this.authSubscriptionExpired
          );
          console.log("Just after login. Before page loading");
          if (_this.authUserData.user_type == "1") {
            if (_this.authCompanyData.is_service_selected == "0") {
              _this.$router.push({ name: "SetupCompany" });
            } else {
              if (_this.two_fa_enabled) {
                _this.confirmPinCodes = [];
                _this.confirm_two_fa_dialog = true;
              } else {
                _this.set_two_factor_auth_confirmation(true);
                _this.$router.push({ name: "ClientArea", params: { subdomain: _this.authCompanyData.subdomain } });
              }
            }
          }
          if (_this.authUserData.user_type != "1") {
            if (_this.two_fa_enabled) {
              _this.confirmPinCodes = [];
              _this.confirm_two_fa_dialog = true;
            } else {
              _this.set_two_factor_auth_confirmation(true);
              _this.$router.push({ name: "ClientArea", params: { subdomain: _this.authCompanyData.subdomain } });
            }
          }
        } else {
          _this.$watch("authSubscriptionExpired", newValue => {
            if (
              // _this.authCompanyData.is_service_selected == 0 ||
              newValue
            ) {
              _this.$router.push({ name: "SetupCompany" });
            } else {
              _this.$router.push("/clientarea");
            }
          });
        }
      } else if (newValue == "error") {
        _this.isLoading = false;
        _this.btnTxt = "Continue";
        _this.dialog = true;
        _this.$store.commit("AUTH_LOGOUT");
      } else if (newValue == "unverified") {
        _this.isLoading = !_this.isLoading;
        _this.btnTxt = "Continue";
        _this.unverifiedDialog = true;
        _this.$store.commit("AUTH_LOGOUT");
      } else {
        // setTimeout(() => {
        //   _this.isLoading = false;
        //   _this.btnTxt = "Sign In";
        // }, 2000);
        console.log('Unknown')
      }
    }
  },
  methods: {
    ...mapActions([
      'set_two_factor_auth_confirmation',
      'logout_request'
    ]),
    secondsToTimeString(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    twofa_confirm() {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        },
      });

      if (_this.pinCodes.length !== 6) {
        Toast.fire({
          icon: "error",
          title: "Code should be 6 characters long",
        });
        return;
      }

      _this.twofa_confirm_text = "";
      _this.twofa_confirm_submit = true;
      axios.post(`${BASE_URL}validatetwofa`, {
        user_id: _this.authUserData.id,
        code: _this.pinCodes
        // code: _this.confirmPinCodesString
      })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Two-Factor Authentication validation successful",
          });
          // SAVE TO STORE
          _this.set_two_factor_auth_confirmation(true);

          clearInterval(_this.twofaInterval);

          _this.confirmPinCodes = [];
          _this.confirm_two_fa_dialog = false;
          _this.$router.push({ name: "ClientArea", params: { subdomain: _this.authCompanyData.subdomain } });
        })
        .catch(function (error) {
          _this.confirmPinCodes = [];
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.user_id
                  ? errors.user_id[0]
                  : null || errors.code
                    ? errors.code[0]
                    : null
              : null || error.response.data.error || error.response.data.message || "Two-Factor Authentication validation failed",
          });
        })
        .then(function () {
          _this.twofa_confirm_submit = false;
          _this.twofa_confirm_text = "Submit";
        });
    },
    confirmAddPinNumber(number) {
      const _this = this;
      if (_this.confirmPinCodes.length < 6) {
        _this.confirmPinCodes.splice(
          _this.confirmPinCodes.length,
          0,
          number
        );
      }
    },
    async auth_request(formData) {
      let _this = this;
      const valid = await _this.$refs.form.validate();
      if (!valid) {
        return;
      }
      // _this.$store.actions.auth_request(formData)
      if (_this.formData.email == "" || _this.formData.password == "") {
        _this.incompleteDialog = true;
      } else {
        _this.btnTxt = "";
        _this.isLoading = true;
        _this.pinCodes = '';
        _this.twofaCountdown = 600;
        clearInterval(_this.twofaInterval);
        _this.twofaInterval = setInterval(() => {
          _this.twofaCountdown -= 1;
        }, 1000);
        _this.$store.dispatch("auth_request", formData);
      }
    }
  },
  mounted() {
    localStorage.clear();
    document.title = "Kylian ERP - Login";
  }
};
</script>

<style lang="scss">
$new-button-color: #225BA6;
$primary-color: #069;
$text-field-color: #4e4e52;

.text-new-primary {
  color: $new-button-color !important;
}

.v-application .primary--text {
  color: $primary-color !important;
}

.fa-eye,
.fa-eyeslash {
  color: $primary-color !important;
}

// .v-text-field__suffix, .v-text-field__prefix, input {
//     color: $text-field-color !important;
//     background-color: #00669914;
//     padding: 10px;
//     height: 48px;
// }
.v-text-field__suffix {
  border-radius: 0px 5px 5px 0px;
  padding-top: 13px;
  padding-left: 8px !important;
}

.v-text-field__prefix {
  border-radius: 5px 0px 0px 5px;
  padding-top: 13px;
  padding-right: 8px !important;
  text-decoration: underline;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
  label {
    margin-left: 2%;
  }

  input {
    text-indent: 3%;
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.v-text-field__details {
  padding-left: 0 !important;
}

.theme--light.v-messages {
  color: $primary-color !important;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  padding: 0px !important;
}

// .remove-bottom-hint div .v-text-field__details {
//   display: none;
// }
</style>
<style lang="scss" scoped>
$new-button-color: #2081FF;
$primary-color: #069;
@media (max-width: 991px) {
  .create-submit {
    height: 45px !important;
  }

  .v-form {
    margin: 0 !important;
  }
}

.v-form {
  margin: 0 20%;
}

.svg-login {
  position: absolute;
  top: 8%;
  right: 22%;
}

#small-link {
  color: $primary-color !important;
}

.space-top {
  padding-top: 40px;
}

.create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
  }
}

.new-create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: $new-button-color !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
  }
}

h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: center;
  color: #111d5e;
}

label {
  color: #999 !important;
}
</style>
