<template>
  <div>
    <!-- <SideMenu /> -->
    <Home />
    <div class="search-ui">
      <div class="search-header">
        <img src="/assets/images/logo.png" alt="" class="logo" />
        <button class="search-close btn btn-icon bg-transparent float-right mt-2">
          <i class="i-Close-Window text-22 text-muted"></i>
        </button>
      </div>
      <input type="text" placeholder="Type here" class="search-input" autofocus />
      <div class="search-title">
        <span class="text-muted">Search results</span>
      </div>
      <div class="search-results list-horizontal">
        <div class="list-item col-md-12 p-0">
          <div class="card o-hidden flex-row mb-4 d-flex">
            <div class="list-thumb d-flex">
              <!-- TUMBNAIL -->
              <img src="/assets/images/products/headphone-1.jpg" alt="" />
            </div>
            <div class="flex-grow-1 pl-2 d-flex">
              <div
                class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                <!-- OTHER DATA -->
                <a href="#" class="w-40 w-sm-100">
                  <div class="item-title">Headphone 1</div>
                </a>
                <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                <p class="m-0 text-muted text-small w-15 w-sm-100">
                  $300
                  <del class="text-secondary">$400</del>
                </p>
                <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                  <span class="badge badge-danger">Sale</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="list-item col-md-12 p-0">
          <div class="card o-hidden flex-row mb-4 d-flex">
            <div class="list-thumb d-flex">
              <!-- TUMBNAIL -->
              <img src="/assets/images/products/headphone-2.jpg" alt="" />
            </div>
            <div class="flex-grow-1 pl-2 d-flex">
              <div
                class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                <!-- OTHER DATA -->
                <a href="#" class="w-40 w-sm-100">
                  <div class="item-title">Headphone 1</div>
                </a>
                <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                <p class="m-0 text-muted text-small w-15 w-sm-100">
                  $300
                  <del class="text-secondary">$400</del>
                </p>
                <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                  <span class="badge badge-primary">New</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="list-item col-md-12 p-0">
          <div class="card o-hidden flex-row mb-4 d-flex">
            <div class="list-thumb d-flex">
              <!-- TUMBNAIL -->
              <img src="/assets/images/products/headphone-3.jpg" alt="" />
            </div>
            <div class="flex-grow-1 pl-2 d-flex">
              <div
                class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                <!-- OTHER DATA -->
                <a href="#" class="w-40 w-sm-100">
                  <div class="item-title">Headphone 1</div>
                </a>
                <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                <p class="m-0 text-muted text-small w-15 w-sm-100">
                  $300
                  <del class="text-secondary">$400</del>
                </p>
                <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                  <span class="badge badge-primary">New</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="list-item col-md-12 p-0">
          <div class="card o-hidden flex-row mb-4 d-flex">
            <div class="list-thumb d-flex">
              <!-- TUMBNAIL -->
              <img src="/assets/images/products/headphone-4.jpg" alt="" />
            </div>
            <div class="flex-grow-1 pl-2 d-flex">
              <div
                class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                <!-- OTHER DATA -->
                <a href="#" class="w-40 w-sm-100">
                  <div class="item-title">Headphone 1</div>
                </a>
                <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                <p class="m-0 text-muted text-small w-15 w-sm-100">
                  $300
                  <del class="text-secondary">$400</del>
                </p>
                <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                  <span class="badge badge-primary">New</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGINATION CONTROL -->
      <div class="col-md-12 mt-5 text-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination d-inline-flex">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- ====== Search UI End ====== -->
    <!-- <v-app>
      <div class="jumbotron mt-5 pt-5 pb-7">
        <v-row>
          <v-col md="7" sm="7">
            <h1 class="white-h1">Grow your business</h1>
            <p class="mt-3 sub-h1">
              Learn the ropes and take charge of your business by using digital
              tools
            </p>
            <v-btn type="button" x-large dark class="create-submit mt-3">
              {{ btnTxt }}
              <v-icon :style="!isLoading ? 'display: none' : ''"
                >fas fa-circle-notch fa-spin</v-icon
              >
            </v-btn>
          </v-col>
          <v-col md="4" sm="4" offset="1">
            <img class="img-fluid" src="@/assets/graphics/team.png" alt="" />
          </v-col>
        </v-row>
      </div>
      <div class="container">
        <svg
          style="position: absolute; top: 32%; left: 5%;"
          width="235"
          height="225"
          viewBox="0 0 235 225"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.1"
            d="M173.495 145.708C177.506 135.28 186.35 126.96 195.577 119.265C204.803 111.57 214.658 104.181 221.233 94.8563C225.46 88.8584 228.201 81.3284 224.088 74.0825C220.119 67.1071 210.967 62.3278 202.257 58.5409C195.704 55.6942 188.705 53.0105 181.773 52.8279C176.96 52.7009 172.529 53.797 167.81 54.0239C151.383 54.8096 134.534 45.1221 122.134 34.5819C116.311 29.6362 110.937 24.3388 104.484 19.8915C98.0308 15.4442 90.1525 11.8175 82.2532 11.3607C74.4176 10.9082 67.5647 13.62 62.1982 17.2178C51.2836 24.5181 45.375 35.3507 40.5052 46.207C29.726 70.2288 22.8941 95.6657 16.0843 121.034C13.565 130.423 11.0385 139.854 10.4538 149.7C10.1279 155.165 10.5126 161.04 14.2914 166.297C19.1038 172.974 29.4105 177.554 38.3196 176.973C53.2614 175.997 61.1953 162.593 76.6033 163.499C85.0856 163.999 93.148 168.867 99.4776 174.124C113.05 185.401 123.351 200.307 140.989 206.578C153.104 210.885 166.982 209.762 175.282 203.802C183.979 197.553 186.861 185.945 178.756 176.679C170.425 167.131 169.951 154.91 173.495 145.708Z"
            fill="#069"
            fill-opacity="0.68"
          ></path>
        </svg>
        <v-row>
          <v-col md="5" sm="5">
            <v-card class="right-card pt-3 pb-3">
              <p class="card-p-icon text-center mb-0" style="margin-top: 1px;">
                <v-icon dense> mdi-account-multiple-outline </v-icon> Staff
              </p>
              <v-row>
                <v-col md="6" sm="6" class="pb-5 pt-5">
                  <h3 class="small-card-h3">213</h3>
                  <p
                    class="card-p text-center mb-0"
                    style="margin-top: 1px; font-weight: 400; font-size: 16px;"
                  >
                    Total
                  </p>
                </v-col>
                <v-col md="6" sm="6" class="pb-5 pt-5">
                  <h3 class="small-card-h3">213</h3>
                  <p
                    class="card-p text-center mb-0"
                    style="margin-top: 1px; font-weight: 400; font-size: 16px;"
                  >
                    Active
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col md="7" sm="7">
            <v-card class="right-card pt-3 pb-3" style="z-index: 1;">
              <p class="card-p-icon text-center mb-0" style="margin-top: 1px;">
                <v-icon dense> mdi-wallet-travel </v-icon> Finances
              </p>
              <v-row>
                <v-col md="6" sm="6" class="pb-5 pt-5">
                  <h3 class="small-card-h3">₦1,793,521</h3>
                  <p
                    class="card-p text-center mb-0"
                    style="margin-top: 1px; font-weight: 400; font-size: 16px;"
                  >
                    Income
                  </p>
                </v-col>
                <v-col md="6" sm="6" class="pb-5 pt-5">
                  <h3 class="small-card-h3">₦2,478,521</h3>
                  <p
                    class="card-p text-center mb-0"
                    style="margin-top: 1px; font-weight: 400; font-size: 16px;"
                  >
                    Expenses
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <div style="padding: 70px 10px;">
          <svg
            style="position: absolute; top: 43%; right: 10%;"
            width="357"
            height="363"
            viewBox="0 0 357 363"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M266.609 161.439C259.486 145.423 259.172 126.898 259.877 108.698C260.581 90.4984 262.205 72.0093 258.678 54.6453C256.408 43.478 251.117 32.0706 239.604 27.9675C228.512 24.0276 214.794 27.8778 202.536 32.4141C193.317 35.8295 183.845 39.8849 177.06 46.7345C172.349 51.4897 169.284 57.2554 165.033 62.3206C150.232 79.9457 124.044 86.0179 101.194 86.5829C90.4668 86.8535 79.799 86.2637 68.9958 87.7459C58.1926 89.2281 46.8947 93.1003 38.9018 100.621C30.9739 108.082 27.3 118.162 25.9692 127.744C23.2448 147.21 28.987 165.622 35.7422 183.002C50.6859 221.465 70.8684 257.527 90.9998 293.487C98.4523 306.796 105.941 320.16 115.712 332.021C121.135 338.606 127.664 344.938 136.771 347.104C148.353 349.845 162.961 344.59 170.826 334.855C184.015 318.524 177.501 295.107 193.107 280.454C201.701 272.391 214.476 269.751 226.01 269.321C250.749 268.404 276.182 274.971 299.538 264.187C315.579 256.78 327.601 241.363 329.244 226.092C330.962 210.087 321.528 193.869 304.099 191.521C286.16 189.079 272.89 175.577 266.609 161.439Z"
              fill="#069"
              fill-opacity="0.68"
            ></path>
          </svg>
          <p class="lead">All apps</p>
          <v-row>
            <v-col v-for="app in apps" :key="app.id" md="3" sm="3">
              <router-link
                :to="{ name: app.linkName }"
                style="text-decoration: none;"
              >
                <v-card class="right-card pb-8" style="cursor: pointer;">
                  <h3 style="color: #069 !important;">
                    <v-icon class="mdi-all-apps-size" x-large color="#069">
                      {{ app.icon }}
                    </v-icon>
                  </h3>
                  <p class="lead text-center mb-0" style="margin-top: 16px;">
                    {{ app.header }}
                  </p>
                </v-card>
              </router-link>
            </v-col>

            <v-col md="3" sm="3">
              <v-card class="right-card pb-8" style="cursor: pointer;">
                <h3 style="color: #069 !important;">
                  <v-icon class="mdi-all-apps-size" x-large color="#111d5ea3">
                    mdi-plus-circle-outline
                  </v-icon>
                </h3>
                <p
                  class="lead text-center mb-0"
                  style="margin-top: 16px; opacity: 0; cursor: default;"
                >
                  &nbsp;*
                </p>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-app> -->

    <v-app>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import SideMenu from "../../components/dashboard/SideMenu";
import Home from "../../components/dashboard/Home";
import { BASE_URL } from "@/main";
import axios from "axios";
export default {
  components: {
    // SideMenu,
    Home
  },
  data() {
    return {
      calledAPIs: 0,
      btnTxt: "Take Tour",
      isLoading: false,
      apps: [],
      overlay: true
    };
  },
  watch: {
    calledAPIs(count) {
      let _this = this;
      if (count == 102) {
        _this.overlay = false;
        if (_this.$route.query.addon == 'first-time') {
          _this.$router.push({ name: 'Settings' });
        }
      }
    },
    authCompanyServices(newValue) {
      if (newValue && this.authCompanyEmployees.length >= 1) {
        this.overlay = false;
        if (this.humanResources.length >= 1) {
          this.apps.push({
            icon: "mdi-account-group-outline",
            header: "Human Resources",
            linkName: "HumanResources"
          });
        }
        if (this.accounts.length >= 1) {
          this.apps.push({
            icon: "mdi-cash",
            header: "Accounts",
            linkName: "Accounts"
          });
        }
      }
    },
    authCompanyEmployees(newValue) {
      if (newValue && this.authCompanyServices.length >= 1) {
        this.overlay = false;
      }
    }
  },
  methods: {
    ...mapActions([
      "fetch_active_company_employees",
      "fetch_company_appraisals",
      "fetch_company_departments",
      "fetch_company_KRAs",
      "fetch_company_goals",
      "fetch_candidates",
      "fetch_company_assets",
      "fetch_all_approved_leave",
      "fetch_all_leave",
      "fetch_all_leave_type",
      "fetch_all_asset_types",
      "fetch_all_check_in",
      "fetch_all_vendors",
      "fetch_all_company_announcements",
      "fetch_all_company_employee_announcements",
      "fetch_all_company_events",
      "fetch_all_company_billing",
      "fetch_company_attendance_configuration",
      "fetch_employee_timesheets",
      "fetch_all_timesheets",
      "fetch_all_roles",
      "fetch_available_services",
      "fetch_company_file_types",
      "fetch_all_company_files",
      "fetch_all_medicals",
      "fetch_all_company_jobs",
      "fetch_employee_travels",
      "fetch_company_travels",
      "fetch_all_countries",
      "fetch_all_states",
      "fetch_all_applicants",
      "fetch_all_check_in_all_time",
      "fetch_all_designations",
      "fetch_all_shifts",
      "fetch_all_employee_shifts",
      "fetch_all_company_projects",
      "fetch_all_company_tasks",
      "fetch_all_holidays",
      "fetch_all_permissions",
      "fetch_assigned_permissions",
      "fetch_all_pay_calendar",
      "fetch_all_employees_pay_calendar",
      "fetch_all_fixed_allowances",
      "fetch_all_fixed_deductions",
      "fetch_all_employees_tax",
      "fetch_all_company_payrun",
      "fetch_all_flexible_allowances",
      "fetch_all_flexible_deductions",
      "fetch_all_employee_payslips",
      "fetch_all_payroll_configuration",
      "fetch_all_employees_assigned_to_projects",
      "fetch_all_company_hospitals",
      "fetch_all_company_loans",
      "fetch_all_liquidation_requests",
      "fetch_company_geo_locations",
      "fetch_company_pay_grades",
      "fetch_company_pay_grade_histories",
      "fetch_company_job_documents",
      "fetch_company_grade_levels",
      "fetch_company_emoluments",
      "fetch_statutory_deductions",
      "fetch_company_statutory_deductions",
      "fetch_company_tasksheets",
      "fetch_employee_tasksheets",
      "fetch_company_logs",
      "fetch_company_requisitions",
      "fetch_company_requisition_types",
      "fetch_company_dockets",
      "fetch_company_department_employees",
      "fetch_company_committees",
      "fetch_company_employee_committees",
      "fetch_company_employee_dockets",
      "fetch_company_employees_in_goals",
      "fetch_company_employees_in_appraisals",
      "fetch_company_questionnaires",
      "fetch_employee_assigned_goals",
      "fetch_company_goals_in_appraisals",
      "fetch_employee_appraisals",
      "fetch_company_employee_kras",
      "fetch_company_reviewers",
      "fetch_company_questionnaire_responses",
      "fetch_employee_appraisal_reviews",
      "fetch_employee_goal_reviews",
      "fetch_employee_reporters",
      "fetch_company_feedbacks",
      "fetch_company_appraisal_feedbacks",
      "fetch_company_loan_repayments",
      "fetch_employee_loan_repayments",
      "fetch_employee_skill_sets",
      "fetch_company_skill_sets",
      "fetch_company_branches",
      "fetch_banks",
      "fetch_company_subaccount",
      "fetch_company_transactions",
      "fetch_company_attendance_deduction_settings",
      "fetch_company_exit_requests",
      "fetch_employee_exit_requests",
      "fetch_company_exit_checklists",
      "fetch_company_course_categories",
      "fetch_company_workflows",
      "fetch_company_approval_levels"
    ]),
    sendLoginNotification() {
      if (!this.checkedIn) {
        let _this = this;
        axios
          .post(`${BASE_URL}checkinemailnotification`, {
            employee_name:
              _this.authEmployeeDataForCompany.employee_fristname +
              " " +
              _this.authEmployeeDataForCompany.employee_lastname,
            employee_email: _this.authEmployeeDataForCompany.employee_email,
            company_id: _this.authEmployeeDataForCompany.company_id,
            employee_id: _this.authEmployeeDataForCompany.id
          })
          .then(function () {
            _this.fetch_all_company_employee_announcements();
          })
          .catch(function (error) {
            console.log(error.response);
          })
          .then(function () {
            //
          });
      }
    }
  },
  computed: {
    ...mapGetters([
      "userType",
      "isAuth",
      "authUserData",
      "authCompanyData",
      "authCompanyServices",
      "humanResources",
      "accounts",
      "checkedIn",
      "authEmployeeDataForCompany",
      "authCompanyEmployees",
      "authCompanyAllBilling"
    ])
  },
  mounted() {
    let _this = this;

    const date = new Date();
    const year = date.getFullYear();
    const month = _this.monthNames[date.getMonth()];

    setTimeout(() => {
      if (_this.overlay == true) {
        _this.$swal.fire({
          title: "Network Anomaly",
          text: "Please check your internet connection and refresh the page",
          icon: "warning",
          confirmButtonColor: "#3085d6",
        }).then(() => {
          _this.overlay = false;
        })
      }
    }, 60000);
    if (!_this.checkedIn && _this.userType != 1) {
      setTimeout(() => {
        _this.sendLoginNotification();
      }, 600000);
    }
    if (_this.userType == 1) {
      if (!_this.authCompanyServices.length) {
        _this.overlay = true;
      } else {
        if (_this.humanResources.length >= 1) {
          _this.apps.push({
            icon: "mdi-account-group-outline",
            header: "Human Resources",
            linkName: "HumanResources"
          });
        }
        if (_this.accounts.length >= 1) {
          _this.apps.push({
            icon: "mdi-cash",
            header: "Accounts",
            linkName: "Accounts"
          });
        }
      }
    } else {
      if (!_this.authCompanyServices) {
        _this.overlay = true;
      }
    }
    if (!!_this.authCompanyAllBilling?.companyservices == true) {
      _this.overlay = false;
    }
    _this.fetch_all_check_in_all_time().then(() => _this.calledAPIs += 1);
    _this.fetch_all_check_in().then(() => _this.calledAPIs += 1);
    _this.fetch_company_pay_grades().then(() => _this.calledAPIs += 1);
    _this.fetch_all_approved_leave().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_announcements().then(() => _this.calledAPIs += 1);
    _this.fetch_company_appraisals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_goals().then(() => _this.calledAPIs += 1);
    _this.fetch_candidates().then(() => _this.calledAPIs += 1);
    _this.fetch_company_assets().then(() => _this.calledAPIs += 1);
    _this.fetch_all_asset_types().then(() => _this.calledAPIs += 1);
    _this.fetch_all_vendors().then(() => _this.calledAPIs += 1);
    _this.fetch_all_timesheets({ month, year }).then(() => _this.calledAPIs += 1);
    _this.fetch_available_services().then(() => _this.calledAPIs += 1);
    _this.fetch_company_file_types().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_jobs().then(() => _this.calledAPIs += 1);
    _this.fetch_company_travels().then(() => _this.calledAPIs += 1);
    _this.fetch_all_applicants().then(() => _this.calledAPIs += 1);

    _this.fetch_assigned_permissions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_departments().then(() => _this.calledAPIs += 1);
    _this.fetch_all_leave().then(() => _this.calledAPIs += 1);
    _this.fetch_active_company_employees().then(() => _this.calledAPIs += 1);
    _this.fetch_all_leave_type().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_events().then(() => _this.calledAPIs += 1);
    _this.fetch_all_roles().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_billing().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_files().then(() => _this.calledAPIs += 1);
    _this.fetch_all_medicals().then(() => _this.calledAPIs += 1);
    _this.fetch_all_countries().then(() => _this.calledAPIs += 1);
    _this.fetch_all_states().then(() => _this.calledAPIs += 1);
    _this.fetch_all_designations().then(() => _this.calledAPIs += 1);
    _this.fetch_all_shifts().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employee_shifts().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_projects().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_tasks().then(() => _this.calledAPIs += 1);
    _this.fetch_all_holidays().then(() => _this.calledAPIs += 1);
    _this.fetch_company_attendance_configuration().then(() => _this.calledAPIs += 1);
    _this.fetch_all_permissions().then(() => _this.calledAPIs += 1);

    _this.fetch_all_company_employee_announcements().then(() => _this.calledAPIs += 1);

    _this.fetch_employee_timesheets({ month, year }).then(() => _this.calledAPIs += 1);
    _this.fetch_employee_travels().then(() => _this.calledAPIs += 1);

    _this.fetch_company_KRAs().then(() => _this.calledAPIs += 1);
    _this.fetch_all_pay_calendar().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employees_pay_calendar().then(() => _this.calledAPIs += 1);
    _this.fetch_all_fixed_allowances().then(() => _this.calledAPIs += 1);
    _this.fetch_all_fixed_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employees_tax().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_payrun().then(() => _this.calledAPIs += 1);
    _this.fetch_all_flexible_allowances().then(() => _this.calledAPIs += 1);
    _this.fetch_all_flexible_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employee_payslips().then(() => _this.calledAPIs += 1);
    _this.fetch_all_payroll_configuration().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employees_assigned_to_projects().then(() => _this.calledAPIs += 1);

    _this.fetch_all_company_hospitals().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_loans().then(() => _this.calledAPIs += 1);
    _this.fetch_company_job_documents().then(() => _this.calledAPIs += 1);
    _this.fetch_all_liquidation_requests().then(() => _this.calledAPIs += 1);

    _this.fetch_company_pay_grade_histories().then(() => _this.calledAPIs += 1);

    _this.fetch_company_geo_locations().then(() => _this.calledAPIs += 1);
    _this.fetch_company_grade_levels().then(() => _this.calledAPIs += 1);
    _this.fetch_company_emoluments().then(() => _this.calledAPIs += 1);
    _this.fetch_statutory_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_statutory_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_statutory_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_tasksheets().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_tasksheets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_logs().then(() => _this.calledAPIs += 1);
    _this.fetch_company_requisitions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_requisition_types().then(() => _this.calledAPIs += 1);
    _this.fetch_company_dockets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_department_employees().then(() => _this.calledAPIs += 1);
    _this.fetch_company_committees().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employee_committees().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employee_dockets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employees_in_goals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employee_kras().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employees_in_appraisals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_questionnaires().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_assigned_goals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_goals_in_appraisals().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_appraisals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_reviewers().then(() => _this.calledAPIs += 1);
    _this.fetch_company_questionnaire_responses().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_appraisal_reviews().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_goal_reviews().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_reporters().then(() => _this.calledAPIs += 1);
    _this.fetch_company_feedbacks().then(() => _this.calledAPIs += 1);
    _this.fetch_company_appraisal_feedbacks().then(() => _this.calledAPIs += 1);
    _this.fetch_company_loan_repayments().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_loan_repayments().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_skill_sets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_skill_sets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_branches().then(() => _this.calledAPIs += 1);
    _this.fetch_banks().then(() => _this.calledAPIs += 1);
    _this.fetch_company_subaccount().then(() => _this.calledAPIs += 1);
    _this.fetch_company_transactions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_attendance_deduction_settings().then(() => _this.calledAPIs += 1);
    _this.fetch_company_exit_requests().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_exit_requests().then(() => _this.calledAPIs += 1);
    _this.fetch_company_exit_checklists().then(() => _this.calledAPIs += 1);
    _this.fetch_company_course_categories().then(() => _this.calledAPIs += 1);
    _this.fetch_company_workflows().then(() => _this.calledAPIs += 1);
    _this.fetch_company_approval_levels().then(() => _this.calledAPIs += 1);
  }
};
</script>

<style lang="scss" scoped>
.mdi-all-apps-size {
  font-size: 90px !important;
}

p {
  color: #111d5e;
  font-weight: 400;
}

.jumbotron {
  border-radius: 8px;
  background: #dbe0f975; //#111d5e75;
  // box-shadow: 0px 0px 50px 0px #00000014 !important;
  padding-left: 7%;
}

h1.white-h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: -2.5010533332824707px;
  text-align: left;
  color: #111d5e !important;
}

p.sub-h1 {
  color: #111d5e !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.create-submit {
  font-weight: 600;
  width: 40%;
  margin-right: 5px;
  height: 60px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial;
}

.right-card {
  padding: 60px 0px;
  box-shadow: 0px 0px 50px 0px #00000014 !important;
  border-radius: 10px !important;
}

.shift-left {
  margin-left: 5%;
}

.card-p-icon {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: #111d5ea3 !important;
}

.card-p-icon i {
  color: #111d5ea3 !important;
}

.small-card-h3 {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
}
</style>