<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="card card-profile-1">
        <div v-if="userType == 1" class="card-body text-center py-5" style="
              background-image: url('/assets/images/stars_bg.png');
              background-position-y: bottom;
          ">
          <div class="row">
            <div class="col-md-6 text-right">
              <div class="avatar box-shadow-2 mb-3 mt-3 mr-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <img v-bind="attrs" v-on="on" @click="dialog = true" :src="current_person.profile_image_url
                        ? host_url + current_person.profile_image_url
                        : '/assets/images/default_profile_img.png'
                      " alt="" style="cursor: pointer; height: inherit;" />
                  </template>
                  <span>Update profile picture</span>
                </v-tooltip>
              </div>
              <p class="m-0 text-white">
                {{ current_person.employee_fristname }}
                {{ current_person.employee_lastname }}
              </p>
              <p class="mt-0 mb-1 text-white">
                {{ current_person.employee_email }}
              </p>
            </div>
            <div class="col-md-6 text-left">
              <div class="avatar box-shadow-2 mb-3 mt-3 ml-0">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <img v-bind="attrs" v-on="on" @click="logoDialog = true" :src="authCompanyData.logo
                        ? host_url + authCompanyData.logo
                        : '/img/kylian-logo-2.png'
                      " alt="" style="cursor: pointer; height: inherit;" />
                  </template>
                  <span>Update company logo</span>
                </v-tooltip>
              </div>
              <p class="m-0 text-white">
                Company Logo
              </p>
            </div>
          </div>
        </div>
        <div v-else class="card-body text-center py-5" style="
              background-image: url('/assets/images/stars_bg.png');
              background-position-y: bottom;
          ">
          <div class="avatar box-shadow-2 mb-3 mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <img v-bind="attrs" v-on="on" @click="dialog = true" :src="current_person.profile_image_url
                    ? host_url + current_person.profile_image_url
                    : '/assets/images/default_profile_img.png'
                  " alt="" style="cursor: pointer; height: inherit;" />
              </template>
              <span>Update profile picture</span>
            </v-tooltip>
          </div>
          <p class="m-0 text-white">
            {{ current_person.employee_fristname }}
            {{ current_person.employee_lastname }}
          </p>
          <p class="mt-0 mb-1 text-white">
            {{ current_person.employee_email }}
          </p>
        </div>
      </div>
      <div style="border-bottom: 1px solid #dee2e6;">
        <p style="
                    float: right;
                    /* font-weight: bold; */
                " class="mb-0 py-2 mr-5">
          <span style="cursor: pointer;" @click="
            $router.push({
              name: 'EmployeeEditProfile',
              params: { id: current_person.id }
            })
            "><i class="fas fa-edit"></i> Edit</span>
          <!--<strong>|</strong> <span style="cursor: pointer;" @click="$router.push({
                          name: 'ViewEmployee',
                          params: { id: current_person.id }
                        })">View All</span> -->
        </p>
        <p style="
                    float: right;
                    /* font-weight: bold; */
                " class="mb-0 py-2 mr-5">
          <span style="cursor: pointer;" v-if="employee_profile_view != 2" @click="viewEmployeeProfile()"><i
              class="fas fa-eye"></i> view</span>
          <!--<strong>|</strong> <span style="cursor: pointer;" @click="$router.push({
                          name: 'ViewEmployee',
                          params: { id: current_person.id }
                        })">View All</span> -->
        </p>
        <p style="
                    float: right;
                    /* font-weight: bold; */
                " class="mb-0 py-2 mr-5">
          <span style="cursor: pointer;" v-if="employee_profile_view == 2" @click="viewEmployeeProfileBack()"><i
              class="fas fa-arrow-left"></i> Back</span>
          <!--<strong>|</strong> <span style="cursor: pointer;" @click="$router.push({
                          name: 'ViewEmployee',
                          params: { id: current_person.id }
                        })">View All</span> -->
        </p>
      </div>

      <!-- ======== Body content start ========= -->
      <template>
        <div class="main-content mt-0" :class="$vuetify.breakpoint.name == 'xs' ? 'px-0' : null">
          <div v-if="employee_profile_view == 2" class="row">
            <!--  ViewEmPStart -->
            <div class="col-12">
              <div class="tab-content py-0 px-0" id="nav-tabContent">
                <div class="tab-pane fade active show" id="onboarding-reports" role="tabpanel"
                  aria-labelledby="onboarding-reports-info">
                  <div class="row">
                    <!-- ICON BG-->
                    <div class="col-12">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li v-for="(tabName, index) in employeeViewTabs" :key="'tab-name-' + index" class="nav-item">
                          <a @click="view = convertToSnakeCase(tabName)" :class="view == convertToSnakeCase(tabName)
                              ? 'active'
                              : null
                            " class="nav-link show" data-toggle="tab" role="tab"
                            :aria-controls="convertToSnakeCase(tabName)" aria-selected="true">
                            {{ tabName }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="card card-icon-bg card-icon-bg-primary mt-4">
                    <!-- Manage all goals -->
                    <PolicyDocuments v-if="view == 'documents'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-md-4 col-lg-4 col-12">
              <div class="card mb-4" style="border-top: 5px solid #069;">
                <div class="card-body py-3">
                  <h5 class="card-title" style="font-weight: bold;">
                    Basic Info
                  </h5>
                  <table class="table" :class="$vuetify.breakpoint.name == 'xs'
                      ? 'table-responsive'
                      : null
                    ">
                    <thead>
                      <tr>
                        <td class="text-left" scope="col">Employee ID</td>
                        <td class="text-left" scope="col">
                          {{ current_person.employee_id }}
                        </td>
                      </tr>
                      <tr v-if="current_person.user_type != 1">
                        <td class="text-left" scope="col">Added By</td>
                        <td class="text-left" scope="col">Admin</td>
                      </tr>
                      <!-- <tr>
                                <td scope="col">Added Time</td>
                                <td scope="col">{{ new Date(authEmployeeDataForCompany.created_at).toUTCString().substring(0, 16) }}</td>
                              </tr> -->
                      <tr>
                        <td class="text-left" scope="col">First Name</td>
                        <td class="text-left" scope="col">
                          {{ current_person.employee_fristname }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Middle Name</td>
                        <td class="text-left" v-if="current_person.employee_middlename" scope="col">
                          {{ current_person.employee_middlename }}
                        </td>
                        <td class="text-left" v-else>
                          <i>No middle name specified</i>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Last Name</td>
                        <td class="text-left" scope="col">
                          {{ current_person.employee_lastname }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Email ID</td>
                        <td class="text-left" scope="col">
                          {{ current_person.employee_email }}
                        </td>
                      </tr>
                      <tr v-if="userType != 1">
                        <td class="text-left" scope="col">Bank Name</td>
                        <td v-if="current_person.bank_name" class="text-left" scope="col">
                          {{ current_person.bank_name }}
                        </td>
                        <td v-else class="text-left" scope="col">
                          <i>No specified bank</i>
                        </td>
                      </tr>
                      <tr v-if="userType != 1">
                        <td class="text-left" scope="col">Account Number</td>
                        <td v-if="current_person.account_number" class="text-left" scope="col">
                          {{ current_person.account_number }}
                        </td>
                        <td v-else class="text-left" scope="col">
                          <i>No specified account number</i>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-12">
              <div class="card mb-4" style="border-top: 5px solid #069;">
                <div class="card-body py-3">
                  <h5 class="card-title" style="font-weight: bold;">Work</h5>
                  <table class="table" :class="$vuetify.breakpoint.name == 'xs'
                      ? 'table-responsive'
                      : null
                    ">
                    <thead>
                      <tr v-if="userType != 1">
                        <td class="text-left" scope="col">Reporting Manager</td>
                        <td v-if="singleEmployeeObject(current_person.reporting_to)
                          " class="text-left" scope="col">
                          {{
                            singleEmployeeObject(current_person.reporting_to)
                              .employee_fristname +
                            " " +
                            singleEmployeeObject(current_person.reporting_to)
                              .employee_lastname
                          }}
                        </td>
                        <td v-else class="text-left" :class="!check_all_services('Payroll') ? 'd-none' : null
                            " scope="col">
                          <i>No specified reporting manager</i>
                        </td>
                      </tr>
                      <tr v-if="current_person.user_type != 1">
                        <td class="text-left" scope="col">
                          Primary Department
                        </td>
                        <td class="text-left" v-if="current_person.department_id" scope="col">
                          {{ get_dept_name(current_person.department_id) }}
                        </td>
                        <td class="text-left" v-else>
                          <i>
                            You haven't been assigned a department
                          </i>
                        </td>
                      </tr>
                      <tr v-if="current_person.user_type != 1 &&
                        currentEmployeeDepartments.length
                        ">
                        <td class="text-left" scope="col">
                          Secondary Departments
                        </td>
                        <td class="text-left" scope="col">
                          <span v-for="(item, index) in currentEmployeeDepartments" :key="'employee-department-' + index">
                            {{
                              get_dept_name(item.department_id) +
                              (index == currentEmployeeDepartments.length - 1
                                ? "."
                                : ", ")
                            }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="current_person.user_type != 1 &&
                        currentEmployeeCommittees.length
                        ">
                        <td class="text-left" scope="col">Committees</td>
                        <td class="text-left" scope="col">
                          <span v-for="(item, index) in currentEmployeeCommittees" :key="'employee-committee-' + index">
                            {{
                              get_committee_name(item.committee_id) +
                              (index == currentEmployeeCommittees.length - 1
                                ? "."
                                : ", ")
                            }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="current_person.user_type != 1">
                        <td class="text-left" scope="col">Designation</td>
                        <td class="text-left" v-if="current_person.employee_designation" scope="col">
                          {{ current_person.employee_designation }}
                        </td>
                        <td class="text-left" v-else>
                          <i>
                            You haven't been given a designation
                          </i>
                        </td>
                      </tr>
                      <tr v-if="current_person.user_type != 1">
                        <td class="text-left" scope="col">Branch</td>
                        <td class="text-left" v-if="current_person.branch" scope="col">
                          {{
                            getBranchNameById(current_person.branch) ||
                            "No assigned branch"
                          }}
                        </td>
                        <td class="text-left" v-else>
                          <i>
                            No assigned branch
                          </i>
                        </td>
                      </tr>
                      <tr v-if="current_person.user_type != 1">
                        <td class="text-left" scope="col">Source of Hire</td>
                        <td class="text-left" v-if="current_person.source_of_hire" scope="col">
                          {{ current_person.source_of_hire }}
                        </td>
                        <td class="text-left" v-else>
                          <i>
                            No specified source of hire
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Date of joining</td>
                        <td class="text-left" scope="col">
                          {{
                            new Date(current_person.created_at)
                              .toUTCString()
                              .substring(0, 16)
                          }}
                        </td>
                      </tr>
                      <tr v-if="userType != 1" :class="!check_all_services('Payroll') ? 'd-none' : null
                        ">
                        <td class="text-left" scope="col">Pay Grade</td>
                        <td v-if="currentPayGrade" class="text-left" scope="col">
                          {{ currentPayGrade.grade_name }}
                        </td>
                        <td v-else class="text-left" :class="!check_all_services('Payroll') ? 'd-none' : null
                          " scope="col">
                          <i>No specified pay grade</i>
                        </td>
                      </tr>
                      <tr v-if="userType != 1">
                        <td class="text-left" scope="col">Grade Level</td>
                        <td v-if="currentGradeLevel" class="text-left" scope="col">
                          {{ currentGradeLevel.gradelevel_name }}
                        </td>
                        <td v-else class="text-left" scope="col">
                          <i>No specified grade level</i>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Work phone</td>
                        <td class="text-left" scope="col">
                          {{ current_person.employee_phone1 }}
                        </td>
                      </tr>
                      <!-- <tr>
                      <td class="text-left" scope="col">Role</td>
                      <td class="text-left" scope="col">
                        {{
                            current_person.user_type == 1
                              ? "Admin"
                              : current_person.user_type == 2
                                ? "Staff"
                                : "HR"
                        }}
                      </td>
                    </tr> -->
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-12">
              <div class="card mb-4" style="border-top: 5px solid #069;">
                <div class="card-body py-3">
                  <h5 class="card-title" style="font-weight: bold;">
                    Personal
                  </h5>
                  <table class="table" :class="$vuetify.breakpoint.name == 'xs'
                      ? 'table-responsive'
                      : null
                    ">
                    <thead>
                      <tr>
                        <td class="text-left" scope="col">Mobile Phone</td>
                        <td class="text-left" scope="col">
                          {{ current_person.employee_phone1 }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Other Email</td>
                        <td class="text-left" v-if="current_person.employee_officialemail" scope="col">
                          {{ current_person.employee_officialemail }}
                        </td>
                        <td class="text-left" v-else>
                          <i>
                            No other email
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Birth Date</td>
                        <td class="text-left" v-if="current_person.employee_date_of_birth" scope="col">
                          {{ current_person.employee_date_of_birth }}
                        </td>
                        <td class="text-left" v-else>
                          <i>
                            No date of birth
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Marital Status</td>
                        <td class="text-left" v-if="current_person.employee_maritalstatus" scope="col">
                          {{ current_person.employee_maritalstatus }}
                        </td>
                        <td class="text-left" v-else>
                          Single
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left" scope="col">Address</td>
                        <td class="text-left" v-if="current_person.employee_address" scope="col">
                          {{ current_person.employee_address }}
                        </td>
                        <td class="text-left" v-else>
                          <i>
                            No address specified
                          </i>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-12" v-if="current_person.user_type != 1">
              <div class="card mb-4" style="border-top: 5px solid #069;">
                <div class="card-body py-3">
                  <h5 class="card-title" style="font-weight: bold;">
                    Education
                  </h5>
                  <table class="table" :class="$vuetify.breakpoint.name == 'xs'
                      ? 'table-responsive'
                      : null
                    ">
                    <thead>
                      <tr>
                        <th class="text-left" scope="col">School Name</th>
                        <th class="text-left" scope="col">Degree/Diploma</th>
                        <th class="text-left" scope="col">Field(s) of Study</th>
                        <th class="text-left" scope="col">
                          Date of Completion
                        </th>
                        <th class="text-left" scope="col">Additional Notes</th>
                      </tr>
                      <tr v-for="(education, index) in candidateSpecificEducation" :key="index">
                        <td class="text-left" scope="col">
                          {{ education.school_name }}
                        </td>
                        <td class="text-left" scope="col">
                          {{ education.degree_diploma }}
                        </td>
                        <td class="text-left" scope="col">
                          {{ education.field_of_study }}
                        </td>
                        <td class="text-left" scope="col">
                          {{ education.date_of_completion }}
                        </td>
                        <td class="text-left" scope="col">
                          {{ education.additional_notes }}
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-12" v-if="current_person.user_type != 1">
              <div class="card mb-4" style="border-top: 5px solid #069;">
                <div class="card-body py-3">
                  <h5 class="card-title" style="font-weight: bold;">
                    Work experience
                  </h5>
                  <table class="table" :class="$vuetify.breakpoint.name == 'xs'
                      ? 'table-responsive'
                      : null
                    ">
                    <thead>
                      <tr>
                        <th class="text-left" scope="col">Occupation</th>
                        <th class="text-left" scope="col">Company</th>
                        <th class="text-left" scope="col">Summary</th>
                        <th class="text-left" scope="col">Duration</th>
                        <th class="text-left" scope="col">
                          Currently Work Here
                        </th>
                      </tr>
                      <tr v-for="(experience,
                        index) in candidateSpecificExperience" :key="index">
                        <td class="text-left" scope="col">
                          {{ experience.occupation }}
                        </td>
                        <td class="text-left" scope="col">
                          {{ experience.company }}
                        </td>
                        <td class="text-left" scope="col">
                          {{ experience.summary }}
                        </td>
                        <td class="text-left" scope="col">
                          {{ experience.duration }}
                        </td>
                        <td class="text-left" scope="col">
                          {{ experience.currently_work_here }}
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ "Update your Profile Picture" }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint">
                      <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                        label="Upload Profile Picture" solo style="text-indent: 5px;" color="#069"
                        class="staffUploadInput2" messages="Employee Profile Image">
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                      <button @click="update_image_submit ? null : update_image()" style="width: 100%;" type="button"
                        class="btn btn-secondary m-1 ripple">
                        {{ update_image_text }}
                        <v-icon :style="!update_image_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="logoDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Update your Company Logo</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint">
                      <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                        label="Upload Company Logo" solo style="text-indent: 5px;" color="#069" class="companyLogo"
                        messages="Company Logo">
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                      <button @click="
                        update_logo_submit ? null : update_company_logo()
                        " style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                        {{ update_logo_text }}
                        <v-icon :style="!update_logo_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="logoDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, ASSETS_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
import PolicyDocuments from "../PolicyDocuments";

export default {
  components: { PolicyDocuments },
  data() {
    return {
      // all_leave: [],
      departmentName: [],
      dialog: false,
      logoDialog: false,
      employee_profile_view: 1,
      update_image_text: "Update",
      update_image_submit: false,
      update_logo_text: "Update",
      update_logo_submit: false,
      create_asset: 2,
      employee_assets: [],
      menu: false,
      menu3: false,
      menu4: false,
      view: "documents",
      employeeViewTabs: ["Documents"],
      // menu: false,
      assignFormData: {
        id: "",
        type_name: "",
        name: "",
        given_date: "",
        return_date: "",
        details: "",
        employee: ""
      },
      allEmployees: [],
      allEducation: [],
      allWorkExperience: [],
      candidateSpecificEducation: [],
      candidateSpecificExperience: []
      // all_asset_types: [],
      // apply_for_leave_text: 'Submit',
      // apply_for_leave_submit: false,
    };
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "authUserData",
      "authCompanyAssets",
      "authCompanyEmployees",
      "userType",
      "authCompanyAllAssetType",
      "authCompanyAllVendors",
      "authCompanyDepartments",
      "authCompanyCommittees",
      "authCompanyEmployees",
      "authCompanyPayGrades",
      "authCompanyGradeLevels",
      "currentEmployeeDepartments",
      "currentEmployeeCommittees",
      "authCompanyBranches"
    ]),
    currentPayGrade() {
      let _this = this,
        result = null;
      result = _this.authCompanyPayGrades.filter((pg) => {
        return _this.current_person.paygrade_id == pg.id;
      });
      if (result.length) {
        return result[0];
      }
      return result;
    },
    currentGradeLevel() {
      let _this = this,
        result = null;
      result = _this.authCompanyGradeLevels.filter((level) => {
        return _this.current_person.gradelevel_id == level.id;
      });
      if (result.length) {
        return result[0];
      }
      return null;
    },
    current_person() {
      let _this = this;
      if (_this.userType != 1) {
        return _this.authCompanyEmployees.filter((person) => {
          return person.id == _this.authEmployeeDataForCompany.id;
        })[0];
      } else {
        return _this.authCompanyEmployees.filter((person) => {
          return person.user_id == _this.authUserData.id;
        })[0];
      }
    },
    host_url() {
      return ASSETS_URL;
    }
  },
  methods: {
    // isViewable() {
    //   tabName = this.convertToSnakeCase(tabName);
    //   // if (tabName === "documents") {
    //   //   return this.userType == 1 || this.permissionIsAssigned("document-read");
    //   // }
    //   return true;
    // },
    convertToSnakeCase(string) {
      let value = string.replaceAll(" ", "_").toLowerCase();
      return value;
    },
    ...mapActions([
      "fetch_company_assets",
      "fetch_all_asset_types",
      "fetch_active_company_employees",
      "refresh_company_data",
      "fetch_company_grade_levels",
      "fetch_company_department_employees",
      "fetch_company_employee_committees",
      "fetch_company_branches"
    ]),
    viewEmployeeProfile() {
      this.employee_profile_view = 2;
    },
    viewEmployeeProfileBack() {
      this.employee_profile_view = 1;
    },
    singleEmployeeObject(id) {
      let _this = this,
        result = null;
      if (!id) {
        return result;
      }
      if (_this.authCompanyEmployees.length >= 1) {
        result = _this.authCompanyEmployees.filter((te) => te.id == id)[0];
      }
      return result;
    },
    get_dept_name(dept_id) {
      let _this = this;
      let dept = _this.authCompanyDepartments.filter((dept) => {
        return dept.id == dept_id;
      });
      return dept[0].department_name;
    },
    get_committee_name(committee_id) {
      let _this = this;
      let committee = _this.authCompanyCommittees.filter((item) => {
        return item.id == committee_id;
      });
      return committee[0].committee_name;
    },
    update_image() {
      let _this = this;
      _this.update_image_text = "";
      _this.update_image_submit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let employeeData = new FormData();
      employeeData.append(
        "id",
        _this.authEmployeeDataForCompany.id || _this.current_person.id
      );
      employeeData.append(
        "company_id",
        _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id
      );
      employeeData.append(
        "image",
        document.querySelector(".staffUploadInput2 div div div input").files[0]
      );

      axios
        .post(`${BASE_URL}uploadprofilepic`, employeeData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Profile picture updated successfully"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.fetch_active_company_employees();
          _this.dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.image
                  ? errors.image[0]
                  : null
              : null ||
              error.response.data.error ||
              error.response.data.message ||
              "Profile picture could not be updated"
          });
        })
        .then(function () {
          _this.update_image_submit = false;
          _this.update_image_text = "Update";
        });
    },
    update_company_logo() {
      let _this = this;
      _this.update_logo_text = "";
      _this.update_logo_submit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let logoData = new FormData();
      logoData.append(
        "company_id",
        _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id
      );
      logoData.append(
        "logo",
        document.querySelector(".companyLogo div div div input").files[0]
      );

      axios
        .post(`${BASE_URL}companylogo`, logoData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Company logo updated successfully"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.refresh_company_data({
            user_id: _this.authUserData.id,
            company_id: _this.authCompanyData.id
          });
          _this.logoDialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.logo
                  ? errors.logo[0]
                  : null
              : null ||
              error.response.data.error ||
              error.response.data.message ||
              "Company logo could not be updated"
          });
        })
        .then(function () {
          _this.update_logo_submit = false;
          _this.update_logo_text = "Update";
        });
    },
    getBranchNameById(branchId) {
      const _this = this;
      let name = "",
        foundBranch = [];
      foundBranch = _this.authCompanyBranches.filter(
        (branch) => branch.id == branchId
      );
      if (foundBranch > 0) {
        name = foundBranch[0].branch_name;
      }
      return name;
    }
  },
  mounted() {
    document.title = "Kylian ERP - User Profile";
    let _this = this;
    this.authCompanyDepartments.forEach((department) => {
      this.departmentName.push(department.department_name);
    });
    if (_this.userType != 1) {
      axios
        .post(`${BASE_URL}fetchcandidate`, {
          company_id: _this.authEmployeeDataForCompany.company_id
        })
        .then(function (response) {
          const RESPONSE = response.data;
          console.log(RESPONSE);
          _this.allWorkExperience = RESPONSE.Work_experience;
          _this.allEducation = RESPONSE.Education;
          let tempEducation = _this.allEducation.filter((edu) => {
            return edu.employee_id == _this.authEmployeeDataForCompany.id;
          });
          if (tempEducation.length) {
            _this.candidateSpecificEducation = tempEducation.map((edu) => {
              return {
                id: edu.id,
                company_id: edu.company_id,
                company_unique_id: edu.company_unique_id,
                school_name: edu.school_name,
                degree_diploma: edu.degree_diploma,
                field_of_study: edu.field_of_study,
                date_of_completion: edu.date_of_completion,
                additional_notes: edu.additional_notes,
                employee_id: edu.employee_id,
                created_at: edu.created_at,
                updated_at: edu.updated_at,
                viewOption: false
              };
            });
          } else {
            _this.candidateSpecificEducation.push({
              id: null,
              company_id: "",
              company_unique_id: "",
              school_name: "",
              degree_diploma: "",
              field_of_study: "",
              date_of_completion: "",
              additional_notes: "",
              employee_id: "",
              created_at: "",
              updated_at: "",
              viewOption: false
            });
          }
          let tempExperience = _this.allWorkExperience.filter((exp) => {
            return exp.employee_id == _this.authEmployeeDataForCompany.id;
          });
          if (tempExperience.length) {
            _this.candidateSpecificExperience = tempExperience.map((exp) => {
              return {
                company: exp.company,
                company_id: exp.company_id,
                company_unique_id: exp.company_unique_id,
                created_at: exp.created_at,
                currently_work_here: exp.currently_work_here,
                duration: exp.duration,
                employee_id: exp.employee_id,
                field_of_study: exp.field_of_study,
                id: exp.id,
                occupation: exp.occupation,
                summary: exp.summary,
                updated_at: exp.updated_at,
                viewOption: false
              };
            });
          } else {
            _this.candidateSpecificExperience.push({
              id: null,
              company: "",
              company_id: "",
              company_unique_id: "",
              created_at: "",
              currently_work_here: "",
              duration: "",
              employee_id: "",
              field_of_study: "",
              occupation: "",
              summary: "",
              updated_at: "",
              viewOption: false
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // context.commit('AUTH_FETCHED_EMPLOYEES', );
        });
    }
    _this.fetch_company_department_employees();
    _this.fetch_company_employee_committees();
    _this.fetch_company_branches();
  }
};
</script>

<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}

.staffUploadInput2 div div div .v-file-input__text {
  cursor: pointer;
}

.companyLogo div div div .v-file-input__text {
  cursor: pointer;
}
</style>
