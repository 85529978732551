<template>
  <div>
    <!-- <SideMenu /> -->
    <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('medicals-read'))"
      class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="employee_name ? employee_name = '' : $router.push({ name: 'ClientArea' })"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('medicals-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                    employeeView ? "Your Medical Records" : "All Employees' Medical Records"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('medicals-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Medical Records" : "Your Medical Records" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-3' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-0 row pb-0">
              <span v-if="employee_name" class="col-lg-5 col-md-5 col-12">Medical Bills for {{
                  employee_name.toLowerCase() == 'all' ? 'All Employees' : employee_name
              }}</span>
              <span v-else class="col-lg-5 col-md-5 col-12">All Medicals Allowances</span>
              <v-row class="col-md-7 col-lg-7 col-12" style="float: right;">
                <v-col offset-lg="3" offset-md="3" cols="12" lg="5" md="5" class="pt-0"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-center'">
                  <button v-if="employee_name" @click="employee_name = ''" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Show All Medical Allowances
                  </button>
                  <template v-else>
                    <button v-if="permissionIsAssigned('medicals-create')" @click="create_medicals_dialog = true"
                      type="button" class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                      Add Medical Allowance
                    </button>
                  </template>
                </v-col>
                <v-col cols="12" lg="4" md="4" class="pt-0 text-right">
                  <v-select class="remove-mb" :items="allEmployees" placeholder="Select employee bills" dense solo
                    messages="*Select Employee to View Bills" :full-width="true" v-model="employee_name"></v-select>
                </v-col>
              </v-row>
            </h1>

            <div v-if="employee_name" class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Hospital</th>
                          <th scope="col" class="text-left">Amount</th>
                          <th scope="col" class="text-left">Description</th>
                          <th scope="col" class="text-left">Date Added</th>
                          <th scope="col" class="text-center">Status</th>
                          <th scope="col" class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="bill in employee_medical_bills" :key="bill.id">
                          <td v-if="get_hospital_name(bill.hospital_id)" class="text-left">{{
                              get_hospital_name(bill.hospital_id)
                          }}</td>
                          <td v-else class="text-left">
                            <i>Hospital not found</i>
                          </td>
                          <td class="text-left" scope="row">
                            ₦{{ bill.amount | number_with_commas }}
                          </td>
                          <td class="text-left" scope="row">{{ bill.description }}
                          </td>

                          <td class="text-left">
                            {{ (new Date(bill.created_at).toUTCString().substring(0, 16)) }}
                          </td>
                          <td v-if="bill.is_approved == 0">
                            <span style="width: 60px;" class="badge badge-warning text-white text-center">PENDING</span>
                          </td>
                          <td v-else-if="bill.is_approved == 1">
                            <span style="width: 60px;" class="badge badge-success text-center">APPROVED</span>
                          </td>
                          <td v-else>
                            <span style="width: 60px;" class="badge badge-danger text-center">REJECTED</span>
                          </td>
                          <td style="padding-top: 20px;" class="text-left employee-options">
                            <div class="row">
                              <i v-if="permissionIsAssigned('medicals-comment')" @click="view_comment(bill)"
                                class="col-2 py-0 text-center fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i @click="make_current_bill(bill)" class="col-2 py-0 text-center fal fa-eye"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('medicals-approve')" @click="approve_medical_bill(bill)"
                                class="col-2 py-0 text-center fal fa-check-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('medicals-approve')" @click="disapprove_medical_bill(bill)"
                                class="col-2 py-0 text-center fal fa-times-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th class="text-left" scope="col">Employee Name</th>
                          <th class="text-left" scope="col">Amount Allocated</th>
                          <th class="text-left" scope="col">Amount Left</th>
                          <th class="text-left" scope="col">Date Added</th>
                          <th class="text-left"
                            v-if="permissionIsAssigned('medicals-update') || permissionIsAssigned('medicals-delete')"
                            scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="medical in auth_company_all_medicals" :key="medical.id">
                          <th @click="employee_name = get_employee_name(medical.employee_id)"
                            class="underline text-left" scope="row">
                            {{
                                get_employee_name(medical.employee_id)
                            }}
                          </th>
                          <td class="text-left" scope="row">₦{{ medical.initial_amount | number_with_commas }}
                          </td>

                          <td class="text-left" v-if="medical.updated_amount">
                            ₦{{ medical.updated_amount | number_with_commas }}
                          </td>
                          <td class="text-left" v-else>
                            ₦{{ medical.initial_amount | number_with_commas }}
                          </td>
                          <td class="text-left">
                            {{ medical.month }}, {{ medical.year }}
                          </td>
                          <td class="text-left"
                            v-if="permissionIsAssigned('medicals-update') || permissionIsAssigned('medicals-delete')">
                            <div class="row pt-2">
                              <!-- <div v-if="medical.initial_amount == medical.updated_amount" class="row"> -->
                              <i v-if="permissionIsAssigned('medicals-update')" @click="launch_medicals_edit(medical)"
                                class="col-3 py-0 text-center fal fa-edit"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('medicals-delete')" @click="delete_medicals(medical)"
                                class="col-3 py-0 text-center fal fa-trash"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                            <!-- <div v-else class="row">
                              <i
                                class="col-3 offset-3 py-0 text-center fal fa-edit"
                                style="color: #069 !important; font-size: 125%; cursor: default; opacity: 0.5;"
                              ></i>
                              <i
                                class="col-3 py-0 text-center fal fa-trash"
                                style="color: #069 !important; font-size: 125%; cursor: default; opacity: 0.5;"
                              ></i>
                            </div> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="$router.push({ name: 'ClientArea' })"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('medicals-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                    employeeView ? "Your Medical Records" : "All Employees' Medical Records"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('medicals-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Medical Records" : "Your Medical Records" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-0 row pb-0">
              <span class="col-lg-5 col-md-5 col-12">Your Medical Records {{ /*employee_medicals && */employee_medicals
                  ? null : '(No medical allowance)'
              }}</span>
              <!-- <span class="col-lg-5 col-md-5 col-12" v-else>Your Medical Records (No data to display)</span> -->
              <v-row v-if="employee_medicals" class="col-md-7 col-lg-7 col-12">
                <v-col cols="5" lg="4" md="4"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
                  class="pt-0 pr-0">
                  <h5 v-if="!employee_medicals">Total amount: <br />₦0</h5>
                  <template v-else>
                    <h5 v-if="employee_medicals.initial_amount">Total amount: <br />₦{{
                        employee_medicals.initial_amount | number_with_commas
                    }}</h5>
                    <h5 v-else>Total amount: <br />₦0</h5>
                  </template>
                </v-col>
                <v-col cols="7" lg="4" md="4"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
                  class="pt-0 pr-0">
                  <h5 v-if="!employee_medicals">Available amount: <br />₦0</h5>
                  <template v-else>
                    <h5 v-if="employee_medicals.updated_amount">Available amount: <br />₦{{
                        employee_medicals.updated_amount | number_with_commas
                    }}</h5>
                    <h5 v-else-if="employee_medicals.initial_amount">Available amount: <br />₦{{
                        employee_medicals.initial_amount | number_with_commas
                    }}</h5>
                    <h5 v-else>Available amount: <br />₦0</h5>
                  </template>
                </v-col>
                <v-col cols="6" lg="4" md="4" class="text-right pt-0">
                  <button @click="create_medical_bill_dialog = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 mt-0 text-white">
                    Add Medical Bill
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Hospital</th>
                          <th scope="col" class="text-left">Amount</th>
                          <th scope="col" class="text-left">Description</th>
                          <th scope="col" class="text-left">Date Added</th>
                          <th scope="col" class="text-center">Status</th>
                          <th scope="col" class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="employee_medicals">
                        <tr v-for="bill in employee_medical_bills" :key="bill.id">
                          <td v-if="get_hospital_name(bill.hospital_id)" class="text-left">{{
                              get_hospital_name(bill.hospital_id)
                          }}</td>
                          <td v-else class="text-left">
                            <i>Hospital not found</i>
                          </td>

                          <td class="text-left" scope="row">₦{{ bill.amount | number_with_commas }}
                          </td>

                          <td class="text-left">
                            {{ bill.description }}
                          </td>
                          <td class="text-left">
                            {{ bill.month }}, {{ bill.year }}
                          </td>
                          <td v-if="bill.is_approved == 0">
                            <span style="width: 60px;" class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="bill.is_approved == 1">
                            <span style="width: 60px;" class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else>
                            <span style="width: 60px;" class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td style="padding-top: 20px;" class="text-left employee-options">
                            <div class="row">
                              <i v-if="permissionIsAssigned('medicals-comment')" @click="view_comment(bill)"
                                class="col-2 py-0 text-center fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i @click="make_current_bill(bill)" class="col-2 py-0 text-center fal fa-eye"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="(bill.is_approved == 0 || bill.is_approved == 2) && permissionIsAssigned('medicals-approve')"
                                @click="launch_medical_bill_edit(bill)" class="col-2 py-0 text-center fal fa-pencil-alt"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <!-- <i
                                v-else
                                class="col-2 py-0 text-center fal fa-pencil-alt"
                                style="color: #069 !important; font-size: 125%; cursor: default; opacity: 0.3;"
                              ></i> -->
                              <i v-if="(bill.is_approved == 0 || bill.is_approved == 2) && permissionIsAssigned('medicals-approve')"
                                @click="delete_medical_bill(bill)" class="col-2 py-0 text-center fal fa-trash-alt"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <!-- <i
                                v-else
                                class="col-2 py-0 text-center fal fa-trash-alt"
                                style="color: #069 !important; font-size: 125%; cursor: default; opacity: 0.3;"
                              ></i> -->
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <v-dialog v-model="medical_bill_image" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Medical Bill Receipt</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <img :src="current_medical_bill ? assets_url + current_medical_bill.receipt_url : null" alt="" srcset="" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="medical_bill_image = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="create_medical_bill_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Medical Bill</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="billFormData.amount" placeholder="Enter amount" solo dense type="number">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Description <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="billFormData.description" placeholder="Description" solo></v-textarea>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Hospital <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="billFormData.hospital_name" item-color="#069" :items="hospitals"
                      placeholder="Select Hospital" solo color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Medical Bill Receipt <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                      placeholder="Upload the medical bill receipt" solo style="text-indent: 5px; cursor: pointer;"
                      color="#069" class="upload-medical-bill">
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="add_medical_bills_submit ? null : add_medical_bills()" style="width: 99%;"
                      type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_medical_bills_text }}
                      <v-icon :style="
                        !add_medical_bills_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="create_medical_bill_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="create_medicals_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Medical Allowance</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="6">
                    <span style="color: #111d5e;">Employee <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select class="remove-mb" :items="allEmployees2" placeholder="Select employee" dense solo
                      :full-width="true" v-model="formData.employee_name"></v-select>
                  </v-col>
                  <v-col cols="6" class="remove-hint">
                    <span style="color: #111d5e;">Initial Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.initial_amount" placeholder="Enter initial amount" solo dense
                      type="number"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button
                      v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('medicals-read'))"
                      @click="add_medicals_submit ? null : add_medicals()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_medicals_text }}
                      <v-icon :style="
                        !add_medicals_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="create_medicals_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_medicals_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Medical</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <v-text-field v-model="editFormData.initial_amount" placeholder="Initial Amount" solo dense
                      type="number"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button
                      v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('medicals-read'))"
                      @click="edit_medicals_submit ? null : edit_medicals()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ edit_medicals_text }}
                      <v-icon :style="
                        !edit_medicals_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="edit_medicals_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_medical_bill_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Your Medical Bill</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editBillFormData.amount" placeholder="Amount" solo dense type="number">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Description <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="editBillFormData.description" placeholder="Description" solo></v-textarea>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Hospital <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="editBillFormData.hospital_name" item-color="#069" :items="hospitals"
                      placeholder="Select Hospital" solo color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Medical Bill Receipt <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                      placeholder="Update the medical bill receipt" solo style="text-indent: 5px; cursor: pointer;"
                      color="#069" class="upload-medical-bill-2">
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="edit_medical_bill_submit ? null : edit_medical_bill()" style="width: 99%;"
                      type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ edit_medical_bill_text }}
                      <v-icon :style="
                        !edit_medical_bill_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="edit_medical_bill_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="flex-grow-1"></div>

    <v-dialog v-model="comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ approval ? 'Approve' : 'Disapprove' }} Medical Bill with Comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <v-textarea v-model="comment"
                      :placeholder="(userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('medicals-read'))) ? 'Medical Bill Comment' : 'No comment'"
                      solo :disabled="userType != 1 ? true : false"></v-textarea>
                  </v-col>
                  <v-col
                    v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('medicals-read'))"
                    cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="add_comment_submit ? null : medical_bill_action()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_comment_text }}
                      <v-icon :style="
                        !add_comment_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="medical_bill_comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Medical Bill Comments</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-left">Date Sent</th>
                  <th class="text-left">Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="comment in all_medical_bill_comments" :key="comment.id">
                  <td class="text-left">
                    {{ new Date(comment.created_at).toUTCString().substring(0, 25) }}
                  </td>
                  <td class="text-left">
                    {{ comment.comments }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="medical_bill_comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      employeeView: true,
      employee_comment: null,
      current_bill: null,
      add_comment_text: "Submit",
      add_comment_submit: false,
      approval: false,
      comment_dialog: false,
      comment: "",
      billFormData: { amount: "", description: "", hospital_name: "" },
      editBillFormData: { id: "", amount: "", description: "", hospital_name: "" },
      formData: { employee_name: "", initial_amount: "" },
      editFormData: { id: "", initial_amount: "" },
      medical_bill_image: false,
      create_medical_bill_dialog: false,
      edit_medical_bill_dialog: false,
      edit_medicals_dialog: false,
      edit_medical_bill_text: "Update",
      edit_medical_bill_submit: false,
      edit_medicals_text: "Update",
      edit_medicals_submit: false,
      create_medicals_dialog: false,
      add_medical_bills_text: "Submit",
      add_medical_bills_submit: false,
      add_medicals_text: "Submit",
      add_medicals_submit: false,
      employee_name: "",
      employee: null,
      day_selected: "",
      view: "date",
      monthSelected: "",
      yearSelected: "",
      checkingDetails: null,
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      firstYear: "",
      allEmployees: ['All'],
      allEmployees2: [],
      medical_bills: [],
      hospitals: [],
      current_medical_bill: null,
      all_medical_bill_comments: [],
      medical_bill_comment_dialog: false
    };
  },
  watch: {
    employee_name: function (t) {
      t && this.fetch_medical_bill();
    },
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authCompanyEmployees",
      "authEmployeeDataForCompany",
      "true_employees",
      "auth_company_all_medicals",
      "authCompanyHospitals"
    ]),
    assets_url() {
      return ASSETS_URL;
    },
    hospital_id() {
      return 1;
    },
    employee_medicals: function () {
      var t = this;
      if (!t.auth_company_all_medicals.length) {
        return [];
      }
      return t.auth_company_all_medicals
        ? t.auth_company_all_medicals.filter(function (e) {
          return e.employee_id == t.authEmployeeDataForCompany.id;
        })[0]
        : null;
    },
    employee_medical_bills: function () {
      var t = this;
      return t.medical_bills
        ? (
          t.employee_name.toLowerCase() == 'all'
            ?
            t.medical_bills
            :
            t.medical_bills.filter(function (e) {
              return e.employee_id == (t.authEmployeeDataForCompany.id || t.employee_id);
            })
        )
        : [];
    },
    medical_bill_employee_id: function () {
      var t = this;
      if (t.employee_name.toLowerCase() == 'all') {
        return t.true_employees && t.employee_name
          ? t.true_employees.filter(function (e) {
            return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
          })[0].id
          : null;
      } else {
        // return null;
        return t.true_employees && t.employee_name
          ? t.true_employees.filter(function (e) {
            return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
          })[0].id
          : null;
      }
    },
    medical_employee_id: function () {
      var t = this;
      return t.true_employees && t.formData.employee_name
        ? t.true_employees.filter(function (e) {
          return e.employee_fristname + " " + e.employee_lastname == t.formData.employee_name;
        })[0].id
        : null;
    },
    employee_id: function () {
      var t = this;
      return t.true_employees && t.employee_name
        ? t.true_employees.filter(function (e) {
          return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
        })[0].id
        : null;
    },
  },
  methods: {
    ...mapActions([
      "fetch_all_medicals",
      "fetch_all_company_hospitals"
    ]),
    get_hospital_id(name) {
      let _this = this;
      let hospital = _this.authCompanyHospitals.filter(
        h => h.name == name
      );
      if (hospital.length) {
        return hospital[0].id;
      } else {
        return null;
      }
    },
    get_hospital_name(id) {
      let _this = this;
      let hospital = _this.authCompanyHospitals.filter(
        h => h.id == id
      );
      if (hospital.length) {
        return hospital[0].name;
      } else {
        return null;
      }
    },
    view_comment(bill) {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchmedicalbillcomment`, {
          company_id: bill.company_id,
          employee_id: bill.employee_id,
          medicalbill_id: bill.id
        })
        .then(function (response) {
          _this.all_medical_bill_comments = response.data.comments
          _this.medical_bill_comment_dialog = true;
        })
        .catch(function () {
          //
        })
        .then(function () {
          //
        });
    },
    medical_bill_action() {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.add_comment_text = '';
      e.add_comment_submit = true;
      if (e.approval) {
        axios
          .post(`${BASE_URL}approvemedicalbills`, {
            company_id: e.current_bill.company_id,
            employee_id: e.current_bill.employee_id,
            amount: e.current_bill.amount,
            medicalbill_id: e.current_bill.id,
            comment: e.comment
          })
          .then(function () {
            a.fire({ icon: "success", title: "Medical bill approved successfully" });
            e.comment_dialog = false;
          })
          .catch(function () {
            a.fire({ icon: "error", title: "Medical bill couldn't be approved" });
          })
          .then(function () {
            e.fetch_all_medicals(), e.fetch_medical_bill();
            e.add_comment_submit = false;
            e.add_comment_text = 'Submit';
          });
      } else {
        axios
          .post(`${BASE_URL}rejectmedicalbills`, {
            company_id: e.current_bill.company_id,
            employee_id: e.current_bill.employee_id,
            medicalbill_id: e.current_bill.id,
            comment: e.comment
          })
          .then(function () {
            a.fire({ icon: "success", title: "Medical bill rejected successfully" });
            e.comment_dialog = false;
          })
          .catch(function () {
            a.fire({ icon: "error", title: "Medical bill couldn't be rejected" });
          })
          .then(function () {
            e.fetch_all_medicals(), e.fetch_medical_bill();
            e.add_comment_submit = false;
            e.add_comment_text = 'Submit';
          });
      }
    },
    make_current_bill(bill) {
      this.current_medical_bill = bill;
      this.medical_bill_image = true;
    },
    hasHistory: function () {
      return window.history.length > 2;
    },
    launch_medicals_edit: function (t) {
      (this.editFormData.initial_amount = t.initial_amount), (this.editFormData.id = t.id), (this.edit_medicals_dialog = !0);
    },
    launch_medical_bill_edit: function (t) {
      (this.editBillFormData.id = t.id),
        (this.editBillFormData.amount = t.amount),
        (this.editBillFormData.description = t.description),
        (this.editBillFormData.hospital_name = t.get_hospital_name(t.hospital_id))
          (this.edit_medical_bill_dialog = !0);
    },
    fetch_medical_bill: function () {
      var t = this;
      axios
        .post(`${BASE_URL}fetchmedicalbillsbyemployee`, {
          company_id: t.authCompanyData.id,
          employee_id: t.medical_bill_employee_id || t.authEmployeeDataForCompany.id
        })
        .then(function (e) {
          var a = e.data;
          t.medical_bills = a.medicals;
        })
        .catch(function () { })
        .then(function () { });
    },
    disapprove_medical_bill(t) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to reject this medical bill?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Reject",
        })
        .then(function (s) {
          e.approval = false;
          e.current_bill = t;
          s.isConfirmed ? e.comment_dialog = true : '';
        });
    },
    approve_medical_bill(t) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to approve this medical bill?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Approve",
        })
        .then(function (s) {
          e.approval = true;
          e.current_bill = t;
          s.isConfirmed ? e.comment_dialog = true : '';
        });
    },
    edit_medical_bill: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      let form_data = new FormData();
      form_data.append("medicalbill_id", t.editBillFormData.id);
      form_data.append("amount", t.editBillFormData.amount);
      form_data.append("description", t.editBillFormData.description);
      form_data.append("hospital_id", t.get_hospital_id(t.editBillFormData.hospital_name));
      if (!!document.querySelector(".upload-medical-bill-2 div div div input")
        .files[0] == true) {
        form_data.append(
          "receipt",
          document.querySelector(".upload-medical-bill-2 div div div input")
            .files[0] || ''
        );
      }
      t.edit_medical_bill_submit ||
        ((t.edit_medical_bill_text = ""),
          (t.edit_medical_bill_submit = !0),
          axios
            .post(`${BASE_URL}editmedicalbill`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              e.fire({ icon: "success", title: "Your medical bill has been updated" }),
                t.fetch_all_medicals(),
                t.fetch_medical_bill(),
                (t.editBillFormData.id = ""),
                (t.editBillFormData.amount = ""),
                (t.editBillFormData.description = ""),
                (t.edit_medical_bill_dialog = !1);
              document
                .querySelector(
                  '.upload-medical-bill-2 div div div div button'
                )
                .click();
            })
            .catch(function (error) {
              let errors = error.response.data.error || null;
              e.fire({
                icon: "error",
                title: errors ? (typeof errors === 'string' ? errors : null
                  || errors.amount ? errors.amount[0] : null
                    || errors.employee_id ? errors.employee_id[0] : null
                      || errors.description ? errors.description[0] : null
                        || errors.hospital_id ? errors.hospital_id[0] : null) : null
                        || error.response.data.error || error.response.data.message || 'Medical bill couldn\'t be updated'
              });
            })
            .then(function () {
              (t.edit_medical_bill_submit = !1), (t.edit_medical_bill_text = "Update");
            }));
    },
    edit_medicals: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      t.edit_medicals_submit ||
        ((t.edit_medicals_text = ""),
          (t.edit_medicals_submit = !0),
          axios
            .post(`${BASE_URL}editmedical`, { medical_id: t.editFormData.id, initial_amount: t.editFormData.initial_amount })
            .then(function () {
              e.fire({ icon: "success", title: "Medicals have been updated" }), t.fetch_all_medicals(), (t.editFormData.initial_amount = ""), (t.edit_medicals_dialog = !1);
            })
            .catch(function (error) {
              let errors = error.response.data.error || null;
              e.fire({
                icon: "error",
                title: errors ? (typeof errors === 'string' ? errors : null
                  || errors.initial_amount ? errors.initial_amount[0] : null
                    || errors.employee_id ? errors.employee_id[0] : null) : null
                    || error.response.data.error || error.response.data.message || 'Medicals couldn\'t be updated'
              });
            })
            .then(function () {
              (t.edit_medicals_submit = !1), (t.edit_medicals_text = "Update");
            }));
    },
    add_medical_bills: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      let form_data = new FormData();
      form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
      form_data.append("employee_id", t.authEmployeeDataForCompany.id);
      form_data.append("amount", t.billFormData.amount);
      form_data.append("description", t.billFormData.description);
      form_data.append("hospital_id", t.get_hospital_id(t.billFormData.hospital_name));
      form_data.append(
        "receipt",
        document.querySelector(".upload-medical-bill div div div input")
          .files[0]
      );

      t.add_medical_bills_submit ||
        ((t.add_medical_bills_text = ""),
          (t.add_medical_bills_submit = !0),
          axios
            .post(`${BASE_URL}addmedicalbill`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              e.fire({ icon: "success", title: "Medical bill for " + t.employee_name + " have been added" }),
                t.fetch_medical_bill(),
                t.fetch_all_medicals(),
                (t.billFormData.amount = ""),
                (t.billFormData.description = ""),
                (t.billFormData.hospital_name = ""),
                (t.create_medical_bill_dialog = !1);
              document
                .querySelector(
                  '.upload-medical-bill div div div div button'
                )
                .click();
            })
            .catch(function (error) {
              let errors = error.response.data.error || null;
              e.fire({
                icon: "error",
                title: errors ? (typeof errors === 'string' ? errors : null
                  || errors.amount ? errors.amount[0] : null
                    || errors.employee_id ? errors.employee_id[0] : null
                      || errors.description ? errors.description[0] : null
                        || errors.hospital_id ? errors.hospital_id[0] : null) : null
                        || error.response.data.error || error.response.data.message || 'Medical bill couldn\'t be added'
              });
            })
            .then(function () {
              (t.add_medical_bills_submit = !1), (t.add_medical_bills_text = "Submit");
            }));
    },
    add_medicals: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      t.add_medicals_submit ||
        ((t.add_medicals_text = ""),
          (t.add_medicals_submit = !0),
          axios
            .post(`${BASE_URL}addemployeemedical`, { medicals: [{ company_id: t.authCompanyData.id, employee_id: t.medical_employee_id, initial_amount: t.formData.initial_amount }] })
            .then(function () {
              e.fire({ icon: "success", title: "Medicals have been added" }),
                t.fetch_all_medicals(), (t.formData.initial_amount = ""), (t.create_medicals_dialog = !1);
            })
            .catch(function (error) {
              let errors = error.response.data.error || null;
              e.fire({
                icon: "error",
                title: errors ? (typeof errors === 'string' ? errors : null
                  || errors.initial_amount ? errors.initial_amount[0] : null
                    || errors.employee_id ? errors.employee_id[0] : null) : null
                    || error.response.data.error || error.response.data.message || 'Medicals couldn\'t be added'
              });
              (t.add_medicals_submit = !1), (t.add_medicals_text = "Submit");
            })
            .then(function () {
              (t.add_medicals_submit = !1), (t.add_medicals_text = "Submit");
            }));
    },
    get_employee_name: function (t) {
      var e = this;
      if (e.true_employees) {
        var a = e.true_employees.filter(function (e) {
          return e.id == t;
        })[0];
        console.log(a);
        console.log('ABOVE IS EMPLOYEE')
        return a.employee_fristname + " " + a.employee_lastname;
      }
      return "No employee";
    },
    delete_medical_bill: function (t) {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.$swal
        .fire({
          title: "Are you sure you want to delete the medical bill?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Delete",
        })
        .then(function (s) {
          s.isConfirmed &&
            axios
              .post(`${BASE_URL}deletemedicalbill`, { medicalbill_id: t.id })
              .then(function () {
                a.fire({ icon: "success", title: "Medical bill deleted successfully" });
              })
              .catch(function () {
                a.fire({ icon: "error", title: "Medical bill couldn't be deleted" });
              })
              .then(function () {
                e.fetch_all_medicals(), e.fetch_medical_bill();
              });
        });
    },
    delete_medicals: function (t) {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.$swal
        .fire({ title: "Are you sure you want to delete the medical for " + e.get_employee_name(t.employee_id) + " dating " + t.month + ", " + t.year + "?", showCancelButton: !0, confirmButtonText: "Yes, Delete" })
        .then(function (s) {
          s.isConfirmed &&
            axios
              .post(`${BASE_URL}deletemedical`, { ids: [{ id: t.id }] })
              .then(function () {
                a.fire({ icon: "success", title: "Medical deleted successfully" });
              })
              .catch(function () {
                a.fire({ icon: "error", title: "Medical couldn't be deleted" });
              })
              .then(function () {
                e.fetch_all_medicals();
              });
        });
    },
  },
  mounted() {
    let _this = this;
    _this.fetch_all_medicals();
    _this.fetch_medical_bill();
    _this.fetch_all_company_hospitals();
    document.title = "Kylian ERP - Medicals";
    1 != this.userType && (this.fetch_all_medicals(), this.fetch_medical_bill()), (document.title = "Kylian ERP - Medicals");
    var t = this;
    t.true_employees.forEach(function (e) {
      t.allEmployees.push(e.employee_fristname + " " + e.employee_lastname), t.allEmployees2.push(e.employee_fristname + " " + e.employee_lastname);
    });
    if (_this.userType != 1) {
      axios
        .post(`${BASE_URL}fetchmedicalbillcomment`, {
          employee_id: _this.authEmployeeDataForCompany.id
        })
        .then(response => {
          _this.employee_comment = response.data.comments;
        })
        .catch(() => {
          //
        })
        .then(() => {
          //
        })
    }
    this.hospitals = this.authCompanyHospitals.map(h => {
      return h.name
    });
  },
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.upload-medical-bill div div,
.upload-medical-bill-2 div div {
  cursor: pointer !important;
}

.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
