<template>
  <!-- <v-app v-if="!isAuth"
    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'px-100-left-stuff px-100-right-stuff'"
    class="mb-10"> -->
  <v-app v-if="!isAuth" class="mb-10">
    <v-navigation-drawer class="hidden-lg-and-up" v-model="sidebar" app right>
      <v-list class="px-2">
        <v-list-item to="/">
          <v-list-item-content class="menu-list">Home</v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="px-2">
        <v-list-item to="/core-features">
          <v-list-item-content class="menu-list">Core Features</v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="px-2">
        <v-list-item to="/pricing">
          <v-list-item-content class="menu-list">Pricing</v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="px-2">
        <v-list-item to="/blog">
          <v-list-item-content class="menu-list">Blog</v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <v-list class="px-2">
        <v-list-item to="/request-demo">
          <v-list-item-content class="menu-list">Request Demo</v-list-item-content>
        </v-list-item>
      </v-list> -->
      <v-list class="px-2">
        <v-list-item to="/contact-us">
          <v-list-item-content class="menu-list">Contact Us</v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="px-2">
        <v-list-item to="/login">
          <v-list-item-content class="menu-list">Sign In</v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="px-2">
        <v-list-item to="/register">
          <v-list-item-content class="menu-list">Register</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="px-2" app height="104"
      style="font-family: Inter; box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%); background: #fff;">

      <v-toolbar-title class="px-0" style="max-width: 220px;">
        <router-link style="display: contents !important;" tag="a" class="navbar-brand"
          :to="isAuth ? { name: 'ClientArea' } : { name: 'Home' }">
          1Howz
        </router-link>
      </v-toolbar-title>
      <v-spacer class="d-flex">
        <ul class="navbar-nav mx-auto hidden-md-only hidden-sm-only hidden-xs-only">
          <li class="nav-item">
            <router-link :class="$route.name == 'Home' ? 'active' : null"
              style="font-size: 17px; font-family: 'Inter' !important; letter-spacing: 0px;" class="nav-link"
              to="/">Home
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link style="font-size: 17px; font-family: 'Inter' !important;" class="nav-link" to="/">About Us </router-link>
          </li> -->
          <li class="nav-item">
            <router-link :class="$route.name == 'CoreFeatures' ? 'active' : null"
              style="font-size: 17px; font-family: 'Inter' !important; letter-spacing: 0px;" class="nav-link"
              :to="'/core-features'">Core
              Features </router-link>
          </li>
          <li class="nav-item">
            <router-link :class="$route.name == 'Pricing' ? 'active' : null"
              style="font-size: 17px; font-family: 'Inter' !important; letter-spacing: 0px;" class="nav-link"
              :to="'/pricing'">Pricing</router-link>
          </li>
          <li class="nav-item">
            <router-link :class="$route.name == 'Blog' ? 'active' : null"
              style="font-size: 17px; font-family: 'Inter' !important; letter-spacing: 0px;" class="nav-link"
              to="/blog">Blog </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :class="$route.name == 'RequestDemo' ? 'active' : null"
              style="font-size: 17px; font-family: 'Inter' !important; letter-spacing: 0px;" class="nav-link"
              to="/request-demo">Request
              Demo </router-link>
          </li> -->
          <li class="nav-item">
            <router-link style="font-size: 17px; font-family: 'Inter' !important; letter-spacing: 0px;" class="nav-link"
              to="/contact-us">
              Contact
              Us </router-link>
          </li>
        </ul>
      </v-spacer>
      <v-toolbar-items class="hidden-md-only hidden-sm-only hidden-xs-only">
        <div style="align-self: center;">
          <router-link to="/login" tag="button" class="px-5 py-2 mr-3 rounded-lg"
            style="border: 2px solid #0B54C0; color: #0B54C0; font-weight: bold;">
            <span style="font-size: 17px; font-family: 'Inter' !important;">
              Sign In
            </span>
          </router-link>
          <router-link to="/register" tag="button" class="px-5 py-2 rounded-lg"
            style="background: #0B54C0; border: 2px solid #0B54C0; color: #FFF; font-weight: bold;">
            <span style="font-size: 17px; font-family: 'Inter' !important;">
              Get Started
            </span>
          </router-link>
        </div>
      </v-toolbar-items>
      <span class="hidden-lg-and-up" style="float: right;">
        <i class="fas fa-bars fa-2x" @click="sidebar = !sidebar"></i>
      </span>
    </v-app-bar>
  </v-app>
  <v-app class="pb-5"
    v-else-if="!isAuth || (isAuth && authCompanyData.is_service_selected == 0) || ($route.name == 'SetupCompany' && authCompanyData.is_service_selected == 1)">
    <nav class="container navbar navbar-expand-lg navbar-light bg-light">
      <div :class="$route.name == 'Welcome' || $route.name == 'SetupCompany' ? null : 'd-block'"
        class="collapse navbar-collapse d-lg-flex d-md-flex" id="navbarTogglerDemo01">
        <router-link style="display: contents !important;" tag="a" class="navbar-brand"
          :to="isAuth ? { name: 'ClientArea' } : { name: 'Home' }">
        1Howz
        </router-link>
        <ul id="top-nav" class="navbar-nav mr-0 mt-0 mt-lg-0 pl-0 d-inline-flex d-md-flex dmlg-flex"
          style="margin: auto; margin-bottom: 0;">
        </ul>
        <div v-if="isAuth">
          <router-link v-if="1 == 0" :to="{ name: 'MainNotifications' }">
            <v-badge bordered overlap color="#069" offset-x="20" offset-y="15" content="4" value="4">
              <v-icon x-large color="#111D5E" style="font-size: 50px; cursor: pointer;">
                mdi-bell-outline
              </v-icon>
            </v-badge>
          </router-link>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="ml-5" style="display: inline;">
                <span class="mr-3">{{ authUserData.name }}</span>

                <v-avatar>
                  <img src="/assets/images/default_profile_img.png" alt="Default-Image" />
                </v-avatar>
              </div>
            </template>

            <v-list>
              <!-- <v-list-item>
                <v-list-item-title>
                  <a class="small-link" @click.prevent="$router.push({ name: 'RequestDemo' })">Request Demo</a>
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item>
                <v-list-item-title>
                  <a class="small-link" @click.prevent="$router.push({ name: 'SetupCompany' })">Setup Company</a>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <a class="small-link" @click.prevent="logout_request()">Logout</a>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </div>
      </div>
    </nav>

    <v-row justify="center">
      <v-dialog v-model="menu" width="500px">
        <v-card>
          <v-card-title class="headline lighten-2">
            <v-row v-if="show">
              <v-col v-for="app in apps" :key="app.id" md="6" sm="6" @click="menu = false">
                <router-link :to="{ name: app.linkName }" style="text-decoration: none;">
                  <v-card class="right-card pb-2"
                    style="cursor: pointer; box-shadow: 0px 0px 20px 0px #00000014 !important;">
                    <h3 class="pb-0" style="color: #069 !important;">
                      <v-icon class="mdi-all-apps-size mt-10" x-large color="#069">
                        {{ app.icon }}
                      </v-icon>
                    </h3>
                    <p class="lead text-center mb-0" style="font-size: 1rem;">
                      {{ app.header }}
                    </p>
                  </v-card>
                </router-link>
              </v-col>
              <v-col md="6" sm="6" @click="menu = false">
                <router-link to="/clientarea" style="text-decoration: none;">
                  <v-card class="right-card pb-2"
                    style="cursor: pointer; box-shadow: 0px 0px 20px 0px #00000014 !important;">
                    <h3 class="pb-0" style="color: #069 !important;">
                      <v-icon class="mdi-all-apps-size mt-10" x-large color="#111d5ea3">
                        mdi-plus-circle-outline
                      </v-icon>
                    </h3>
                    <p class="lead text-center mb-0" style="opacity: 0; cursor: default;">
                      &nbsp;*
                    </p>
                  </v-card>
                </router-link>
              </v-col>
            </v-row>
            <div v-else class="text-center" style="height: 45px; width: 100%; background-color: #fff;">
              <v-progress-circular indeterminate color="#069"></v-progress-circular>
            </div>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
  <div class="main-header noShowOnPrint px-4"
    :style="$route.name == 'SetupCompany' ? 'display: none;' : 'height: 71px;'" v-else>
    <div v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'" class="menu-toggle">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <router-link style="display: contents !important;"
      :to="{ name: 'ClientArea', params: { subdomain: authCompanyData.subdomain } }">
      1Howz
    </router-link>
    <div class="header-part-right">
      <div v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'" style="padding-right: 55px;">
        <h4 style="font-weight: bold; margin: auto;">
          {{ authCompanyData.company_name }}
        </h4>
      </div>

      <div class="dropdown">
        <div @click="run_click()" class="badge-top-container" role="button" id="dropdownNotification"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span v-if="all_unread != 0" class="badge badge-primary">{{
    all_unread
  }}</span>
          <template v-if="userType == 1">
            <i v-if="permissionIsAssigned('announcement-read')" @click="$router.push({ name: 'Announcements' })"
              class="fal fa-bell text-muted header-icon"></i>
          </template>
          <i v-else class="fal fa-bell text-muted header-icon"></i>
        </div>

        <div v-if="userType != 1"
          class="menu-scrollbar-stuff dropdown-menu dropdown-menu-right notification-dropdown rtl-ps-none"
          aria-labelledby="dropdownNotification" data-perfect-scrollbar data-suppress-scroll-x="true">
          <template v-if="authCompanyAllEmployeeAnnouncements.length >= 1">
            <div>
              <button @click="delete_announcements()" style="float: right;" type="button"
                class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <div class="dropdown-item d-flex" v-for="announcement in authCompanyAllEmployeeAnnouncements"
              :key="announcement.id" @click="load_announcement(announcement)">
              <div class="notification-icon">
                <i class="i-Speach-Bubble-6 text-primary mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center" :style="announcement.read == 0 ? 'font-weight: bold;' : ''">
                  <span style="color: #58515f !important;">{{
    announcement.title.substring(0, 25) +
    (announcement.title.length > 25 ? "..." : "")
  }}</span>
                  <span v-if="announcement.read == 0" class="badge badge-pill badge-primary ml-1 mr-1">new</span>
                  <span class="flex-grow-1"></span>
                  <span class="text-small text-muted ml-auto" style="font-weight: 100;">{{
    timeSince(
      Date.parse(
        announcement.publish_date +
        " " +
        announcement.publish_time
      )
    )
  }}
                    ago</span>
                </p>
                <p class="text-small text-muted m-0">
                  {{
      announcement.description.substring(0, 30) +
      (announcement.description.length > 30 ? "..." : "")
    }}
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="dropdown">
        <div class="user col align-self-end py-0">
          <img @click="run_click2()" :src="current_person
    ?
    (current_person.profile_image_url ? host_url + current_person.profile_image_url : '/assets/images/default_profile_img.png')
    : '/assets/images/default_profile_img.png'
    " id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <div class="dropdown-header">
              <i class="i-Lock-User mr-1"></i> {{ authUserData.name }}
            </div>
            <a v-if="userType == 1 && authCompanyServices.length > 2" class="dropdown-item"
              @click="$router.push({ name: 'Settings' })">Account
              settings</a>
            <a v-else class="dropdown-item" @click="$router.push({ name: 'Profile' })">Profile</a>
            <a v-if="userType == 1 && authCompanyServices.length > 2" class="dropdown-item"
              @click="$router.push({ name: 'BillingHistory' })">Billing
              history</a>
            <a class="dropdown-item" @click="change_password_dialog = true">Change password</a>
            <a style="cursor: pointer;" class="dropdown-item" @click="logout_request()">Log out</a>
          </div>
        </div>
      </div>

      <v-row class="mx-auto" justify="center">
        <v-dialog v-model="view_announcement_dialog" max-width="600px" class="sssss">
          <v-card>
            <v-card-title>
              <span class="headline">{{ "announcement".toUpperCase() }} [{{ announcement_day }} -
                {{ announcement_time }}]</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" class="remove-hint">
                        <h4>{{ announcement_title }}</h4>
                      </v-col>
                      <v-col cols="12" class="remove-hint">
                        <p>{{ announcement_body }}</p>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="view_announcement_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <v-dialog v-model="change_password_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Change password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Current password <i class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.currentpassword" solo dense
                      :append-icon="show1 ? 'fal fa-eye' : 'fal fa-eye-slash'" :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1" class="remove-bottom-hint"
                      placeholder="Enter your current password"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">New password <i class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.newpassword" solo dense
                      :append-icon="show2 ? 'fal fa-eye' : 'fal fa-eye-slash'" :type="show2 ? 'text' : 'password'"
                      @click:append="show2 = !show2" class="remove-bottom-hint" placeholder="Enter your new password">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Password confirmation <i class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.passwordconfirmation" solo dense append-icon="fal fa-eye-slash"
                      type="password" class="remove-bottom-hint" placeholder="Confirm your new password"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                    <button @click="change_password_submit ? null : change_password()" style="width: 100%;"
                      type="button" class="btn btn-secondary m-1 ripple">
                      {{ change_password_text }}
                      <v-icon :style="!change_password_submit
    ? 'display: none;'
    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
    ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="change_password_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, ASSETS_URL } from "@/main";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      formData: {
        currentpassword: '',
        newpassword: '',
        passwordconfirmation: ''
      },
      change_password_text: 'Submit',
      change_password_submit: false,
      show1: false,
      show2: false,
      show3: false,
      change_password_dialog: false,
      times: 0,
      times2: null,
      show: "",
      closeOnClick: true,
      menu: false,
      apps: [],
      drawer: null,
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" }
      ],
      view_announcement_dialog: false,
      announcement_title: "",
      announcement_body: "",
      announcement_day: "",
      announcement_time: "",
      appTitle: 'Awesome App',
      sidebar: false,
      menuItems: [
        { title: 'Home', path: '/home', icon: 'home' },
        { title: 'Sign Up', path: '/signup', icon: 'face' },
        { title: 'Sign In', path: '/signin', icon: 'lock_open' }
      ]
    };
  },
  watch: {
    isAuth(newValue) {
      if (newValue == true) {
        let perfect_scroll_js = document.createElement('script')
        perfect_scroll_js.setAttribute('src', '/assets/js/perfect-scrollbar.min.js')
        document.body.appendChild(perfect_scroll_js)

        setTimeout(() => {
          let script_js = document.createElement('script')
          script_js.setAttribute('src', '/assets/js/script.min.js')
          document.body.appendChild(script_js)
        }, 500);

        let bootstrap_bundle_js = document.createElement('script')
        bootstrap_bundle_js.setAttribute('src', '/assets/bootstrap/js/bootstrap.bundle.min.js')
        document.body.appendChild(bootstrap_bundle_js)
      } else {
        this.times2 = 0;

        this.$router.push("/login");

        let perfect_scroll_js = document.querySelector("[src='/assets/js/perfect-scrollbar.min.js']");
        document.body.removeChild(perfect_scroll_js)

        let script_js = document.querySelectorAll("[src='/assets/js/script.min.js']");
        document.body.removeChild(script_js[script_js.length - 1])

        let bootstrap_bundle_js = document.querySelector("[src='/assets/bootstrap/js/bootstrap.bundle.min.js']");
        document.body.removeChild(bootstrap_bundle_js);
      }
    },
    authCompanyServices(newValue) {
      if (newValue) {
        if (this.humanResources.length >= 1) {
          let HRSample = {
            icon: "mdi-account-group-outline",
            header: "Human Resources",
            linkName: "HumanResources"
          };
          // if (this.apps.indexOf(HRSample) < 0) {
          //     this.apps.push(HRSample)
          // }
          let countHR = 0;
          this.apps.forEach(app => {
            if (app.header.toLowerCase() == "human resources") {
              countHR++;
            }
          });
          if (countHR == 0) {
            this.apps.push(HRSample);
          }
        }
        if (this.accounts.length >= 1) {
          let ACSample = {
            icon: "mdi-cash",
            header: "Accounts",
            linkName: "Accounts"
          };
          let countAC = 0;
          this.apps.forEach(app => {
            if (app.header.toLowerCase() == "accounts") {
              countAC++;
            }
          });
          if (countAC == 0) {
            this.apps.push(ACSample);
          }
        }
        this.show = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "isAuth",
      "authUserData",
      "authCompanyData",
      "authCompanyServices",
      "authCompanyEmployees",
      "humanResources",
      "accounts",
      "authCompanyAllEmployeeAnnouncements",
      "authEmployeeDataForCompany",
      "userType",
      "true_employees"
    ]),
    announcement_ids_to_delete() {
      let _this = this;
      return _this.authCompanyAllEmployeeAnnouncements.map(announcement => {
        return {
          id: announcement.announcement_id
        }
      });
    },
    current_person() {
      let _this = this;
      if (_this.userType != 1) {
        if (_this.true_employees && _this.authEmployeeDataForCompany) {
          return _this.true_employees.filter(person => {
            return person.id == _this.authEmployeeDataForCompany.id;
          })[0];
        } else {
          return "";
        }
      } else {
        if (_this.authCompanyEmployees && _this.authUserData) {
          return _this.authCompanyEmployees.filter(person => {
            return person.user_id == _this.authUserData.id;
          })[0];
        } else {
          return "";
        }
      }
    },
    host_url() {
      return ASSETS_URL;
    },
    all_unread() {
      if (this.authCompanyAllEmployeeAnnouncements) {
        let unread = this.authCompanyAllEmployeeAnnouncements.filter(item => {
          return item.read == 0;
        });
        return unread.length;
      }
      return 0;
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_company_employee_announcements"
    ]),
    goToCoreFeatures() {
      document.getElementById('goToCoreFeatures').click();
    },
    delete_announcements() {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.$swal
        .fire({
          title: _this.announcement_ids_to_delete.length == 1 ? "Are you sure you want to delete this announcement?" : "Are you sure you want to delete these announcements?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deletemultipleannouncement`, {
                ids: _this.announcement_ids_to_delete
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: _this.announcement_ids_to_delete.length == 1 ? "Announcement deleted successfully" : "Announcements deleted successfully"
                });
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title: _this.announcement_ids_to_delete.length == 1 ? "Announcement couldn't be deleted" : "Announcements couldn't be deleted"
                });
                console.log(error);
              })
              .then(function () {
                _this.fetch_all_company_employee_announcements();
              });
          }
        });
    },
    change_password() {
      let _this = this;
      _this.change_password_text = "";
      _this.change_password_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      if (_this.formData.newpassword !== _this.formData.passwordconfirmation) {
        _this.change_password_submit = false;
        _this.change_password_text = "Submit";
        Toast.fire({
          icon: "error",
          title: "The new password and it's confirmation don't match"
        });
        return;
      }
      if (_this.formData.newpassword.length < 8) {
        _this.change_password_submit = false;
        _this.change_password_text = "Submit";
        Toast.fire({
          icon: "error",
          title: "The new password must be at least 8 characters long"
        });
        return;
      }
      axios
        .post(`${BASE_URL}changeuserpassword`, {
          user_id: _this.authUserData.id,
          currentpassword: _this.formData.currentpassword,
          newpassword: _this.formData.newpassword,
          passwordconfirmation: _this.formData.passwordconfirmation,
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Password changed successfully"
          });
          _this.formData.currentpassword = "";
          _this.formData.newpassword = "";
          _this.formData.passwordconfirmation = "";
          _this.change_password_dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.currentpassword ? errors.currentpassword[0] : null
                || errors.newpassword ? errors.newpassword[0] : null
                  || errors.passwordconfirmation ? errors.passwordconfirmation[0] : null) : null
                  || error.response.data.error || error.response.data.message || 'Geo-location address couldn\'t be added'
          });
        })
        .then(function () {
          _this.change_password_submit = false;
          _this.change_password_text = "Submit";
        });
    },
    run_click() {
      if (this.times == 0) {
        document.getElementById('dropdownNotification').click();
        document.getElementById('dropdownNotification').click();
        this.times += 1;
      }
    },
    run_click2() {
      if (this.times2 == 0) {
        document.getElementById('userDropdown').click();
        document.getElementById('userDropdown').click();
        this.times2 += 1;
      }
    },
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " years"
          : Math.floor(interval) + " year";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " months"
          : Math.floor(interval) + " month";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " days"
          : Math.floor(interval) + " day";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " hours"
          : Math.floor(interval) + " hour";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " minutes"
          : Math.floor(interval) + " minute";
      }
      return Math.floor(seconds) != 1
        ? Math.floor(seconds) + " seconds"
        : Math.floor(seconds) + " second";
    },
    load_announcement(announcement) {
      this.announcement_title = announcement.title;
      this.announcement_body = announcement.description;
      this.announcement_day = announcement.publish_date;
      this.announcement_time = announcement.publish_time;
      this.view_announcement_dialog = true;

      let _this = this;
      axios
        .post(`${BASE_URL}setread`, {
          company_id: _this.authEmployeeDataForCompany.company_id,
          employee_id: _this.authEmployeeDataForCompany.id,
          employeeannouncement_id: announcement.id
        })
        .then(function () {
          _this.fetch_all_company_employee_announcements();
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          //
        });
    },
    logout_request() {
      this.$store.dispatch("logout_request")
      this.apps = [];
    }
  },
  mounted() {
    if (this.isAuth) {

      setTimeout(() => {
        let script_js = document.createElement('script')
        script_js.setAttribute('src', '/assets/js/script.min.js')
        document.body.appendChild(script_js)
      }, 500);

      let bootstrap_bundle_js = document.createElement('script')
      bootstrap_bundle_js.setAttribute('src', '/assets/bootstrap/js/bootstrap.bundle.min.js')
      document.body.appendChild(bootstrap_bundle_js)
    }
    if (!this.authCompanyServices) {
      this.show = false;
    } else {
      if (this.humanResources.length >= 1) {
        let HRSample = {
          icon: "mdi-account-group-outline",
          header: "Human Resources",
          linkName: "HumanResources"
        };
        // if (this.apps.indexOf(HRSample) < 0) {
        //     this.apps.push(HRSample)
        // }
        let countHR = 0;
        this.apps.forEach(app => {
          if (app.header.toLowerCase() == "human resources") {
            countHR++;
          }
        });
        if (countHR == 0) {
          this.apps.push(HRSample);
        }
      }
      if (this.accounts.length >= 1) {
        let ACSample = {
          icon: "mdi-cash",
          header: "Accounts",
          linkName: "Accounts"
        };
        let countAC = 0;
        this.apps.forEach(app => {
          if (app.header.toLowerCase() == "accounts") {
            countAC++;
          }
        });
        if (countAC == 0) {
          this.apps.push(ACSample);
        }
      }
      this.show = true;
      // if (this.humanResources.length >= 1) {
      //         this.apps.push({
      //             icon: 'mdi-account-group-outline',
      //             header: 'Human Resources',
      //             linkName: 'HumanResources'
      //         })
      //     }
      //     if (this.accounts.length >= 1) {
      //         this.apps.push({
      //             icon: 'mdi-cash',
      //             header: 'Accounts',
      //             linkName: 'Accounts'
      //         })
      //     }
    }

    if (this.apps) {
      this.show = true;
    }
  }
  // onActive() {
  //     this.logout_request
  // }
};
</script>
<style>
.v-dialog {
  margin: auto !important;
}

.menu-scrollbar-stuff .ps__rail-y {
  left: 336px !important;
}
</style>
<style lang="scss" scoped>
@media (max-width: 991px) {
  #navbarTogglerDemo01 a img {
    max-width: 30.5% !important;
  }

  ul.navbar-nav li {
    margin: 0px 12px !important;
  }
}

.v-dialog__content {
  display: initial;
  left: 6%;
  top: 7%;
}

.v-dialog {
  margin-left: 10% !important;
}

.v-card__title {
  background-color: #fff i !important;
}

.mdi-all-apps-size {
  font-size: 100px !important;
}

$primary-color: #011534;

// .navbar {
//     padding: 20px 10rem;
// }
.bg-light {
  background-color: #f8f9fa00 !important;
}

ul.navbar-nav li.active {
  border-bottom: 2px solid $primary-color;
}

ul.navbar-nav li a {
  color: #000 !important;
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
}

ul.navbar-nav li a:hover {
  color: $primary-color !important;
  // border-bottom: 2px solid $primary-color;
  border-top: 2px solid #fff;
}

ul.navbar-nav li a.active {
  color: $primary-color !important;
  // border-bottom: 2px solid $primary-color;
  border-top: 2px solid #fff;
}

ul.navbar-nav li {
  margin: 0 20px;
}

ul.navbar-nav li a {
  letter-spacing: -1px;
  font-weight: bold;
}

button.headButton {
  background-color: #fff;
  color: $primary-color;
  border: 1px solid $primary-color;
  width: 211px;
  height: 62px;
  border-radius: 10px;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5314737558364868px;
  text-align: center;
}

button.headButton:hover {
  color: #fff !important;
  background-color: $primary-color !important;
}

.nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.small-link {
  color: $primary-color !important;
}

.menu-list {
  font-family: 'Inter';
}
</style>
