<template>
  <div class="home">
    <div
      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pt-10' : 'pt-sm-4rem'">
      <div
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? '' : 'pt-sm-6rem'"
        style="background-image: url(/img/background-graphics/home-1.png); background-size: contain; background-repeat-x: repeat;">
        <!-- <BigLanding />
        <HomeServices />
        <HomeBottom /> -->

        <!-- <div class="elfsight-app-f0241ddc-8073-46eb-9229-a3feaa0d1c9f"></div> -->

        <!-- START MAIN HOMEPAGE -->
        <v-row>
          <v-col
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'px-5' : 'px-100-left-stuff'"
            class="container-fluid mt-10 mt-md-0" lg="6" cols="12">
            <div
              :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'text-center' : 'text-left'">
              <h2
                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'font-size: 40px; line-height: 50px;' : 'font-size: 54px; line-height: 75px;'"
                style="font-family: Poppins !important; font-weight: 700; color: #000 !important;">
                <span
                  :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'font-size: 50px' : 'font-size: 70px;'"
                  style="color: #0B54C0; font-family: Merriweather !important;">Streamline</span> your business with
               1Howz
              </h2>
              <p class="my-5"
                style="max-width: 600px; font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 24px;">
                Take Control of Your HR Functions with Our Customizable Software
              </p>
              <v-row>
                <v-col cols="12" md="4">
                  <div>
                    <router-link tag="button" to="/request-demo" class="px-5 py-3 rounded w-100"
                      style="background: #0B54C0; border: 2px solid #0B54C0; color: #FFF; font-weight: bold;">
                      <span style="font-family: Poppins !important; font-size: 17px;">
                        Request for Demo
                      </span>
                    </router-link>
                  </div>
                </v-col>
                <!-- <v-col cols="12" md="4">
                  <div>
                    <router-link tag="button" to="/login" class="px-5 py-3 rounded w-100"
                      style="border: 2px solid #2081FF; color: #2081FF; font-weight: bold;">
                      <span style="font-family: Poppins !important; font-size: 17px;">
                        Login
                      </span>
                    </router-link>
                  </div>
                </v-col> -->
              </v-row>
            </div>
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? '' : 'pr-0'"
            lg="6" cols="12">
            <div class="text-center"
              :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'mx-2' : ''">
              <img class="animate__animated animate__shakeY animate__infinite animate__slowest"
                src="/img/Hero_Image.png" alt="">
            </div>
          </v-col>
          <!-- <v-col id="core-features"
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'px-5' : 'px-100-left-stuff px-100-right-stuff'"
            style="background: rgba(188, 226, 250, 0.18);" cols="12" class="container-fluid pt-16 mt-10">
            <v-row>
              <v-col cols="12">
                <h2 class="text-center" style="color: #0A5EB4 !important; font-weight: bold; font-size: 40px;">Core
                  Features</h2>
              </v-col>
              <v-col cols="12" lg="4" md="4" v-for="m in modules" :key="m.id">
                <v-card style="box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);"
                  class="feature-card row mx-2 mb-5 px-4">
                  <v-col style="align-self: center;"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pb-0' : null"
                    class="text-center" md="3" lg="3" cols="12">
                    <div>
                      <i style="color: #0A5EB4 !important;"
                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? m.icon + ' fa-4x' : m.icon + ' fa-4x'"></i>
                    </div>
                  </v-col>
                  <v-col md="9" lg="9" cols="12"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pt-2' : null"
                    class="my-auto" style="padding-top: 15px;">
                    <h3
                      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'text-center' : 'text-left'"
                      style="font-family: 'Poppins' !important; font-size: 20px !important; color: #000 !important;"
                      class="mb-3">{{ m.name }}</h3>
                    <p :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'text-center' : 'text-left'"
                      style="font-size: 15px; font-family: 'Open Sans' !important;">
                      {{ m.description }}
                    </p>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>

          </v-col> -->

          <v-col
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pr-5' : 'px-100-right-stuff'"
            class="d-none d-md-block" lg="6" cols="12">
            <div
              :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'mx-2' : ''">
              <img class="animate__animated animate__shakeY animate__infinite animate__slowest w-100"
                src="/img/core-features.svg" alt="">
            </div>
          </v-col>
          <v-col class="container-fluid" style="align-self: center;" lg="6" cols="12">
            <div>
              <h3 class="text-center text-md-center"
                style="font-family: Poppins !important; font-size: 32px; font-weight: 600; color: #000 !important; max-width: 600px;">
                1Howz HR Features
              </h3>
              <p class="my-5 mx-5 mx-md-0 text-center text-md-center"
                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? '' : 'max-width: 600px;'"
                style="font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 28px;">
                1Howz is an integrated enterprise resource planning (ERP) system
                designed to assist organizations in more effectively managing their
                business processes. The HR module, which includes a variety of
                features to help manage various HR-related tasks, is one of 1Howz's
                key modules. Payroll Management, Performance Management, Recruitment,
                Employee Information System (EIS), Projects, Asset Management, Loan Management, and other
                features are among the key features of the 1Howz HR module.
              </p>
            </div>
          </v-col>

          <v-col style="padding: 80px 0; background: #EDF3FB;" cols="12">
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pl-5' : 'px-100-left-stuff'"
                class="container-fluid" style="align-self: center;" lg="6" cols="12">
                <div>
                  <h3 class="text-center text-md-center"
                    style="font-family: Poppins !important; max-width: 600px; font-size: 32px; font-weight: 600; color: #000 !important;">
                    Payroll Management
                  </h3>
                  <p class="my-5 mx-5 mx-md-0 text-center text-md-center"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? '' : 'max-width: 600px;'"
                    style="font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 28px;">
                    Payroll management is a critical component of the
                    1Howz HR module. It enables you to streamline
                    and automate employee compensation, benefits, and
                    deductions. You can automate the process of
                    calculating salaries, bonuses, and other benefits
                    with 1Howz. You can also ensure compliance
                    with payroll-related tax laws and regulations.
                  </p>
                </div>
              </v-col>
              <v-col style="background: #EDF3FB;"
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? '' : 'px-100-right-stuff'"
                lg="6" cols="12">
                <div
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'mx-4' : ''">
                  <img class="animate__animated animate__shakeY animate__infinite animate__slowest w-100"
                    src="/img/payroll-dashboard.svg" alt="">
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col style="padding: 80px 0;" cols="12">
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? '' : 'px-100-left-stuff'"
                class="text-center" lg="6" cols="12">
                <div class="mr-md-10"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'mx-4' : ''">
                  <img class="animate__animated animate__shakeY animate__infinite animate__slowest w-100"
                    src="/img/ipad.svg" alt="">
                </div>
              </v-col>
              <v-col class="container-fluid" style="align-self: center;" lg="6" cols="12">
                <div>
                  <h3 class="text-center text-md-center"
                    style="font-family: Poppins !important; max-width: 600px; font-size: 32px; font-weight: 600; color: #000 !important;">
                    Performance Management
                  </h3>
                  <p class="my-5 mx-5 mx-md-0 text-center text-md-center"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? '' : 'max-width: 600px;'"
                    style="font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 28px;">
                    The 1Howz HR module includes a comprehensive performance
                    management feature. It enables you to set performance goals,
                    provide feedback, and develop performance improvement plans.
                    You can automate the process of tracking employee performance
                    and providing real-time feedback to your employees. This can
                    assist you in identifying areas for improvement and rewarding
                    your top performers.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col style="padding: 80px 0; background: #011534; color: #FFFFFF;" cols="12">
            <v-row class="mx-4 mx-md-0">
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pl-5' : 'px-100-left-stuff'"
                class="container-fluid" lg="4" cols="12">
                <div>
                  <img class="w-100 mt-1" src="/img/arrow-right.svg" alt="">
                </div>
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pr-5' : 'px-100-right-stuff'"
                lg="4" cols="12">
                <div style="color: #FFF !important;" class="pl-sm-10">
                  <h3 class="text-center text-md-left"
                    style="font-family: Poppins !important; font-size: 32px; font-weight: 600; color: #FFFFFF !important;">
                    Cost containment
                  </h3>
                  <p class="my-4 my-md-8 text-center text-md-left"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? '' : 'max-width: 600px;'"
                    style="font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 28px;">
                    1Howz ERP aims at maintaining and improving the
                    quality of products and services at very affordable costs.
                  </p>
                </div>
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pr-5' : 'px-100-right-stuff'"
                lg="4" cols="12">
                <div style="color: #FFF !important;" class="pl-sm-10">
                  <h3 class="text-center text-md-left"
                    style="font-family: Poppins !important; font-size: 32px; font-weight: 600; color: #FFFFFF !important;">
                    Easy to use
                  </h3>
                  <p class="my-4 my-md-8 text-center text-md-left"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? '' : 'max-width: 600px;'"
                    style="font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 28px;">
                    Users can easily navigate through 1Howz HR features and functions without encountering any
                    significant difficulties or confusion.
                    The interface was designed with you in mind.
                  </p>
                </div>
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pl-5' : 'px-100-left-stuff'"
                class="container-fluid" lg="4" cols="12">
                <div>
                  <h2 class="text-center text-md-left"
                    style="font-family: Poppins !important; font-size: 50px; line-height: 75px; font-weight: 500; color: #FFF !important;">
                    Why 1Howz ERP?
                  </h2>
                </div>
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pr-5' : 'px-100-right-stuff'"
                lg="4" cols="12">
                <div style="color: #FFF !important;" class="pl-sm-10">
                  <h3 class="text-center text-md-left"
                    style="color: #FFF !important; font-family: Poppins !important; font-size: 32px; font-weight: 600;">
                    Centralization of employee data
                  </h3>
                  <p class="my-2 text-center text-md-left"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? '' : 'max-width: 600px;'"
                    style="font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 28px;">
                    1Howz ERP allows you to store all employee data in
                    one central location, making it easier to access and
                    manage employee information such as contact details,
                    employment history, performance reviews, and benefits.
                  </p>
                </div>
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'pr-5' : 'px-100-right-stuff'"
                lg="4" cols="12">
                <div style="color: #FFF !important;" class="pl-sm-10">
                  <h3 class="text-center text-md-left"
                    style="color: #FFF !important; font-family: Poppins !important; font-size: 32px; font-weight: 600;">
                    Quality support
                  </h3>
                  <p class="my-2 text-center text-md-left"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? '' : 'max-width: 600px;'"
                    style="font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 28px;">
                    We are ready to handhold you till you become an
                    expert in the utilization of 1Howz ERP.
                  </p>
                </div>

                <div style="color: #FFF !important; padding-top: 50px;" class="pl-sm-10">
                  <h3 class="text-center text-md-left"
                    style="color: #FFF !important; font-family: Poppins !important; font-size: 32px; font-weight: 600;">
                    Best standards
                  </h3>
                  <p class="my-2 text-center text-md-left"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? '' : 'max-width: 600px;'"
                    style="font-family: Poppins !important; font-size: 16px; font-weight: 400; line-height: 28px;">
                    1Howz ERP was built with great assistance from
                    subject matter experts in the different modules.
                    Be rest assured that you are in good hands.
                  </p>
                </div>

                <svg width="122" height="207" viewBox="0 0 122 207" fill="none" xmlns="http://www.w3.org/2000/svg"
                  :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'position: absolute; right: -100px; top: 120px;' : 'position: absolute; right: -40px; top: 120px;'">
                  <path d="M253.285 4.48141L161.201 204.029L6.92321 47.5129L253.285 4.48141Z" stroke="white"
                    stroke-width="6"></path>
                </svg>
              </v-col>
            </v-row>
          </v-col>

          <v-col style="background: #F8FAFC;"
            :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'padding: 35px 20px;' : 'padding: 70px 0;'"
            cols="12">
            <v-row>
              <v-col cols="12"
                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'padding: 30px 10px;' : 'padding: 50px 0;'">
                <h2 class="text-center"
                  style="font-family: Poppins !important; font-size: 38px; line-height: 57px; font-weight: 700; color: #000 !important;">
                  What People Are Saying About Us
                </h2>
              </v-col>
              <v-col cols="12"
                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? '' : 'padding: 12px 100px !important;'">
                <!-- <v-row>
                  <v-col
                    class="text-center"
                    md="4" cols="12">
                    <div class="card" style="background: #FFFFFF; box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.07); border-radius: 20px;">
                      <div class="card-body px-10">
                        <div class="mb-10 text-left">
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                        </div>
                        <div class="mb-10 text-left">
                          <span style="font-size: 16px;">
                            I love the payroll module. It is very accurate 
                            and easy to use.
                          </span>
                        </div>
                        <v-row>
                          <v-col cols="4" md="2">
                            <img src="/img/reviewer-1.svg" class="w-100" />
                          </v-col>
                          <v-col cols="8" md="10" style="align-self: center;">
                            <div class="text-left">
                              <span style="font-family: Inter; font-size: 16px; font-weight: 700; color: #000 !important;">MD</span>
                            </div>
                            <div class="text-left">
                              <span style="font-family: Inter; font-size: 14px; font-weight: 400; color: #000 !important; opacity: 0.7;">Robinson International Energy Limited</span>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    class="text-center"
                    md="4" cols="12">
                    <div class="card" style="background: #FFFFFF; box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.07); border-radius: 20px;">
                      <div class="card-body px-10">
                        <div class="mb-10 text-left">
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                        </div>
                        <div class="mb-10 text-left">
                          <span style="font-size: 16px;">
                            1Howz helps us manage our employees in our various 
                            locations all over the world seamlessly. I 
                            particularly love the docket, payroll, attendance, 
                            leave and the smart dashboard that tells you who is 
                            celebrating birthdays.
                          </span>
                        </div>
                        <v-row>
                          <v-col cols="4" md="2">
                            <img src="/img/reviewer-2.svg" class="w-100" />
                          </v-col>
                          <v-col cols="8" md="10" style="align-self: center;">
                            <div class="text-left">
                              <span style="font-family: Inter; font-size: 16px; font-weight: 700; color: #000 !important;">Madu Gadzama, Partner</span>
                            </div>
                            <div class="text-left">
                              <span style="font-family: Inter; font-size: 14px; font-weight: 400; color: #000 !important; opacity: 0.7;">J-K Gadzama LLP</span>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    class="text-center"
                    md="4" cols="12">
                    <div class="card" style="background: #FFFFFF; box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.07); border-radius: 20px;">
                      <div class="card-body px-10">
                        <div class="mb-10 text-left">
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                          <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                        </div>
                        <div class="mb-10 text-left">
                          <span style="font-size: 16px;">
                            1Howz ERP has helped me manage my employees in 
                            our different locations with the geolocation 
                            attendance technology. Our attendance record is 
                            now authentic.
                          </span>
                        </div>
                        <v-row>
                          <v-col cols="4" md="2">
                            <img src="/img/reviewer-3.svg" class="w-100" />
                          </v-col>
                          <v-col cols="8" md="10" style="align-self: center;">
                            <div class="text-left">
                              <span style="font-family: Inter; font-size: 16px; font-weight: 700; color: #000 !important;">MD</span>
                            </div>
                            <div class="text-left">
                              <span style="font-family: Inter; font-size: 14px; font-weight: 400; color: #000 !important; opacity: 0.7;">Cypress Eye Centre</span>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col md="7">
                    <iframe height="500" src="https://www.youtube.com/embed/8zDjlTfv0_4" title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen class="w-100"></iframe>
                  </v-col>
                  <v-col md="5">
                    <v-carousel class="home-carousel" :show-arrows="false" color="#FF0000" cycle
                      :hide-delimiter-background="false">
                      <v-carousel-item v-for="(review, index) in reviews" :key='index' cover>
                        <div class="card"
                          style="background: #FFFFFF; border-radius: 20px; box-shadow: none; height: 80%;">
                          <div class="card-body px-10">
                            <div class="mb-10 text-left">
                              <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                              <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                              <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                              <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                              <i class="fas fa-star-sharp mr-2" style="font-size: 16px; color: #FE470E;"></i>
                            </div>
                            <div class="mb-10 text-left">
                              <span style="font-size: 16px;">
                                {{ review.review }}
                              </span>
                            </div>
                            <v-row>
                              <v-col cols="3">
                                <img :src="review.imagePath" class="w-100" style="border-radius: 100px;" />
                              </v-col>
                              <v-col cols="9" style="align-self: center;">
                                <div class="text-left">
                                  <span
                                    style="font-family: Inter; font-size: 16px; font-weight: 700; color: #000 !important;">{{
        review.name }}</span>
                                </div>
                                <div class="text-left">
                                  <span
                                    style="font-family: Inter; font-size: 14px; font-weight: 400; color: #000 !important; opacity: 0.7;">{{
        review.company }}</span>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'px-5' : 'px-100-left-stuff px-100-right-stuff'"
            style="background: #FFFFFF; padding-bottom: 50px;" class="container-fluid py-8">
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'text-center' : 'text-left'"
                style="padding-bottom: 50px;"
                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'padding-bottom: 30px;' : 'padding-bottom: 50px;'"
                cols="12">
                <h2 class="text-center"
                  style="font-family: Poppins !important; font-size: 38px; line-height: 57px; font-weight: 700; color: #000 !important;">
                  Our Trusted Clients
                </h2>
              </v-col>
              <v-col class="text-center" cols="12" lg="2" md="2">
                <img src="/img/ROBINSON.png" alt="">
              </v-col>
              <v-col class="text-center" cols="12" lg="2" md="2">
                <img src="/img/JK-GADZAMA.jpg" alt="">
              </v-col>
              <v-col class="text-center" cols="12" lg="2" md="2">
                <img src="/img/NULAI.jpg" alt="">
              </v-col>
              <v-col class="text-center" cols="12" lg="2" md="2">
                <img src="/img/BARIANSYSTEMS.png" alt="">
              </v-col>
              <v-col class="text-center" cols="12" lg="2" md="2">
                <img src="/img/CYPRESS.png" alt="">
              </v-col>
              <v-col class="text-center" cols="12" lg="2" md="2">
                <img src="/img/TRUE-LANDLORD.png" alt="">
              </v-col>
            </v-row>
          </v-col>

          <GuestNewsletter />

          <!-- <v-col class="px-5" cols="12">
            <v-row>
              <v-col cols="12" lg="5" md="5">
                <img src="/img/home-image-2.png" alt="">
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'px-5' : 'px-100-right-stuff'"
                style="background: #EFF2F7 !important;" cols="12" lg="7" md="7">
                <div
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'text-center' : 'text-left'"
                  class="mt-10 mx-10">
                  <h2 class="mb-5" style="color: #0A5EB4 !important; font-weight: bold; font-size: 33px;">No blindspots on
                    your payroll ever again</h2>
                  <p style="font-size: 18px; font-familty: 'Open Sans' !important;">
                    Take advantage of our services/modules listed above and power your organization's HR needs.
                  </p>
                  <p style="font-size: 18px; font-familty: 'Open Sans' !important;">
                    <router-link to="/" style="font-weight: bold;">
                      Learn More
                    </router-link>
                  </p>
                </div>
              </v-col>

              <v-col cols="12" class="text-center">
                <iframe
                  :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'width: 100%; height: 200px;' : 'width: 70%; height: 600px;'"
                  src="https://www.youtube.com/embed/8zDjlTfv0_4">
                </iframe>
              </v-col>
            </v-row>
          </v-col> -->
          <!-- <v-col cols="12"
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'px-5' : 'px-100-left-stuff px-100-right-stuff'"
            style="background: #323232;" class="container-fluid py-8">
            <v-row>
              <v-col cols="12">
                <h2 class="text-center" style="font-weight: bold; font-size: 39px; color: #fff !important;">Meet the needs
                  of your employee and your business with <br />our global ERP system</h2>
              </v-col>
              <v-col cols="12">
                <div class="text-center">
                  <router-link tag="button" to="/register" class="px-5 py-3 rounded"
                    style="background: #2081FF; border: 2px solid #2081FF; color: #FFF; font-weight: bold;">
                    <span style="font-family: Poppins !important; font-size: 17px;">
                      Get Started
                    </span>
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col> -->
          <GuestFooter />
        </v-row>
        <!-- END MAIN HOMEPAGE -->

        <!-- <keep-alive>
          <BaseFooter />
        </keep-alive> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import BigLanding from "@/components/BigLanding";
// import HomeServices from "@/components/HomeServices";
// import HomeBottom from "@/components/HomeBottom";
import GuestFooter from "@/components/GuestFooter";
import GuestNewsletter from "@/components/GuestNewsletter";
import { mapActions } from 'vuex';
export default {
  name: "Home",
  data() {
    return {
      reviews: [
        {
          review: 'I love the payroll module. It is very accurate and easy to use.',
          imagePath: '/img/reviewer-robinson.jpeg',
          name: 'Vincent Iwueze, MD',
          company: 'Robinson International Energy Limited'
        },
        {
          review: '1Howz helps us manage our employees in our various locations all over the world seamlessly. I particularly love the docket, payroll, attendance, leave and the smart dashboard that tells you who is celebrating birthdays.',
          imagePath: '/img/reviewer-gadzama.jpeg',
          name: 'Madu Gadzama, Partner',
          company: 'J-K Gadzama LLP'
        },
        {
          review: '1Howz ERP has helped me manage my employees in our different locations with the geolocation attendance technology. Our attendance record is now authentic.',
          imagePath: '/img/reviewer-cypress.jpeg',
          name: 'Obiageli Oketta, MD',
          company: 'Cypress Eye Centre'
        }
      ],
      modules: [
        {
          id: 1,
          icon: 'fad fa-money-check-edit',
          name: 'Payroll',
          description: 'Hire anywhere and manage your payroll through one dashboard. Our cutting-edge ERP solution replaces manual processes and eliminates errors.'
        },
        {
          id: 2,
          icon: 'fad fa-clipboard-user',
          name: 'Attendance',
          description: '1Howz provides an accurate and neatly organized attendance tracking system. Employees can punch in/out using their devices on a dedicated IP address.'
        },
        {
          id: 3,
          icon: 'fad fa-tasks',
          name: 'Projects',
          description: '1Howz keeps your organization\'s projects on time and on budget, keeps your employees focused and keeps accelerating your project management success no matter what the environment.'
        },
        {
          id: 4,
          icon: 'fad fa-computer-speaker',
          name: 'Assets',
          description: 'The asset management is an add-on module in 1Howz that manages the asset of the business. The module allows users to record all the details of assets based on their location, tags and vendors.'
        },
        {
          id: 5,
          icon: 'fad fa-handshake',
          name: 'Recruitment',
          description: 'In today\'s marketplace, Many companies are facing serious challenges in finding the right talent. 1Howz helps match great talent to great opportunity'
        },
        {
          id: 6,
          icon: 'fad fa-notes-medical',
          name: 'Medicals',
          description: 'Companies that do not have partnership with health insurance, can use the this module to allocate health allowance, present evidence of treatment and payment.'
        },
        {
          id: 7,
          icon: 'fad fa-plane-departure',
          name: 'Travel management',
          description: 'This module is specifically designed to present to employers the flexibility in managing staff travel allowances, expenses, reimbursement, calculations and deductions.'
        },
        {
          id: 8,
          icon: 'fad fa-island-tropical',
          name: 'Leave management',
          description: 'Evaluate your employees\' requests for leave with 1Howz and manage the entire tracking and compliance process, from initial request to the return to work.'
        },
        {
          id: 9,
          icon: 'fad fa-file-lines',
          name: 'Timesheet management',
          description: 'Makes it simple and easy for employees to keep record of their daily tasks, employer can track employee\'s productivity and manage resources.'
        },
        {
          id: 10,
          icon: 'fad fa-file-spreadsheet',
          name: 'Tasksheet management',
          description: 'Track and manage all activities that employees are engaged in, their pay-rates per minutes worked, and promptness & efficiency of work done.'
        },
        {
          id: 11,
          icon: 'fad fa-money-check',
          name: 'Requisitions',
          description: 'Manage all financial requisitions and reimbursement here. This service takes out the hassle of manually organizing monetary claims and reimbursables.'
        },
        {
          id: 12,
          icon: 'fad fa-scale-balanced',
          name: 'Dockets',
          description: 'With this service, organizations can schedule and manage court cases. This way, employees are aware of what engagements they are assigned to.'
        },
        {
          id: 13,
          icon: 'fad fa-coins',
          name: 'Loan',
          description: 'Take advantage of our Loan management system to ease the process/procedure tied with loan applications and approvals. All application information can be exported in excel and PDF formats for record-keeping purposes.'
        }
      ]
    }
  },
  components: {
    GuestFooter,
    GuestNewsletter
  },
  methods: {
    ...mapActions([
      'fetch_available_services',
      'fetch_company_blog_posts'
    ])
  },
  mounted() {
    localStorage.clear();
    let _this = this;
    document.title = "1Howz ERP";
    _this.fetch_company_blog_posts();
    if (_this.$route.query.goTo == 'coreFeatures') {
      setTimeout(() => {
        document.getElementById('goToCoreFeatures').click();
      }, 250);
    }
    _this.fetch_available_services();
  }
};
</script>
<style lang="scss">
.home-page-subscriber .v-text-field--outlined .v-label,
.home-page-subscriber .theme--light.v-input input,
.theme--light.v-input textarea {
  color: #FFFFFF !important;
}

.home-page-subscriber .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
  color: #FFFFFF !important;
}

.home-carousel {
  .theme--dark.v-btn.v-btn--icon {
    color: #D9D9D9;
  }

  .v-item--active {
    color: #0b54c0 !important;
  }

  .v-carousel__controls {
    background: none;
  }
}
</style>
