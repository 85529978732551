<template>
    <div>
        <!-- <SideMenu /> -->
        <div v-if="userType == 1 || permissionIsAssigned('payroll-read')"
            class="main-content-wrap sidenav-open d-flex flex-column">
            <div class="main-header pl-4" style="z-index: 60;">
                <div class="row">
                    <div style="align-self: center;" class="logo col-md-2 col-lg-2 col-12 py-0">
                        <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
                            <i @click="
                                payrunView == 3
                                    ? (payrunView = 1)
                                    : hasHistory()
                                        ? $router.go(-1)
                                        : $router.push('/')
                                " style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"></i>
                            <span class="ml-2" style="top: 4%; position: relative;">Back</span>
                        </div>
                    </div>
                    <!-- FOR OTHER ROLES ASSIGNED THE PERMISSION OF READING PAYROLL -->
                </div>
                <div style="margin: auto;"></div>
                <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                    ? null
                    : 'pr-5'
                    ">
                    <!-- Notificaiton -->
                    <div class="dropdown animate__animated animate__fadeIn">
                        <button v-if="payrunView != 2" @click="viewAllPayruns()" style="float: right;" type="button"
                            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                            View Payrun
                        </button>
                        <template v-if="payrunView !== 6">
                            <button v-if="payrunView !== 1 && payrunView !== 3" @click="payrunView = 1"
                                style="float: right;" type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                Create Payrun
                            </button>
                            <button @click="viewCompanyTrasactions()" style="float: right;" type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                View Company Transactions
                            </button>
                        </template>
                        <template v-else>
                            <button @click="payrunView = 1" style="float: right;" type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                Create Payrun
                            </button>
                        </template>
                    </div>
                    <!-- Notificaiton End -->
                    <!-- User avatar dropdown -->
                </div>
            </div>
            <!-- ======== Body content start ========= -->
            <div v-if="payrunView == 1" class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? 'px-3'
                : null
                ">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                        ">
                        <h1 class="col-12 my-0 row pb-0">
                            <span class="col-lg-12 col-md-12 col-12">Payrun</span>
                        </h1>
                        <div :class="$vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                            ? 'px-0'
                            : 'px-7'
                            " class="col-12 row pt-0 mt-0">
                            <div v-for="(pc, index) in authPayCalendars" :key="'pay-calendar-' + pc.id"
                                class="col-lg-4 col-md-6 col-sm-6">
                                <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                                    :style="getStyleByPattern(index)">
                                    <div class="card-header">
                                        {{ pc.calendar_name }}
                                    </div>
                                    <div class="row card-body text-center py-1 mt-0">
                                        <p class="col-12 text-left py-1 mb-1"><strong>Calendar Name: </strong>{{
                                            pc.calendar_name }}</p>
                                        <p class="col-12 text-left py-1 mb-1"><strong>Calendar Type: </strong>{{
                                            pc.calendar_type }}</p>
                                        <p class="col-12 text-left py-1 mb-1"><strong>No. of Employees: </strong>{{
                                            employeesInPayCalendar(pc.id).length }}</p>
                                    </div>
                                    <div v-if="permissionIsAssigned('payroll-create')"
                                        class="card-footer py-1 text-right row">
                                        <div class="col-lg-5 row py-0"></div>
                                        <div class="col-lg-6 offset-lg-1 py-0 pb-3 px-0">
                                            <button @click="startPayrun(pc)" type="button"
                                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                                Start Payrun
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <div v-else-if="payrunView == 2" class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? 'px-3'
                : null
                ">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                        ">
                        <h1 class="col-12 my-0 row pb-0">
                            <span class="col-lg-12 col-md-12 col-12">Payrun</span>
                        </h1>
                        <div class="col-12" :class="$vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                            ? 'px-0'
                            : 'pr-7'
                            ">
                            <div class="card" style="border-radius: 10px;">
                                <div class="card-body px-1 py-1 pt-0 pr-0">
                                    <div class="table-responsive"
                                        style="border-radius: 10px; height: 70vh; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                                        <download-excel style="position: fixed; top: 0px;" id="export-button"
                                            :data="json_data" :fields="json_fields" :worksheet="excelSheetName"
                                            :name="excelSheetName + '.xls'"
                                            class="btn btn-raised ripple btn-raised-secondary btn-sm m-1 text-white" :class="$vuetify.breakpoint.name == 'xs' ||
                                                $vuetify.breakpoint.name == 'sm'
                                                ? 'w-100'
                                                : null
                                                ">
                                            Export to Excel
                                        </download-excel>
                                        <table class="table table-hover">
                                            <thead>
                                                <tr style="border: 2px solid #4440;">
                                                    <th scope="col" class="text-left">Date Created</th>
                                                    <th scope="col" class="text-left">Name</th>
                                                    <th scope="col" class="text-left">From</th>
                                                    <th scope="col" class="text-left">To</th>
                                                    <th scope="col" class="text-left">Payment Date</th>
                                                    <th scope="col" class="text-center">Status</th>
                                                    <th v-if="permissionIsAssigned('payroll-approve')" scope="col"
                                                        class="text-center">
                                                        Salaries Payment
                                                    </th>
                                                    <th v-if="permissionIsAssigned('payroll-read') || permissionIsAssigned('payroll-update') ||
                                                        permissionIsAssigned('payroll-delete')
                                                        " scope="col" class="text-left">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="p in authCompanyPayRun" :key="'pay-run-' + p.id">
                                                    <td class="text-left">
                                                        {{ new Date(p.created_at).toUTCString().substring(0, 16) }}
                                                    </td>
                                                    <td class="text-left">{{ p.payrun_name }}</td>
                                                    <td class="text-left">
                                                        {{ new Date(p.from_date).toUTCString().substring(0, 16) }}
                                                    </td>
                                                    <td class="text-left">
                                                        {{ new Date(p.to_date).toUTCString().substring(0, 16) }}
                                                    </td>
                                                    <td class="text-left">
                                                        {{ new Date(p.payment_date).toUTCString().substring(0, 16) }}
                                                    </td>
                                                    <td v-if="p.approved == 0">
                                                        <span class="badge badge-light text-center">UNAPPROVED</span>
                                                    </td>
                                                    <td v-else>
                                                        <span class="badge badge-success text-center">APPROVED</span>
                                                    </td>
                                                    <td v-if="permissionIsAssigned('payroll-approve')">
                                                        <i v-if="p.approved == 0">Payrun unapproved</i>
                                                        <button v-else @click="launchSalaryPayment(p)" type="button"
                                                            class="btn btn-sm btn-secondary text-white btn-raised ripple"
                                                            :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null">
                                                            Process Payment
                                                        </button>
                                                    </td>
                                                    <td v-if="permissionIsAssigned('payroll-read') || permissionIsAssigned('payroll-update') ||
                                                        permissionIsAssigned('payroll-delete')
                                                        " style="padding-top: 25px;"
                                                        class="text-left employee-options">
                                                        <div class="ul-widget4__actions">
                                                    <button class="btn _r_btn border-0" type="button"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <span class="_dot _inline-dot bg-primary"></span>
                                                        <span class="_dot _inline-dot bg-primary"></span>
                                                        <span class="_dot _inline-dot bg-primary"></span>
                                                    </button>
                                                    <div class="dropdown-menu" x-placement="top-start"
                                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                                        <i v-if="permissionIsAssigned('payroll-read') &&
                                                                p.approved != 0
                                                                " @click="exportToExcel(p)"
                                                                class="col-2 py-0 text-center fal fa-file-spreadsheet"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                        <a class="dropdown-item" style="cursor: pointer;"
                                                        @click="triggerPayrunSummaryAltPrint(p)">
                                                            <i v-if="permissionIsAssigned('payroll-read') &&
                                                                p.approved != 0
                                                                " @click="triggerPayrunSummaryAltPrint(p)"
                                                                class="col-2 py-0 text-center fal fa-print"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>Print
                                                        </a>
                                                        <template>
                                                                <i v-if="permissionIsAssigned('payroll-update') &&
                                                                    p.approved == 0
                                                                    " @click="editPayRun(p)"
                                                                    class="col-2 py-0 text-center fal fa-edit"
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                                <i v-if="permissionIsAssigned('payroll-approve') &&
                                                                    p.approved != 0
                                                                    " @click="viewPayrunInfo(p)"
                                                                    class="col-2 py-0 text-center fal fa-eye"
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            </template>
                                                            <template v-if="permissionIsAssigned('payroll-delete')">
                                                                <i @click="deletePayRun(p)"
                                                                    class="col-2 py-0 text-center fal fa-trash"
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            </template>
                                                    </div>
                      </div>
                                                        <!-- <div class="row">
                                                            <i v-if="permissionIsAssigned('payroll-read') &&
                                                                p.approved != 0
                                                                " @click="exportToExcel(p)"
                                                                class="col-2 py-0 text-center fal fa-file-spreadsheet"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            <i v-if="permissionIsAssigned('payroll-read') &&
                                                                p.approved != 0
                                                                " @click="triggerPayrunSummaryAltPrint(p)"
                                                                class="col-2 py-0 text-center fal fa-print"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            <template>
                                                                <i v-if="permissionIsAssigned('payroll-update') &&
                                                                    p.approved == 0
                                                                    " @click="editPayRun(p)"
                                                                    class="col-2 py-0 text-center fal fa-edit"
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                                <i v-if="permissionIsAssigned('payroll-approve') &&
                                                                    p.approved != 0
                                                                    " @click="viewPayrunInfo(p)"
                                                                    class="col-2 py-0 text-center fal fa-eye"
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            </template>
                                                            <template v-if="permissionIsAssigned('payroll-delete')">
                                                                <i @click="deletePayRun(p)"
                                                                    class="col-2 py-0 text-center fal fa-trash"
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            </template>
                                                        </div> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <div v-else-if="payrunView == 3" class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? 'px-3'
                : null
                ">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                        ">
                        <h1 class="col-12 my-0 row pb-0">
                            <span class="col-6">Payrun</span>
                            <span class="col-6 text-right">
                                <span v-if="currentPayRun ? currentPayRun.approved == 1 : null"
                                    class="badge badge-success">APPROVED</span>
                                <span v-else class="badge badge-light">UNAPPROVED</span>
                            </span>
                        </h1>
                        <div class="col-12" :class="$vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                            ? 'px-0'
                            : 'px-7'
                            ">
                            <v-stepper v-model="steps" non-linear>
                                <v-stepper-header>
                                    <v-stepper-step :editable="false" step="1">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ?
                                            "Employees" : null }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :editable="false" step="2">
                                        {{
                                            $vuetify.breakpoint.name != "xs"
                                            &&
                                            $vuetify.breakpoint.name != "sm"
                                            ?
                                            "Variable Input"
                                            : null
                                        }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :editable="false" step="3">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ? "PaySlips"
                                            : null }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :editable="false" step="4">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ? "Approval"
                                            : null }}
                                    </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="1">
                                        <div class="container-fluid">
                                            <v-form ref='form1'>
                                                <v-row>
                                                    <v-col cols="12" sm="3" md="3">
                                                        <span style="color: #111d5e;">From Date <i class="fas fa-asterisk"
                                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field color="#069"
                                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                                    v-model="payrunForm.from_date" placeholder="From date" solo
                                                                    class="mt-2" dense readonly v-bind="attrs"
                                                                    v-on="on" :rules="[
                                        v => !!v || 'Date is required'
                                    ]"></v-text-field>
                                                            </template>
                                                            <v-date-picker color="#069" ref="picker"
                                                                v-model="payrunForm.from_date" @change="save" :min="new Date(new Date().getTime() - 1.581e10)
                                                                    .toISOString()
                                                                    .substring(0, 10)
                                                                    " :max="new Date(new Date().getTime() + 2.678e9)
            .toISOString()
            .substring(0, 10)
            "></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="3" md="3">
                                                        <span style="color: #111d5e;">To Date <i class="fas fa-asterisk"
                                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field color="#069"
                                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                                    v-model="payrunForm.to_date" placeholder="To date" solo
                                                                    class="mt-2" dense readonly v-bind="attrs" v-on="on" :rules="[
                                        v => !!v || 'Date is required'
                                    ]">
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker color="#069" ref="picker2"
                                                                v-model="payrunForm.to_date" @change="save2" :min="new Date(new Date().getTime() - 1.581e10)
                                                                    .toISOString()
                                                                    .substring(0, 10)
                                                                    " :max="new Date(new Date().getTime() + 2.678e9)
            .toISOString()
            .substring(0, 10)
            "></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="3" md="3">
                                                        <span style="color: #111d5e;">Payment Date <i class="fas fa-asterisk"
                                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                        <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field color="#069"
                                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                                    v-model="payrunForm.payment_date" placeholder="Payment date"
                                                                    solo class="mt-2" dense readonly v-bind="attrs"
                                                                    v-on="on" :rules="[
                                        v => !!v || 'Date is required'
                                    ]"></v-text-field>
                                                            </template>
                                                            <v-date-picker color="#069" ref="picker4"
                                                                v-model="payrunForm.payment_date" @change="save4" :min="new Date(new Date().getTime() - 1.581e10)
                                                                    .toISOString()
                                                                    .substring(0, 10)
                                                                    " :max="new Date(new Date().getTime() + 2.678e9)
            .toISOString()
            .substring(0, 10)
            "></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" class="card my-3" style="border-radius: 10px;">
                                                        <div class="card-header px-3">
                                                            <h4>Active Employees</h4>
                                                        </div>
                                                        <div class="card-body px-1 py-1 pt-0 pr-0">
                                                            <div class="table-responsive" :style="$vuetify.breakpoint.name == 'xs' ||
                                                                $vuetify.breakpoint.name == 'sm'
                                                                ? 'height: 30vh;'
                                                                : 'height: 30vh;'
                                                                "
                                                                style="border-radius: 10px; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Employee
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Department
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Pay Basic
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Allowances
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Statutory Deductions
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Non-Statutory Deductions
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Net Pay
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="e in employeesInPayCalendar(
                                                                            currentPayCalendar
                                                                                ? currentPayCalendar.id
                                                                                : null
                                                                        )" :key="'employee-in-pay-calendar-' + e.id">
                                                                            <!-- Employee -->
                                                                            <td class="text-left">
                                                                                {{ e.employee_fristname + " " +
                                                                                    e.employee_lastname }}
                                                                            </td>
                                                                            <!-- Department -->
                                                                            <td class="text-left">
                                                                                {{ e.department_name }}
                                                                            </td>
                                                                            <!-- Pay Basic -->
                                                                            <td class="text-left">
                                                                                ₦{{ ((currentGradeLevel(e.employee_id) ?
                                                                                    currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                    0) | number_with_commas }}
                                                                            </td>
                                                                            <!-- Allowances -->
                                                                            <td class="text-left">
                                                                                ₦{{ totalEmployeesFlexibleAllowances(
                                                                                    e.employee_id) | number_with_commas }}
                                                                            </td>
                                                                            <!-- Statutory Deductions -->

                                                                            <!-- Statutory Deductions -->
                                                                            <td class="text-left">
                                                                                ₦{{ (getPensionDeduction(
                                                                                    (currentGradeLevel(e.employee_id) ?
                                                                                        currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                    0) + getNHFDeduction(
                                                                                        (currentGradeLevel(e.employee_id) ?
                                                                                            currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                        0) + getNHISDeduction(
                                                                                            (currentGradeLevel(e.employee_id) ?
                                                                                                currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                            0) + getNSITFDeduction(
                                                                                                (currentGradeLevel(e.employee_id) ?
                                                                                                    currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                                0) +
                                                                                    getPAYEDeduction(
                                                                                        (currentGradeLevel(e.employee_id) ?
                                                                                            currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                        0, 148)) | number_with_commas }}
                                                                            </td>

                                                                            <!-- Non-Statutory Deductions -->
                                                                            <td class="text-left">
                                                                                ₦{{ totalEmployeesFlexibleDeductions(
                                                                                    e.employee_id) | number_with_commas }}
                                                                            </td>

                                                                            <!-- Net Pay -->
                                                                            <td class="text-left">
                                                                                ₦{{ (taxEmployeeNetPay(
                                                                                    (currentGradeLevel(e.employee_id) ?
                                                                                        currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                    0, e.employee_id) +
                                                                                    totalEmployeesFlexibleAllowances(
                                                                                        e.employee_id) -
                                                                                    totalEmployeesFlexibleDeductions(e.employee_id
                                                                                    )) | number_with_commas }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="12" sm="3" lg="1" md="3" xl="1" offset-lg="11"
                                                                offset-md="9" offset-xl="11" class="pt-0">
                                                                <button v-if="employeesInPayCalendar(
                                                                    currentPayCalendar
                                                                        ? currentPayCalendar.id
                                                                        : null
                                                                ).length >= 1
                                                                    " @click="add_payrun_submit ? null : add_payrun()"
                                                                    style="width: 100%;" type="button"
                                                                    class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                    {{ add_payrun_text }}
                                                                    <v-icon :style="!add_payrun_submit
                                                                        ? 'display: none;'
                                                                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                                        ">
                                                                        fal fa-circle-notch fa-spin
                                                                    </v-icon>
                                                                </button>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </div>
                                    </v-stepper-content>

                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="2">
                                        <div class="container-fluid pb-4">
                                            <v-row>
                                                <v-col cols="12" lg="5" xl="5" class="m0-3" style="border-radius: 10px;">
                                                    <div class="card">
                                                        <div class="card-header px-3">
                                                            <h4>Employees profile information</h4>
                                                        </div>
                                                        <div class="card-body px-3 py-5">
                                                            <v-row>
                                                                <v-col cols="12" class="pb-0">
                                                                    <v-select class="remove-mb"
                                                                        :items="employeesInPayCalendarNames"
                                                                        placeholder="Employee" dense solo
                                                                        hint="*Select Employee" :full-width="true"
                                                                        v-model="employee_name"></v-select>
                                                                </v-col>
                                                                <v-col cols="12" class="pt-2">
                                                                    <v-row>
                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5 style="font-weight: bold;">
                                                                                Emoluments
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                        </v-col>

                                                                        <template
                                                                            v-for="emolument in authCompanyEmoluments">
                                                                            <v-col :key="'emolument-' + emolument.id"
                                                                                cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                                <h5>
                                                                                    {{ emolument.emolument_name }}
                                                                                </h5>
                                                                                <span>
                                                                                    {{
                                                                                        emolument.emolument_percentage
                                                                                        +
                                                                                        "% of salary"
                                                                                    }}
                                                                                </span>
                                                                            </v-col>
                                                                            <v-col :key="'emolument-1-' + emolument.id"
                                                                                cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                                <h5>
                                                                                    ₦{{ ((parseFloat(
                                                                                        emolument.emolument_percentage) / 100)
                                                                                        * ((currentGradeLevel(employee_id) ?
                                                                                            currentGradeLevel(employee_id).salary :
                                                                                            0) || 0)) |
                                                                                        number_with_commas }}
                                                                                </h5>
                                                                            </v-col>
                                                                        </template>

                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Gross Pay
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ (currentGradeLevel(employee_id) ?
                                                                                    currentGradeLevel(employee_id).salary : 0)
                                                                                    || 0 | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col v-if="employeesFlexibleAllowances(employee_id)
                                                                            .length
                                                                            " cols="8" lg="9" xl="9" md="9"
                                                                            class="pb-0">
                                                                            <h5 style="font-weight: bold;">
                                                                                Allowances
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col v-if="employeesFlexibleAllowances(employee_id)
                                                                            .length
                                                                            " cols="4" lg="3" xl="3" md="3"
                                                                            class="pb-0">
                                                                        </v-col>

                                                                        <template v-for="fa in employeesFlexibleAllowances(
                                                                            employee_id
                                                                        )">
                                                                            <v-col :key="'flexible-allowance-'
                                                                                +
                                                                                fa.id
                                                                                +
                                                                                fa.allowance_name
                                                                                " cols="8" lg="9" xl="9" md="9"
                                                                                class="pb-0">
                                                                                <h5>
                                                                                    <i class="fas fa-times-circle"
                                                                                        style="cursor: pointer;" @click="
                                                                                            delete_flexible_allowance(fa.id)
                                                                                            "></i>
                                                                                    {{ fa.allowance_name }}
                                                                                </h5>
                                                                                <span v-if="fa.note">{{ fa.note }}</span>
                                                                            </v-col>
                                                                            <v-col :key="'flexible-allowance-' + fa.id"
                                                                                cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                                <h5>
                                                                                    ₦{{ parseFloat(fa.allowance_amount) |
                                                                                        number_with_commas }}
                                                                                </h5>
                                                                            </v-col>
                                                                        </template>

                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Total Allowances
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ taxTotalEmployeeAllowances(employee_id
                                                                                ) | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5 style="font-weight: bold;">
                                                                                Statutory Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                        </v-col>

                                                                        <template
                                                                            v-for="deduction in allStatutoryDeductions">
                                                                            <v-col :key="'statutory-deduction-' + deduction.id
                                                                                " cols="8" lg="9" xl="9" md="9"
                                                                                class="pb-0">
                                                                                <h5>
                                                                                    {{ deduction.name }}
                                                                                </h5>
                                                                                <span>{{ deduction.description }}</span>
                                                                            </v-col>
                                                                            <v-col :key="'statutory-deduction-1-' +
                                                                                deduction.id
                                                                                " cols="4" lg="3" xl="3" md="3"
                                                                                class="pb-0">
                                                                                <h5 v-if="deduction.name.toLowerCase() ==
                                                                                    'pension'
                                                                                    ">
                                                                                    ₦{{ getPensionDeduction(
                                                                                        (currentGradeLevel(employee_id) ?
                                                                                            currentGradeLevel(employee_id).salary :
                                                                                            0) || 0) | number_with_commas }}
                                                                                </h5>
                                                                                <h5 v-if="deduction.name.toLowerCase() ==
                                                                                    'nhf'
                                                                                    ">
                                                                                    ₦{{ getNHFDeduction(
                                                                                        (currentGradeLevel(employee_id) ?
                                                                                            currentGradeLevel(employee_id).salary :
                                                                                            0) || 0) | number_with_commas }}
                                                                                </h5>
                                                                                <h5 v-if="deduction.name.toLowerCase() ==
                                                                                    'nhis'
                                                                                    ">
                                                                                    ₦{{ getNHISDeduction(
                                                                                        (currentGradeLevel(employee_id) ?
                                                                                            currentGradeLevel(employee_id).salary :
                                                                                            0) || 0) | number_with_commas }}
                                                                                </h5>
                                                                                <h5 v-if="deduction.name.toLowerCase() ==
                                                                                    'nsitf'
                                                                                    ">
                                                                                    ₦{{ getNSITFDeduction(
                                                                                        (currentGradeLevel(employee_id) ?
                                                                                            currentGradeLevel(employee_id).salary :
                                                                                            0) || 0) | number_with_commas }}
                                                                                </h5>
                                                                                <h5 v-if="deduction.name.toLowerCase() ==
                                                                                    'paye'
                                                                                    ">
                                                                                    ₦{{ getPAYEDeduction(
                                                                                        (currentGradeLevel(employee_id) ?
                                                                                            currentGradeLevel(employee_id).salary :
                                                                                            0) || 0, employee_id) |
                                                                                        number_with_commas }}
                                                                                </h5>
                                                                            </v-col>
                                                                        </template>

                                                                        <v-col v-if="employeesFlexibleDeductions(
                                                                            employee_id
                                                                        ).length
                                                                            " cols="8" lg="9" xl="9" md="9"
                                                                            class="pb-0">
                                                                            <h5 style="font-weight: bold;">
                                                                                Non-Statutory Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col v-if="employeesFlexibleDeductions(
                                                                            employee_id
                                                                        ).length
                                                                            " cols="4" lg="3" xl="3" md="3"
                                                                            class="pb-0">
                                                                        </v-col>

                                                                        <template v-for="fd in employeesFlexibleDeductions(
                                                                            employee_id
                                                                        )">
                                                                            <v-col :key="'flexible-deduction-' +
                                                                                fd.id +
                                                                                fd.deduction_name
                                                                                " cols="8" lg="9" xl="9" md="9"
                                                                                class="pb-0">
                                                                                <h5>
                                                                                    <i class="fas fa-times-circle"
                                                                                        style="cursor: pointer;" @click="
                                                                                            delete_flexible_deduction(fd.id)
                                                                                            "></i>
                                                                                    {{ fd.deduction_name }}
                                                                                </h5>
                                                                                <span v-if="fd.note">{{ fd.note }}</span>
                                                                            </v-col>
                                                                            <v-col :key="'flexible-deduction-' + fd.id"
                                                                                cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                                <h5>
                                                                                    ₦{{ parseFloat(fd.deduction_amount) |
                                                                                        number_with_commas }}
                                                                                </h5>
                                                                            </v-col>
                                                                        </template>
                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Total Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ taxTotalEmployeeDeductions(
                                                                                    (currentGradeLevel(employee_id) ?
                                                                                        currentGradeLevel(employee_id).salary : 0)
                                                                                    || 0, employee_id) | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Net Pay
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ finalTaxEmployeeNetPay(
                                                                                    (currentGradeLevel(employee_id) ?
                                                                                        currentGradeLevel(employee_id).salary : 0)
                                                                                    || 0, employee_id) | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" lg="7" xl="7" class="mb-3" style="border-radius: 10px;">
                                                    <div class="card pb-8">
                                                        <div class="card-header px-3">
                                                            <h4>
                                                                Additional allowance or deduction for this payrun only
                                                            </h4>
                                                        </div>
                                                        <div class="card-body px-3 py-5">
                                                            <v-row>
                                                                <v-col cols="12" class="py-0">
                                                                    <v-row>
                                                                        <v-col cols="12" class="py-1 mt-2">
                                                                            <h5 class="mb-0 d-inline-block"
                                                                                style="background: #e2e2e2;">
                                                                                Additional Pay
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4" lg="4" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Allowance Name <i
                                                                                    class="fas fa-asterisk"
                                                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                                            <v-select class="remove-mb"
                                                                                :items="companyAllowances" dense solo
                                                                                :full-width="true" v-model="flexibleAllowanceForm.allowance_name
                                                                                    ">
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col cols="12" md="3" lg="3" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Amount <i
                                                                                    class="fas fa-asterisk"
                                                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                                            <v-text-field v-model="flexibleAllowanceForm.allowance_amount
                                                                                " solo dense
                                                                                type="number"></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4" lg="4" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Note</span>
                                                                            <v-text-field
                                                                                v-model="flexibleAllowanceForm.note" solo
                                                                                dense></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="1" lg="1" class="pt-0 pb-2">
                                                                            <span style="opacity: 0;">A</span>
                                                                            <button @click="
                                                                                add_flexible_allowance_submit
                                                                                    ? null
                                                                                    : add_flexible_allowance()
                                                                                " style="width: 100%;" type="button"
                                                                                class="btn btn-secondary px-0 btn-raised ripple">
                                                                                <v-icon
                                                                                    v-if="add_flexible_allowance_submit">
                                                                                    fal fa-circle-notch fa-spin text-white
                                                                                </v-icon>
                                                                                <v-icon v-else>
                                                                                    fal fa-plus text-white
                                                                                </v-icon>
                                                                            </button>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="12" class="py-0">
                                                                    <v-row :class="$vuetify.breakpoint.name == 'xs' ||
                                                                        $vuetify.breakpoint.name == 'sm'
                                                                        ? 'mt-8'
                                                                        : null
                                                                        ">
                                                                        <v-col cols="12" class="py-1 mt-2">
                                                                            <h5 class="mb-0 d-inline-block"
                                                                                style="background: #e2e2e2;">
                                                                                Additional Deduction
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4" lg="4" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Deduction Name <i
                                                                                    class="fas fa-asterisk"
                                                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                                            <v-select class="remove-mb"
                                                                                :items="companyDeductions" dense solo
                                                                                :full-width="true" v-model="flexibleDeductionForm.deduction_name
                                                                                    ">
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col cols="12" md="3" lg="3" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Amount <i
                                                                                    class="fas fa-asterisk"
                                                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                                            <v-text-field v-model="flexibleDeductionForm.deduction_amount
                                                                                " solo dense
                                                                                type="number"></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4" lg="4" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Note</span>
                                                                            <v-text-field
                                                                                v-model="flexibleDeductionForm.note" solo
                                                                                dense></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="1" lg="1" class="pt-0 pb-2">
                                                                            <span style="opacity: 0;">A</span>
                                                                            <button @click="
                                                                                add_flexible_deduction_submit
                                                                                    ? null
                                                                                    : add_flexible_deduction()
                                                                                " style="width: 100%;" type="button"
                                                                                class="btn btn-secondary px-0 btn-raised ripple">
                                                                                <v-icon
                                                                                    v-if="add_flexible_deduction_submit">
                                                                                    fal fa-circle-notch fa-spin text-white
                                                                                </v-icon>
                                                                                <v-icon v-else>
                                                                                    fal fa-plus text-white
                                                                                </v-icon>
                                                                            </button>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>

                                                    <v-row class="mt-5" :class="$vuetify.breakpoint.name == 'xs' ||
                                                        $vuetify.breakpoint.name == 'sm'
                                                        ? 'px-2'
                                                        : null
                                                        ">
                                                        <v-col cols="6" lg="2" xl="2" offset-lg="8" offset-xl="8"
                                                            class="pt-0">
                                                            <button @click="steps -= 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Previous
                                                            </button>
                                                        </v-col>
                                                        <v-col cols="6" lg="2" xl="2" class="pt-0">
                                                            <button @click="steps += 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Next
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>

                                    <v-stepper-content v-if="currentEmployee" :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="3">
                                        <div class="container-fluid">
                                            <v-row>
                                                <v-col cols="12" class="m0-3" style="border-radius: 10px;">
                                                    <div id="printMe" class="card">
                                                        <div class="card-header px-3">
                                                            <h4>Employee Payslip Summary</h4>
                                                        </div>
                                                        <div class="card-body px-3 pt-1 pb-8">
                                                            <div class="noShowOnPrint d-print-none">
                                                                <v-col cols="12" lg="4" md="4" class="pb-0">
                                                                    <v-select class="remove-mb"
                                                                        :items="employeesInPayCalendarNames"
                                                                        placeholder="Employee" dense solo :full-width="true"
                                                                        v-model="employee_name"> </v-select>
                                                                </v-col>
                                                                <v-col cols="12" lg="2" md="2" class="pb-0">
                                                                    <button @click="print()" type="button"
                                                                        class="btn btn-outline-secondary m-1">
                                                                        Print Payslip
                                                                    </button>
                                                                </v-col>
                                                            </div>
                                                            <v-row class="add-mt">
                                                                <v-col cols="6">
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <h3 class="text-left">
                                                                                {{ authCompanyData.company_name }}
                                                                            </h3>
                                                                            <p>
                                                                                <i>{{ authCompanyData.address }}</i>
                                                                            </p>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Name
                                                                            </h5>
                                                                            <p>{{ employee_name }}</p>
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Address
                                                                            </h5>
                                                                            <p>
                                                                                <i>{{ currentEmployee.employee_address
                                                                                }}</i>
                                                                            </p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="6" class="text-center">
                                                                    <img :src="authCompanyData.logo
                                                                            ? host_url + authCompanyData.logo
                                                                            : '/img/kylian-logo-2.png'
                                                                        " alt="" class="w-50"
                                                                        style="height: inherit;" />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mx-1">
                                                                <v-col cols="12">
                                                                    <v-row>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Department
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Designation
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="6"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Period
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ get_dept_name(
                                                                                    currentEmployee.department_id) }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.employee_designation }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6" class="py-3">
                                                                            <h5 v-if="currentPayRun">
                                                                                {{ currentPayRun.from_date }} - {{
                                                                                    currentPayRun.to_date }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Date
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Name
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Account Number
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Method
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentPayRun ?
                                                                                    currentPayRun.payment_date : null }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.bank_name }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.account_number }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.payment_method }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="6" class="py-3"
                                                                            style="background: #e2e2e2; border-right: 10px solid #fff;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payments
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col v-if="allStatutoryDeductions.length" cols="6"
                                                                            class="py-3"
                                                                            style="background: #e2e2e2; border-left: 10px solid #fff;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Statutory Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6"
                                                                            style="border-right: 10px solid #fff;">
                                                                            <v-row style="border-top: 2px solid #fff;">
                                                                                <v-col cols="6" class="py-3">
                                                                                    <h5>
                                                                                        Pay Basic
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" class="text-right py-3">
                                                                                    <h5>
                                                                                        ₦{{ ((currentGradeLevel(employee_id)
                                                                                            ? currentGradeLevel(employee_id)
                                                                                                .salary : 0) || 0) |
                                                                                            number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <template v-for="fa in employeesFlexibleAllowances(
                                                                                employee_id
                                                                            )">
                                                                                <v-row style="border-top: 2px solid #fff;"
                                                                                    :key="'flexible-allowance-2-' + fa.id">
                                                                                    <v-col cols="6" class="py-3">
                                                                                        <h5>
                                                                                            {{ fa.allowance_name }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                    <v-col cols="6" class="text-right py-3">
                                                                                        <h5>
                                                                                            ₦{{
                                                                                                parseFloat(fa.allowance_amount)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </template>
                                                                            <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Gross Pay
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{ totalAllowanceToPay(
                                                                                            employeesFlexibleAllowances(
                                                                                                employee_id),
                                                                                            (currentGradeLevel(employee_id) ?
                                                                                                currentGradeLevel(employee_id
                                                                                                ).salary : 0) || 0
                                                                                        ) | number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col cols="6"
                                                                            style="border-left: 10px solid #fff;">
                                                                            <template
                                                                                v-for="deduction in allStatutoryDeductions">
                                                                                <v-row v-if="allStatutoryDeductions.length"
                                                                                    style="border-top: 2px solid #fff;"
                                                                                    :key="'statutory-deduction-2-' +
                                                                                        deduction.id
                                                                                        ">
                                                                                    <v-col cols="6" class="py-3">
                                                                                        <h5>
                                                                                            {{ deduction.name }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                    <v-col cols="6" class="text-right py-3">
                                                                                        <h5 v-if="deduction.name.toLowerCase() ==
                                                                                            'pension'
                                                                                            ">
                                                                                            ₦{{ getPensionDeduction(
                                                                                                (currentGradeLevel(employee_id
                                                                                                ) ? currentGradeLevel(
                                                                                                    employee_id).salary : 0) || 0)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                        <h5 v-if="deduction.name.toLowerCase() ==
                                                                                            'nhf'
                                                                                            ">
                                                                                            ₦{{ getNHFDeduction(
                                                                                                (currentGradeLevel(employee_id
                                                                                                ) ? currentGradeLevel(
                                                                                                    employee_id).salary : 0) || 0)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                        <h5 v-if="deduction.name.toLowerCase() ==
                                                                                            'nhis'
                                                                                            ">
                                                                                            ₦{{ getNHISDeduction(
                                                                                                (currentGradeLevel(employee_id
                                                                                                ) ? currentGradeLevel(
                                                                                                    employee_id).salary : 0) || 0)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                        <h5 v-if="deduction.name.toLowerCase() ==
                                                                                            'nsitf'
                                                                                            ">
                                                                                            ₦{{ getNSITFDeduction(
                                                                                                (currentGradeLevel(employee_id
                                                                                                ) ? currentGradeLevel(
                                                                                                    employee_id).salary : 0) || 0)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                        <h5 v-if="deduction.name.toLowerCase() ==
                                                                                            'paye'
                                                                                            ">
                                                                                            ₦{{ getPAYEDeduction(
                                                                                                (currentGradeLevel(employee_id
                                                                                                ) ? currentGradeLevel(
                                                                                                    employee_id).salary : 0) || 0,
                                                                                                employee_id) |
                                                                                                number_with_commas }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </template>
                                                                            <v-row v-if="allStatutoryDeductions.length"
                                                                                style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Total Statutory Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{ (taxTotalEmployeeDeductions(
                                                                                            (currentGradeLevel(employee_id) ?
                                                                                                currentGradeLevel(employee_id
                                                                                                ).salary : 0) || 0, employee_id) -
                                                                                            totalEmployeesFlexibleDeductions(
                                                                                                employee_id)) | number_with_commas
                                                                                        }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row v-if="employeesFlexibleDeductions(
                                                                                        employee_id
                                                                                    ).length
                                                                                    "
                                                                                style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="12" class="py-3 pl-0"
                                                                                    style="background: #e2e2e2; border-left: 10px solid #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Non-Statutory Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <template v-for="fd in employeesFlexibleDeductions(
                                                                                employee_id
                                                                            )">
                                                                                <v-row style="border-top: 2px solid #fff;"
                                                                                    :key="'employee-flexible-deduction-' +
                                                                                        fd.id
                                                                                        ">
                                                                                    <v-col cols="6" class="py-3">
                                                                                        <h5>
                                                                                            {{ fd.deduction_name }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                    <v-col cols="6" class="text-right py-3">
                                                                                        <h5>
                                                                                            ₦{{
                                                                                                parseFloat(fd.deduction_amount)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </template>

                                                                            <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Total Non-Statutory Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{
                                                                                            totalEmployeesFlexibleDeductions(
                                                                                                employee_id) | number_with_commas
                                                                                        }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col class="py-3" cols="6"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Net Pay
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{ finalTaxEmployeeNetPay(
                                                                                            (currentGradeLevel(employee_id) ?
                                                                                                currentGradeLevel(employee_id
                                                                                                ).salary : 0) || 0, employee_id) |
                                                                                            number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                    <v-row class="mt-5" :class="$vuetify.breakpoint.name == 'xs' ||
                                                        $vuetify.breakpoint.name == 'sm'
                                                        ? 'px-2'
                                                        : null
                                                        ">
                                                        <v-col cols="6" lg="2" xl="2" offset-lg="8" offset-xl="8"
                                                            class="pt-0">
                                                            <button @click="steps -= 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Previous
                                                            </button>
                                                        </v-col>
                                                        <v-col cols="6" lg="2" xl="2" class="pt-0">
                                                            <button @click="
                                                            (payrunForm.payment_date =
                                                                currentPayRun.payment_date),
                                                                (steps += 1)
                                                                " style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Next
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>

                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="4">
                                        <div class="container-fluid">
                                            <v-row>
                                                <v-col cols="12" sm="3" md="3">
                                                    <span style="color: #111d5e;">Payment Date <i class="fas fa-asterisk"
                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                    <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false"
                                                        transition="scale-transition" offset-y min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field color="#069"
                                                                prepend-inner-icon="mdi-calendar-month-outline"
                                                                v-model="payrunForm.payment_date" placeholder="Payment date"
                                                                solo class="mt-2" dense readonly v-bind="attrs"
                                                                v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker color="#069" ref="picker3"
                                                            v-model="payrunForm.payment_date" @change="save3" :min="new Date(new Date().getTime() - 1.581e10)
                                                                .toISOString()
                                                                .substring(0, 10)
                                                                " :max="new Date(new Date().getTime() + 2.678e9)
        .toISOString()
        .substring(0, 10)
        "></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" class="card my-3" style="border-radius: 10px;">
                                                    <div class="card-header px-3">
                                                        <h4>Ready to approve</h4>
                                                    </div>
                                                    <div class="card-body px-1 py-1 pt-0 pr-0">
                                                        <div class="table-responsive" :style="$vuetify.breakpoint.name == 'xs' ||
                                                            $vuetify.breakpoint.name == 'sm'
                                                            ? '/*height: 30vh;*/'
                                                            : '/*height: 30vh;*/'
                                                            "
                                                            style="border-radius: 10px; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            S/N
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Employee
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Department
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Pay Basic
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Allowances
                                                                        </th>
                                                                        <template
                                                                            v-for="deduction in allStatutoryDeductions">
                                                                            <th :key="deduction.id" v-if="deduction.name.toLowerCase() ===
                                                                                'pension' ||
                                                                                deduction.name.toLowerCase() ===
                                                                                'nhis'
                                                                                " class="text-left"
                                                                                style="border-top: none;" scope="col">
                                                                                Employer's {{ deduction.name }} Contribution
                                                                            </th>
                                                                        </template>
                                                                        <th v-for="deduction in allStatutoryDeductions"
                                                                            :key="deduction.id" class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            Employee's {{ deduction.name }} Contribution
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Statutory Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Non-Statutory Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Net Pay
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(e, index) in employeesInPayCalendar(
                                                                        currentPayCalendar
                                                                            ? currentPayCalendar.id
                                                                            : null
                                                                    )" :key="'pay-calendar-employee-' + e.id">
                                                                        <td class="text-left">{{ index + 1 }}</td>
                                                                        <td class="text-left">
                                                                            {{ e.employee_fristname + " " +
                                                                                e.employee_lastname }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            {{ e.department_name }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ ((currentGradeLevel(e.employee_id) ?
                                                                                currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                0) | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ totalEmployeesFlexibleAllowances(
                                                                                e.employee_id) | number_with_commas }}
                                                                        </td>
                                                                        <template
                                                                            v-for="deduction in allStatutoryDeductions">
                                                                            <td :key="deduction.id" v-if="deduction.name.toLowerCase() ===
                                                                                'pension' ||
                                                                                deduction.name.toLowerCase() ===
                                                                                'nhis'
                                                                                " class="text-left">
                                                                                ₦{{ parseFloat(deduction.name.toLowerCase()
                                                                                    === "pension" ? getEmployerPensionDeduction(
                                                                                        getPensionDeduction((currentGradeLevel(
                                                                                            e.employee_id) ?
                                                                                            currentGradeLevel(e.employee_id).salary :
                                                                                            0) || 0)) : getEmployerNHISDeduction(
                                                                                                getNHISDeduction((currentGradeLevel(
                                                                                                    e.employee_id) ? currentGradeLevel(
                                                                                                        e.employee_id).salary : 0) || 0))) |
                                                                                    number_with_commas }}
                                                                            </td>
                                                                        </template>
                                                                        <td v-for="deduction in allStatutoryDeductions"
                                                                            :key="'approve-ready-2-' + deduction.id"
                                                                            class="text-left">
                                                                            <span v-if="deduction.name.toLowerCase() ==
                                                                                'pension'
                                                                                ">
                                                                                ₦{{ getPensionDeduction(
                                                                                    (currentGradeLevel(e.employee_id) ?
                                                                                        currentGradeLevel(e.employee_id).salary :
                                                                                        0) || 0) | number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.name.toLowerCase() == 'nhf'
                                                                                ">
                                                                                ₦{{ getNHFDeduction(
                                                                                    (currentGradeLevel(e.employee_id) ?
                                                                                        currentGradeLevel(e.employee_id).salary :
                                                                                        0) || 0) | number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.name.toLowerCase() == 'nhis'
                                                                                ">
                                                                                ₦{{ getNHISDeduction(
                                                                                    (currentGradeLevel(e.employee_id) ?
                                                                                        currentGradeLevel(e.employee_id).salary :
                                                                                        0) || 0) | number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.name.toLowerCase() ==
                                                                                'nsitf'
                                                                                ">
                                                                                ₦{{ getNSITFDeduction(
                                                                                    (currentGradeLevel(e.employee_id) ?
                                                                                        currentGradeLevel(e.employee_id).salary :
                                                                                        0) || 0) | number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.name.toLowerCase() == 'paye'
                                                                                ">
                                                                                ₦{{ getPAYEDeduction(
                                                                                    (currentGradeLevel(e.employee_id) ?
                                                                                        currentGradeLevel(e.employee_id).salary :
                                                                                        0) || 0, e.employee_id) |
                                                                                    number_with_commas }}
                                                                            </span>
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ (taxTotalEmployeeDeductions(
                                                                                (currentGradeLevel(e.employee_id) ?
                                                                                    currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                0, e.employee_id) -
                                                                                totalEmployeesFlexibleDeductions(e.employee_id
                                                                                )) | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ totalEmployeesFlexibleDeductions(
                                                                                e.employee_id) | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ finalTaxEmployeeNetPay(
                                                                                (currentGradeLevel(e.employee_id) ?
                                                                                    currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                0, e.employee_id) | number_with_commas }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col"></th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col"></th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col"></th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Pay Basic
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Allowances
                                                                        </th>
                                                                        <template
                                                                            v-for="deduction in allStatutoryDeductions">
                                                                            <th :key="deduction.id" v-if="deduction.name.toLowerCase() ===
                                                                                'pension' ||
                                                                                deduction.name.toLowerCase() ===
                                                                                'nhis'
                                                                                " class="text-left"
                                                                                style="border-top: none;" scope="col">
                                                                                Employer's {{ deduction.name }} Contribution
                                                                            </th>
                                                                        </template>
                                                                        <th v-for="deduction in allStatutoryDeductions"
                                                                            :key="deduction.id" class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            Employee's {{ deduction.name }} Contribution
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Statutory Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Non-Statutory Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Net Pay
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th class="text-left"></th>
                                                                        <th class="text-left"></th>
                                                                        <th class="text-left">TOTAL</th>
                                                                        <th class="text-left">
                                                                            ₦{{ allPayBasic | number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ allTotalAllowances | number_with_commas }}
                                                                        </th>
                                                                        <template
                                                                            v-for="deduction in allStatutoryDeductions">
                                                                            <th v-if="deduction.name.toLowerCase() ===
                                                                                'pension' ||
                                                                                deduction.name.toLowerCase() ===
                                                                                'nhis'
                                                                                " :key="'breakdown-3-' + deduction.id"
                                                                                class="text-left">
                                                                                <span v-if="deduction.name.toLowerCase() ===
                                                                                    'pension'
                                                                                    ">
                                                                                    ₦{{
                                                                                        allUnapprovedCompanyPensionDeductions |
                                                                                        number_with_commas }}
                                                                                </span>
                                                                                <span v-if="deduction.name.toLowerCase() ===
                                                                                    'nhis'
                                                                                    ">
                                                                                    ₦{{ allUnapprovedCompanyNHISDeductions |
                                                                                        number_with_commas }}
                                                                                </span>
                                                                            </th>
                                                                        </template>
                                                                        <th v-for="deduction in allStatutoryDeductions"
                                                                            :key="'breakdown-employee-s-deduction-' +
                                                                                deduction.id
                                                                                " class="text-left">
                                                                            <span v-if="deduction.name.toLowerCase() ===
                                                                                'paye'
                                                                                ">
                                                                                ₦{{ allUnapprovedTotalPAYE |
                                                                                    number_with_commas }}
                                                                            </span>
                                                                            <span v-else-if="deduction.name.toLowerCase() ===
                                                                                'pension'
                                                                                ">
                                                                                ₦{{ allUnapprovedTotalPension |
                                                                                    number_with_commas }}
                                                                            </span>
                                                                            <span v-else-if="deduction.name.toLowerCase() ===
                                                                                'nhis'
                                                                                ">
                                                                                ₦{{ allUnapprovedTotalNHIS |
                                                                                    number_with_commas }}
                                                                            </span>
                                                                            <span v-else-if="deduction.name.toLowerCase() === 'nhf'
                                                                                ">
                                                                                ₦{{ allUnapprovedTotalNHF |
                                                                                    number_with_commas }}
                                                                            </span>
                                                                            <span v-else-if="deduction.name.toLowerCase() ===
                                                                                'nsitf'
                                                                                ">
                                                                                ₦{{ allUnapprovedTotalNSITF |
                                                                                    number_with_commas }}
                                                                            </span>
                                                                            <span v-else class="d-none"></span>
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ allUnapprovedTotalStatutoryDeduction |
                                                                                number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ allUnapprovedTotalNonStatutoryDeduction |
                                                                                number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ allUnapprovedTotalNetPay |
                                                                                number_with_commas }}
                                                                        </th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-row>
                                                        <v-col cols="6" lg="2" xl="2" offset-lg="8" offset-xl="8"
                                                            class="pt-0">
                                                            <button @click="steps -= 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Previous
                                                            </button>
                                                        </v-col>
                                                        <v-col v-if="permissionIsAssigned('payroll-approve')" cols="6"
                                                            lg="2" xl="2" class="pt-0">
                                                            <button v-if="currentPayRun
                                                                ? currentPayRun.approved == 0
                                                                : null
                                                                " @click="
        approve_payrun_submit
            ? null
            : approvePayRun() /*approvePayRun()*/
        " style="width: 100%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                {{ approve_payrun_text }}
                                                                <v-icon :style="!approve_payrun_submit
                                                                    ? 'display: none;'
                                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                                    ">
                                                                    fal fa-circle-notch fa-spin
                                                                </v-icon>
                                                            </button>
                                                            <button v-else @click="
                                                                disapprove_payrun_submit
                                                                    ? null
                                                                    : disapprovePayRun()
                                                                " style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                {{ disapprove_payrun_text }}
                                                                <v-icon :style="!disapprove_payrun_submit
                                                                    ? 'display: none;'
                                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                                    ">
                                                                    fal fa-circle-notch fa-spin
                                                                </v-icon>
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <div v-else-if="payrunView == 4" class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? 'px-3'
                : null
                ">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                        ">
                        <h1 class="col-12 my-0 row pb-0">
                            <span class="col-6">Payrun</span>
                            <span class="col-6 text-right">
                                <span v-if="currentPayRun ? currentPayRun.approved == 1 : null"
                                    class="badge badge-success">APPROVED</span>
                                <span v-else class="badge badge-light">UNAPPROVED</span>
                            </span>
                        </h1>
                        <div class="col-12" :class="$vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                            ? 'px-0'
                            : 'px-7'
                            ">
                            <v-stepper v-model="steps2" non-linear>
                                <v-stepper-header>
                                    <v-stepper-step :editable="true" step="1">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ? "PaySlips"
                                            : null }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :editable="true" step="2">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ? "Overview"
                                            : null }}
                                    </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content v-if="currentEmployee" :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="1">
                                        <div class="container-fluid">
                                            <v-row v-if="isFlexiblePayroll">
                                                <v-col cols="12" class="m0-3" style="border-radius: 10px;">
                                                    <div id="printMe" class="card">
                                                        <div class="card-header px-3">
                                                            <h4>Employee Payslip Summary</h4>
                                                        </div>
                                                        <div class="card-body px-3 pt-1 pb-8">
                                                            <div class="noShowOnPrint d-print-none">
                                                                <v-col cols="12" lg="4" md="4" class="pb-0">
                                                                    <v-select class="remove-mb"
                                                                        :items="employeesInPayCalendarNames"
                                                                        placeholder="Employee" dense solo :full-width="true"
                                                                        v-model="employee_name"> </v-select>
                                                                </v-col>
                                                                <v-col cols="12" lg="2" md="2" class="pb-0">
                                                                    <button @click="print()" type="button"
                                                                        class="btn btn-outline-secondary m-1">
                                                                        Print Payslip
                                                                    </button>
                                                                </v-col>
                                                            </div>
                                                            <v-row class="add-mt">
                                                                <v-col cols="6">
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <h3 class="text-left">
                                                                                {{ authCompanyData.company_name }}
                                                                            </h3>
                                                                            <p>
                                                                                <i>{{ authCompanyData.address }}</i>
                                                                            </p>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Name
                                                                            </h5>
                                                                            <p>{{ employee_name }}</p>
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Address
                                                                            </h5>
                                                                            <p>
                                                                                <i>{{ currentEmployee.employee_address
                                                                                }}</i>
                                                                            </p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="6" class="text-center">
                                                                    <img :src="authCompanyData.logo
                                                                            ? host_url + authCompanyData.logo
                                                                            : '/img/kylian-logo-2.png'
                                                                        " alt="" class="w-50"
                                                                        style="height: inherit;" />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mx-1">
                                                                <v-col cols="12">
                                                                    <v-row>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Department
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Designation
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="6"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Period
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ get_dept_name(
                                                                                    currentEmployee.department_id) }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.employee_designation }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6" class="py-3">
                                                                            <h5 v-if="currentPayRun">
                                                                                {{ currentPayRun.from_date }} - {{
                                                                                    currentPayRun.to_date }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Date
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Name
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Account Number
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Method
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentPayRun ?
                                                                                    currentPayRun.payment_date : null }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.bank_name }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.account_number }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.payment_method }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="6" class="py-3"
                                                                            style="background: #e2e2e2; border-right: 10px solid #fff;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payments
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6" class="py-3"
                                                                            style="background: #e2e2e2; border-left: 10px solid #fff;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6"
                                                                            style="border-right: 10px solid #fff;">
                                                                            <v-row style="border-top: 2px solid #fff;">
                                                                                <v-col cols="6" class="py-3">
                                                                                    <h5>
                                                                                        Pay Basic
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" class="text-right py-3">
                                                                                    <h5>
                                                                                        ₦{{ ((currentGradeLevel(employee_id)
                                                                                            ? currentGradeLevel(employee_id)
                                                                                                .salary : 0) || 0) |
                                                                                            number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Gross Pay
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{ totalAllowanceToPay(
                                                                                            employeesFlexibleAllowances(
                                                                                                employee_id),
                                                                                            (currentGradeLevel(employee_id) ?
                                                                                                currentGradeLevel(employee_id
                                                                                                ).salary : 0) || 0
                                                                                        ) | number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col cols="6"
                                                                            style="border-left: 10px solid #fff;">
                                                                            <template v-for="fd in trueEmployeesFlexibleDeductions(
                                                                                employee_id
                                                                            )">
                                                                                <v-row style="border-top: 2px solid #fff;"
                                                                                    :key="'true-flexible-deduction-' + fd.id
                                                                                        ">
                                                                                    <v-col cols="6" class="py-3">
                                                                                        <h5>
                                                                                            {{ fd.deduction_name }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                    <v-col cols="6" class="text-right py-3">
                                                                                        <h5>
                                                                                            ₦{{
                                                                                                parseFloat(fd.deduction_amount)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </template>

                                                                            <v-row v-if="totalTrueEmployeesFlexibleDeductions(
                                                                                employee_id
                                                                            ) >= 1
                                                                                "
                                                                                style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Total Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{
                                                                                            totalTrueEmployeesFlexibleDeductions(
                                                                                                employee_id) | number_with_commas
                                                                                        }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col class="py-3" cols="6"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Net Pay
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{ finalTaxEmployeeNetPay(
                                                                                            (currentGradeLevel(employee_id) ?
                                                                                                currentGradeLevel(employee_id
                                                                                                ).salary : 0) || 0, employee_id) |
                                                                                            number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                    <v-row class="mt-5" :class="$vuetify.breakpoint.name == 'xs' ||
                                                        $vuetify.breakpoint.name == 'sm'
                                                        ? 'px-2'
                                                        : null
                                                        ">
                                                        <v-col offset="6" cols="6" lg="2" xl="2" offset-lg="10"
                                                            offset-xl="10" class="pt-0">
                                                            <button @click="steps2 += 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Next
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row v-else>
                                                <v-col cols="12" class="m0-3" style="border-radius: 10px;">
                                                    <div id="printMe" class="card">
                                                        <div class="card-header px-3">
                                                            <h4>Employee Payslip Summary</h4>
                                                        </div>
                                                        <div class="card-body px-3 pt-1 pb-8">
                                                            <div class="noShowOnPrint d-print-none">
                                                                <v-col cols="12" lg="4" md="4" class="pb-0">
                                                                    <v-select class="remove-mb"
                                                                        :items="employeesInPayCalendarNames"
                                                                        placeholder="Employee" dense solo :full-width="true"
                                                                        v-model="employee_name"> </v-select>
                                                                </v-col>
                                                                <v-col cols="12" lg="2" md="2" class="pb-0">
                                                                    <button @click="print()" type="button"
                                                                        class="btn btn-outline-secondary m-1">
                                                                        Print Payslip
                                                                    </button>
                                                                </v-col>
                                                            </div>
                                                            <v-row class="add-mt">
                                                                <v-col cols="6">
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <h3 class="text-left">
                                                                                {{ authCompanyData.company_name }}
                                                                            </h3>
                                                                            <p>
                                                                                <i>{{ authCompanyData.address }}</i>
                                                                            </p>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Name
                                                                            </h5>
                                                                            <p>{{ employee_name }}</p>
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Address
                                                                            </h5>
                                                                            <p>
                                                                                <i>{{ currentEmployee.employee_address
                                                                                }}</i>
                                                                            </p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="6" class="text-center">
                                                                    <img :src="authCompanyData.logo
                                                                            ? host_url + authCompanyData.logo
                                                                            : '/img/kylian-logo-2.png'
                                                                        " alt="" class="w-50"
                                                                        style="height: inherit;" />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mx-1">
                                                                <v-col cols="12">
                                                                    <v-row>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Department
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Designation
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="6"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Period
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ get_dept_name(
                                                                                    currentEmployee.department_id) }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.employee_designation }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6" class="py-3">
                                                                            <h5 v-if="currentPayRun">
                                                                                {{ currentPayRun.from_date }} - {{
                                                                                    currentPayRun.to_date }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Date
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Name
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Account Number
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Method
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentPayRun ?
                                                                                    currentPayRun.payment_date : null }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.bank_name }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.account_number }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentEmployee.payment_method }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="6" class="py-3"
                                                                            style="background: #e2e2e2; border-right: 10px solid #fff;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payments
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col v-if="totalTrueEmployeesFlexibleStatutoryDeductions(
                                                                            employee_id
                                                                        ) >= 1
                                                                            " cols="6" class="py-3"
                                                                            style="background: #e2e2e2; border-left: 10px solid #fff;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Statutory Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6"
                                                                            style="border-right: 10px solid #fff;">
                                                                            <v-row style="border-top: 2px solid #fff;">
                                                                                <v-col cols="6" class="py-3">
                                                                                    <h5>
                                                                                        Pay Basic
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" class="text-right py-3">
                                                                                    <h5>
                                                                                        ₦{{ ((currentGradeLevel(employee_id)
                                                                                            ? currentGradeLevel(employee_id)
                                                                                                .salary : 0) || 0) |
                                                                                            number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Gross Pay
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{ totalAllowanceToPay(
                                                                                            employeesFlexibleAllowances(
                                                                                                employee_id),
                                                                                            (currentGradeLevel(employee_id) ?
                                                                                                currentGradeLevel(employee_id
                                                                                                ).salary : 0) || 0
                                                                                        ) | number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col cols="6"
                                                                            style="border-left: 10px solid #fff;">
                                                                            <template v-for="fd in trueEmployeesFlexibleStatutoryDeductions(
                                                                                employee_id
                                                                            )">
                                                                                <v-row style="border-top: 2px solid #fff;"
                                                                                    :key="'flexible-statutory-deduction-' +
                                                                                        fd.id
                                                                                        ">
                                                                                    <v-col cols="6" class="py-3">
                                                                                        <h5>
                                                                                            {{ fd.deduction_name }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                    <v-col cols="6" class="text-right py-3">
                                                                                        <h5>
                                                                                            ₦{{
                                                                                                parseFloat(fd.deduction_amount)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </template>
                                                                            <v-row v-if="totalTrueEmployeesFlexibleStatutoryDeductions(
                                                                                employee_id
                                                                            ) >= 1
                                                                                "
                                                                                style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Total Statutory Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{
                                                                                            totalTrueEmployeesFlexibleStatutoryDeductions(
                                                                                                employee_id) | number_with_commas
                                                                                        }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row v-if="totalTrueEmployeesFlexibleDeductions(
                                                                                        employee_id
                                                                                    ) >= 1
                                                                                    "
                                                                                style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="12" class="py-3 pl-0"
                                                                                    style="background: #e2e2e2; border-left: 10px solid #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Non-Statutory Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <template v-for="fd in trueEmployeesFlexibleDeductions(
                                                                                employee_id
                                                                            )">
                                                                                <v-row style="border-top: 2px solid #fff;"
                                                                                    :key="'true-flexible-deduction-' + fd.id
                                                                                        ">
                                                                                    <v-col cols="6" class="py-3">
                                                                                        <h5>
                                                                                            {{ fd.deduction_name }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                    <v-col cols="6" class="text-right py-3">
                                                                                        <h5>
                                                                                            ₦{{
                                                                                                parseFloat(fd.deduction_amount)
                                                                                                | number_with_commas }}
                                                                                        </h5>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </template>

                                                                            <v-row v-if="totalTrueEmployeesFlexibleDeductions(
                                                                                employee_id
                                                                            ) >= 1
                                                                                "
                                                                                style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Total Non-Statutory Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{
                                                                                            totalTrueEmployeesFlexibleDeductions(
                                                                                                employee_id) | number_with_commas
                                                                                        }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                <v-col class="py-3" cols="6"
                                                                                    style="background: #e2e2e2;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Net Pay
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6" style="background: #e2e2e2;"
                                                                                    class="text-right py-3">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        ₦{{ finalTaxEmployeeNetPay(
                                                                                            (currentGradeLevel(employee_id) ?
                                                                                                currentGradeLevel(employee_id
                                                                                                ).salary : 0) || 0, employee_id) |
                                                                                            number_with_commas }}
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                    <v-row class="mt-5" :class="$vuetify.breakpoint.name == 'xs' ||
                                                        $vuetify.breakpoint.name == 'sm'
                                                        ? 'px-2'
                                                        : null
                                                        ">
                                                        <v-col offset="6" cols="6" lg="2" xl="2" offset-lg="10"
                                                            offset-xl="10" class="pt-0">
                                                            <button @click="steps2 += 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Next
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>

                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="2">
                                        <div class="container-fluid">
                                            <v-row>
                                                <v-col cols="12" sm="3" md="3">
                                                    <span style="color: #111d5e;">Payment Date <i class="fas fa-asterisk"
                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                    <v-menu disabled ref="menu3" v-model="menu3"
                                                        :close-on-content-click="false" transition="scale-transition"
                                                        offset-y min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field disabled color="#069"
                                                                prepend-inner-icon="mdi-calendar-month-outline"
                                                                v-model="currentPayRun.payment_date"
                                                                placeholder="Payment date" solo class="mt-2" dense readonly
                                                                v-bind="attrs" v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker disabled color="#069" ref="picker3"
                                                            v-model="currentPayRun.payment_date" @change="save3" :min="new Date(new Date().getTime() - 1.581e10)
                                                                .toISOString()
                                                                .substring(0, 10)
                                                                " :max="new Date(new Date().getTime() + 2.678e9)
        .toISOString()
        .substring(0, 10)
        "></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" offset-sm="6" offset-md="6" sm="3" md="3" class="pb-0">
                                                    <button @click="printSummary()" type="button"
                                                        class="btn btn-outline-secondary m-1">
                                                        Print Payslip
                                                    </button>
                                                </v-col>
                                                <v-col id="printPayrunSummary" cols="12" class="card my-3"
                                                    style="border-radius: 10px;">
                                                    <div class="card-header px-3" style="background: #fff;">
                                                        <v-row>
                                                            <v-col cols="12" md="4"></v-col>
                                                            <v-col cols="12" md="4" class="text-left text-md-center mb-4">
                                                                <img :src="authCompanyData.logo
                                                                    ? host_url + authCompanyData.logo
                                                                    : '/img/kylian-logo-2.png'
                                                                    " alt="" class="w-50" style="height: inherit;" />
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <h4>Payrun breakdown</h4>
                                                            </v-col>
                                                            <v-col cols="12" md="9">
                                                                <h6 class="mt-2 mb-3">
                                                                    <span style="font-weight: bold;">Start Date:</span>
                                                                    {{ currentPayRun.from_date }}.
                                                                    <span style="font-weight: bold;">End Date:</span>
                                                                    {{ currentPayRun.to_date }}.
                                                                    <span style="font-weight: bold;">Payment Date:</span>
                                                                    {{ currentPayRun.payment_date }}.
                                                                </h6>
                                                                <h6 v-if="trueEmployersFlexibleStatutoryDeductions(
                                                                    employeesInPayCalendar(
                                                                        currentPayCalendar
                                                                            ? currentPayCalendar.id
                                                                            : null
                                                                    )[0].employee_id
                                                                ).length
                                                                    ">
                                                                    <div class="mb-2" v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                                                        employeesInPayCalendar(
                                                                            currentPayCalendar
                                                                                ? currentPayCalendar.id
                                                                                : null
                                                                        )[0].employee_id
                                                                    )" :key="'statutory-summary-' + deduction.id">
                                                                        <p style="font-weight: bold;">
                                                                            Employer's Total {{ deduction.deduction_name }}
                                                                            Contribution: ₦{{
                                                                                (deduction.deduction_name.toLowerCase() ===
                                                                                    "pension" ? allCompanyPensionDeductions :
                                                                                    allCompanyNHISDeductions) | number_with_commas
                                                                            }}.
                                                                        </p>
                                                                        <p style="font-weight: bold;">
                                                                            Employee's Total {{ deduction.deduction_name }}
                                                                            Contribution: ₦{{
                                                                                (deduction.deduction_name.toLowerCase() ===
                                                                                    "pension" ? allTotalPension : allTotalNHIS) |
                                                                                number_with_commas }}.
                                                                        </p>
                                                                        <p style="font-weight: bold;">
                                                                            Total {{ deduction.deduction_name }}
                                                                            Contribution: ₦{{
                                                                                (deduction.deduction_name.toLowerCase() ===
                                                                                    "pension" ? parseFloat(
                                                                                        allCompanyPensionDeductions +
                                                                                        allTotalPension) : parseFloat(
                                                                                            allCompanyNHISDeductions + allTotalNHIS)) |
                                                                                number_with_commas }}.
                                                                        </p>
                                                                    </div>
                                                                </h6>
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <h3 class="text-left text-md-right">
                                                                    {{ authCompanyData.company_name }}
                                                                </h3>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <div class="card-body px-1 py-1 pt-0 pr-0">
                                                        <div :style="$vuetify.breakpoint.name == 'xs' ||
                                                            $vuetify.breakpoint.name == 'sm'
                                                            ? '/*height: 30vh;*/'
                                                            : '/*height: 30vh;*/'
                                                            "
                                                            style="border-radius: 10px; padding-top: 0; padding-bottom: 0;">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left" style="border-top: none;">
                                                                            S/N
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Employee
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Department
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Pay Basic
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Allowances
                                                                        </th>
                                                                        <th v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                                                            employeesInPayCalendar(
                                                                                currentPayCalendar
                                                                                    ? currentPayCalendar.id
                                                                                    : null
                                                                            )[0].employee_id
                                                                        )" :key="'company-breakdown-' + deduction.id"
                                                                            class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Employer's {{ deduction.deduction_name }}
                                                                            Contribution
                                                                        </th>
                                                                        <th v-for="deduction in trueEmployeesFlexibleStatutoryDeductions(
                                                                            employeesInPayCalendar(
                                                                                currentPayCalendar
                                                                                    ? currentPayCalendar.id
                                                                                    : null
                                                                            )[0].employee_id
                                                                        )" :key="'breakdown-' + deduction.id"
                                                                            class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Employee's {{ deduction.deduction_name }}
                                                                            Contribution
                                                                        </th>
                                                                        <th v-if="!isFlexiblePayroll" class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            Total Statutory Deductions
                                                                        </th>
                                                                        <th v-if="isFlexiblePayroll" class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            Total Deductions
                                                                        </th>
                                                                        <th v-else class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            Total Non-Statutory Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Net Pay
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(e, index) in employeesInPayCalendar(
                                                                        currentPayCalendar
                                                                            ? currentPayCalendar.id
                                                                            : null
                                                                    )" :key="'pay-calendar-employee-2-' + e.id">
                                                                        <th class="text-left">{{ index + 1 }}</th>
                                                                        <td class="text-left">
                                                                            {{ e.employee_fristname + " " +
                                                                                e.employee_lastname }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            {{ e.department_name }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ ((currentGradeLevel(e.employee_id) ?
                                                                                currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                0) | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ totalEmployeesFlexibleAllowances(
                                                                                e.employee_id) | number_with_commas }}
                                                                        </td>
                                                                        <td v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                                                            e.employee_id
                                                                        )" :key="'breakdown-3-' + deduction.id"
                                                                            class="text-left">
                                                                            ₦{{ parseFloat(
                                                                                deduction.deduction_name.toLowerCase() ===
                                                                                    "pension" ? getCompanyPensionForEmployee(
                                                                                        e.employee_id) : getCompanyNHISForEmployee(
                                                                                            e.employee_id))
                                                                                | number_with_commas }}
                                                                        </td>
                                                                        <td v-for="deduction in trueEmployeesFlexibleStatutoryDeductions(
                                                                            e.employee_id
                                                                        )" :key="'breakdown-2-' + deduction.id"
                                                                            class="text-left">
                                                                            ₦{{ parseFloat(deduction.deduction_amount) |
                                                                                number_with_commas }}
                                                                        </td>
                                                                        <td v-if="!isFlexiblePayroll" class="text-left">
                                                                            ₦{{
                                                                                totalTrueEmployeesFlexibleStatutoryDeductions(
                                                                                    e.employee_id) | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ totalTrueEmployeesFlexibleDeductions(
                                                                                e.employee_id) | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ finalTaxEmployeeNetPay(
                                                                                (currentGradeLevel(e.employee_id) ?
                                                                                    currentGradeLevel(e.employee_id).salary : 0) ||
                                                                                0, e.employee_id) | number_with_commas }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr style="border-top: 1px solid #ddd;">
                                                                        <th class="text-left"></th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col"></th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col"></th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Pay Basic
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Allowances
                                                                        </th>
                                                                        <th v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                                                            employeesInPayCalendar(
                                                                                currentPayCalendar
                                                                                    ? currentPayCalendar.id
                                                                                    : null
                                                                            )[0].employee_id
                                                                        )" :key="'company-breakdown-' + deduction.id"
                                                                            class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Employer's {{ deduction.deduction_name }}
                                                                            Contribution
                                                                        </th>
                                                                        <th v-for="deduction in trueEmployeesFlexibleStatutoryDeductions(
                                                                            employeesInPayCalendar(
                                                                                currentPayCalendar
                                                                                    ? currentPayCalendar.id
                                                                                    : null
                                                                            )[0].employee_id
                                                                        )" :key="'breakdown-' + deduction.id"
                                                                            class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Employee's {{ deduction.deduction_name }}
                                                                            Contribution
                                                                        </th>
                                                                        <th v-if="!isFlexiblePayroll" class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            Total Statutory Deductions
                                                                        </th>
                                                                        <th v-if="isFlexiblePayroll" class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            Total Deductions
                                                                        </th>
                                                                        <th v-else class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            Total Non-Statutory Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Net Pay
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th class="text-left"></th>
                                                                        <th class="text-left"></th>
                                                                        <th class="text-left">TOTAL</th>
                                                                        <th class="text-left">
                                                                            ₦{{ allPayBasic | number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ allTotalAllowances | number_with_commas }}
                                                                        </th>
                                                                        <th v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                                                            employeesInPayCalendar(
                                                                                currentPayCalendar
                                                                                    ? currentPayCalendar.id
                                                                                    : null
                                                                            )[0].employee_id
                                                                        )" :key="'breakdown-3-' + deduction.id"
                                                                            class="text-left">
                                                                            <span v-if="deduction.deduction_name.toLowerCase() ===
                                                                                'pension'
                                                                                ">
                                                                                ₦{{ allCompanyPensionDeductions |
                                                                                    number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.deduction_name.toLowerCase() ===
                                                                                'nhis'
                                                                                ">
                                                                                ₦{{ allCompanyNHISDeductions |
                                                                                    number_with_commas }}
                                                                            </span>
                                                                        </th>
                                                                        <th v-for="deduction in trueEmployeesFlexibleStatutoryDeductions(
                                                                            employeesInPayCalendar(
                                                                                currentPayCalendar
                                                                                    ? currentPayCalendar.id
                                                                                    : null
                                                                            )[0].employee_id
                                                                        )" :key="'breakdown-2-' + deduction.id"
                                                                            class="text-left">
                                                                            <span v-if="deduction.deduction_name.toLowerCase() ===
                                                                                'paye'
                                                                                ">
                                                                                ₦{{ allTotalPAYE | number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.deduction_name.toLowerCase() ===
                                                                                'pension'
                                                                                ">
                                                                                ₦{{ allTotalPension | number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.deduction_name.toLowerCase() ===
                                                                                'nhis'
                                                                                ">
                                                                                ₦{{ allTotalNHIS | number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.deduction_name.toLowerCase() ===
                                                                                'nhf'
                                                                                ">
                                                                                ₦{{ allTotalNHF | number_with_commas }}
                                                                            </span>
                                                                            <span v-if="deduction.deduction_name.toLowerCase() ===
                                                                                'nsitf'
                                                                                ">
                                                                                ₦{{ allTotalNSITF | number_with_commas }}
                                                                            </span>
                                                                        </th>
                                                                        <th v-if="!isFlexiblePayroll" class="text-left">
                                                                            ₦{{ allTotalStatutoryDeduction |
                                                                                number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ allTotalNonStatutoryDeduction |
                                                                                number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ allTotalNetPay | number_with_commas }}
                                                                        </th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div class="text-left">
                                                                <h4 class="pt-5">
                                                                    Signature: ____________________
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-row>
                                                        <v-col cols="6" lg="2" xl="2" offset-lg="10" offset-xl="10"
                                                            class="pt-0">
                                                            <button @click="steps2 -= 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Previous
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <div v-else-if="payrunView == 6" class="main-content pt-0" :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? ''
                : 'margin-top:50px;'
                " :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
        ? 'px-5'
        : null
        ">
                <CompanyTransactions />
            </div>
            <div v-else class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? 'px-3'
                : null
                ">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                        ">
                        <h1 class="col-12 my-0 row pb-0">
                            <span class="col-6">Salaries Payment</span>
                            <span class="col-6 text-right"> </span>
                        </h1>
                        <div class="col-12" :class="$vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                            ? 'px-0'
                            : 'px-7'
                            ">
                            <div class="container-fluid">
                                <v-row>
                                    <v-col id="paySalaries" cols="12" class="card my-3" style="border-radius: 10px;">
                                        <div class="card-header px-3" style="background: #fff;">
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <h4>Salaries payment breakdown</h4>
                                                </v-col>
                                                <v-col v-if="!paymentSuspended" cols="12" md="6" class="text-lg-right">
                                                    <button v-if="subaccountAvailableBalance >= trueAmountToBePaid
                                                        " :disabled="pay_salaries_loading ||
        trueEmployeesToBePaid.length === 0
        " @click="
        pay_salaries_loading ||
            trueEmployeesToBePaid.length === 0
            ? null
            : paySalaries()
        " type="button" class="btn btn-lg btn-secondary m-1">
                                                        {{ pay_salaries_text }}
                                                        <v-icon :style="!pay_salaries_loading
                                                            ? 'display: none;'
                                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                            ">
                                                            fal fa-circle-notch fa-spin
                                                        </v-icon>
                                                    </button>
                                                    <button v-else :disabled="true" type="button"
                                                        class="btn btn-lg btn-secondary m-1" style="cursor: not-allowed;">
                                                        {{ pay_salaries_text }}
                                                    </button>
                                                    <br />
                                                    <span v-if="subaccountAvailableBalance < trueAmountToBePaid
                                                        " class="font-bold" style="font-weight: bold; color: red;">
                                                        Please ensure your available balance is more than total salaries
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" md="9">
                                                    <h6>
                                                        <span style="font-weight: bold;">Available Balance:</span>
                                                        ₦{{ subaccountAvailableBalance | number_with_commas }}.
                                                        <span style="font-weight: bold;">Total Amount to Pay:</span>
                                                        ₦{{ trueAmountToBePaid | number_with_commas }}.
                                                        <span style="font-weight: bold;">Total Employees to Pay:</span> {{
                                                            trueEmployeesToBePaid.length }}.
                                                    </h6>
                                                    <h6>
                                                        <span style="font-weight: bold;">Start Date:</span>
                                                        {{ currentPayRun.from_date }}.
                                                        <span style="font-weight: bold;">End Date:</span> {{
                                                            currentPayRun.to_date }}. <span style="font-weight: bold;">Payment
                                                            Date:</span> {{ currentPayRun.payment_date }}.
                                                    </h6>
                                                    <h6 style="font-weight: bold; text-transform: uppercase;"
                                                        class="animate__animated animate__fadeIn animate__fast animate__infinite">
                                                        It takes some seconds to see the payment status responses for
                                                        employees
                                                    </h6>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <h3 class="text-left text-md-right">{{ authCompanyData.company_name }}
                                                    </h3>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="card-body px-1 py-1 pt-0 pr-0">
                                            <div style="border-radius: 10px; padding-top: 0; padding-bottom: 0;">
                                                <v-overlay v-if="fetchingEmployeesForPayment"
                                                    :value="fetchingEmployeesForPayment">
                                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                                </v-overlay>
                                                <table v-else class="table">
                                                    <thead>
                                                        <tr>
                                                            <th v-if="!paymentSuspended" scope="col"
                                                                style="width: 5%; border-top: none;"
                                                                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'padding: 0px 10px 20px 15px;' : 'padding: 0px 10px 20px 30px;'">
                                                                <label v-if="verifiedEmployeesToBePaid.length >= 1"
                                                                    class="checkbox checkbox-secondary">
                                                                    <input v-model="allChecked" type="checkbox"
                                                                        @change="allCheckboxesFunc()" />
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </th>
                                                            <th class="text-left" style="border-top: none;">S/N</th>
                                                            <th class="text-left" style="border-top: none;" scope="col">
                                                                First Name</th>
                                                            <th class="text-left" style="border-top: none;" scope="col">Last
                                                                Name</th>
                                                            <th class="text-left" style="border-top: none;" scope="col">Bank
                                                            </th>
                                                            <th class="text-left" style="border-top: none;" scope="col">
                                                                Account Number</th>
                                                            <th class="text-left" style="border-top: none;" scope="col">
                                                                Amount</th>
                                                            <th class="text-left" style="border-top: none;" scope="col">
                                                                Narration</th>
                                                            <th class="text-center" style="border-top: none;" scope="col">
                                                                Account Status</th>
                                                            <th class="text-center" style="border-top: none;" scope="col">
                                                                Status Message</th>
                                                            <th class="text-left" style="border-top: none;" scope="col">
                                                                Payment Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(e, index) in payrollPaymentUsers"
                                                            :key="'pay-calendar-employee-2-' + e.id">
                                                            <th v-if="!paymentSuspended" scope="row"
                                                                style="padding-left: 2.5%;">
                                                                <label
                                                                    v-if="e.is_verified == 1 && e.payment_status != 1 && e.payment_status != 2 && nameIsValid(e)"
                                                                    class="checkbox checkbox-secondary">
                                                                    <input type="checkbox" v-model="e.checked" />
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                                <label v-else class="checkbox checkbox-secondary"> </label>
                                                            </th>
                                                            <th class="text-left">{{ index + 1 }}</th>
                                                            <td class="text-left">{{ e.first_name }}</td>
                                                            <td class="text-left">{{ e.last_name }}</td>
                                                            <td v-if="singleEmployeeObject(e.employee_id)"
                                                                class="text-left">
                                                                <span v-if="singleEmployeeObject(e.employee_id).bank_name">
                                                                    {{ singleEmployeeObject(e.employee_id).bank_name }}
                                                                </span>
                                                                <span v-else>
                                                                    <i>Bank details missing</i>
                                                                </span>
                                                            </td>
                                                            <td v-else class="text-left">
                                                                <i>Employee not found</i>
                                                            </td>
                                                            <td v-if="singleEmployeeObject(e.employee_id)"
                                                                class="text-left">
                                                                <span
                                                                    v-if="singleEmployeeObject(e.employee_id).account_number">
                                                                    {{ singleEmployeeObject(e.employee_id).account_number }}
                                                                </span>
                                                                <span v-else>
                                                                    <i>Bank details missing</i>
                                                                </span>
                                                            </td>
                                                            <td v-else class="text-left">
                                                                <i>Employee not found</i>
                                                            </td>
                                                            <td class="text-left">
                                                                {{ e.currency }}{{
                                                                    (e.amount) | number_with_commas }}
                                                            </td>
                                                            <td class="text-left">
                                                                {{ e.narration }}
                                                            </td>
                                                            <td class="text-center"
                                                                v-if="singleEmployeeObject(e.employee_id)">
                                                                <span
                                                                    v-if="singleEmployeeObject(e.employee_id).account_status == 1"
                                                                    class="badge badge-success">VERIFIED</span>
                                                                <span v-else class="badge badge-danger">UNVERIFIED</span>
                                                            </td>
                                                            <td class="text-center" v-else>
                                                                <span class="badge badge-danger">UNVERIFIED</span>
                                                            </td>
                                                            <td class="text-center" v-if="e.payment_status == 0">
                                                                <span class="badge badge-warning">UNPAID</span>
                                                            </td>
                                                            <td class="text-center" v-else-if="e.payment_status == 1">
                                                                <span class="badge badge-primary">PENDING</span>
                                                            </td>
                                                            <td class="text-center" v-else-if="e.payment_status == 2">
                                                                <span class="badge badge-success">SUCCESSFUL</span>
                                                            </td>
                                                            <td class="text-center" v-else>
                                                                <span class="badge badge-danger">FAILED</span>
                                                            </td>
                                                            <td class="text-left" v-if="e.status_message">
                                                                <span>{{ e.status_message }}</span>
                                                            </td>
                                                            <td class="text-left" v-else-if="!nameIsValid(e)">
                                                                <i>Account and employee names don't match</i>
                                                            </td>
                                                            <td class="text-left" v-else>
                                                                <i>No status message</i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <!-- Footer Start -->
            <div class="flex-grow-1"></div>
            <!-- Footer end -->
            <!-- DOCUMENT TO PRINT -->
            <div v-if="currentPayRunId && currentPayCalendarId" id="printPayrunSummaryAlt"
                class="d-none d-print-block card my-3" style="border-radius: 10px;">
                <div class="card-header px-3" style="background: #fff;">
                    <v-row>
                        <v-col cols="12" md="4"></v-col>
                        <v-col cols="12" md="4" class="text-left text-md-center mb-4">
                            <img :src="authCompanyData.logo
                                ? host_url + authCompanyData.logo
                                : '/img/kylian-logo-2.png'
                                " alt="" class="w-50" style="height: inherit;" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <h4>Payrun breakdown</h4>
                        </v-col>
                        <v-col cols="12" md="9">
                            <h6 class="mt-2 mb-3">
                                <span style="font-weight: bold;">Start Date:</span>
                                {{ currentPayRun.from_date }}.
                                <span style="font-weight: bold;">End Date:</span>
                                {{ currentPayRun.to_date }}.
                                <span style="font-weight: bold;">Payment Date:</span>
                                {{ currentPayRun.payment_date }}.
                            </h6>
                            <h6 v-if="trueEmployersFlexibleStatutoryDeductions(
                                employeesInPayCalendar(
                                    currentPayCalendar ? currentPayCalendar.id : null
                                )[0].employee_id
                            ).length
                                ">
                                <div class="mb-2" v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                    employeesInPayCalendar(
                                        currentPayCalendar ? currentPayCalendar.id : null
                                    )[0].employee_id
                                )" :key="'statutory-summary-' + deduction.id">
                                    <p style="font-weight: bold;">
                                        Employer's Total {{ deduction.deduction_name }} Contribution: ₦{{
                                            (deduction.deduction_name.toLowerCase() === "pension" ? allCompanyPensionDeductions
                                                : allCompanyNHISDeductions) | number_with_commas
                                        }}.
                                    </p>
                                    <p style="font-weight: bold;">
                                        Employee's Total {{ deduction.deduction_name }} Contribution: ₦{{
                                            (deduction.deduction_name.toLowerCase() === "pension" ? allTotalPension :
                                                allTotalNHIS) | number_with_commas }}.
                                    </p>
                                    <p style="font-weight: bold;">
                                        Total {{ deduction.deduction_name }} Contribution: ₦{{
                                            (deduction.deduction_name.toLowerCase() === "pension" ? parseFloat(
                                                allCompanyPensionDeductions + allTotalPension) :
                                                parseFloat(allCompanyNHISDeductions + allTotalNHIS)) | number_with_commas }}.
                                    </p>
                                </div>
                            </h6>
                        </v-col>
                        <v-col cols="12" md="3">
                            <h3 class="text-left text-md-right">
                                {{ authCompanyData.company_name }}
                            </h3>
                        </v-col>
                    </v-row>
                </div>
                <div class="card-body px-1 py-1 pt-0 pr-0">
                    <div :style="$vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                        ? '/*height: 30vh;*/'
                        : '/*height: 30vh;*/'
                        " style="border-radius: 10px; padding-top: 0; padding-bottom: 0;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" style="border-top: none;">S/N</th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Employee
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Department
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Pay Basic
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Total Allowances
                                    </th>
                                    <th v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                        employeesInPayCalendar(
                                            currentPayCalendar ? currentPayCalendar.id : null
                                        )[0].employee_id
                                    )" :key="'company-breakdown-' + deduction.id" class="text-left"
                                        style="border-top: none;" scope="col">
                                        Employer's {{ deduction.deduction_name }} Contribution
                                    </th>
                                    <th v-for="deduction in trueEmployeesFlexibleStatutoryDeductions(
                                        employeesInPayCalendar(
                                            currentPayCalendar ? currentPayCalendar.id : null
                                        )[0].employee_id
                                    )" :key="'breakdown-' + deduction.id" class="text-left" style="border-top: none;"
                                        scope="col">
                                        Employee's {{ deduction.deduction_name }} Contribution
                                    </th>
                                    <th v-if="!isFlexiblePayroll" class="text-left" style="border-top: none;" scope="col">
                                        Total Statutory Deductions
                                    </th>
                                    <th v-if="isFlexiblePayroll" class="text-left" style="border-top: none;" scope="col">
                                        Total Deductions
                                    </th>
                                    <th v-else class="text-left" style="border-top: none;" scope="col">
                                        Total Non-Statutory Deductions
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Net Pay
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(e, index) in employeesInPayCalendar(
                                    currentPayCalendar ? currentPayCalendar.id : null
                                )" :key="'pay-calendar-employee-2-' + e.id">
                                    <th class="text-left">{{ index + 1 }}</th>
                                    <td class="text-left">
                                        {{ e.employee_fristname + " " + e.employee_lastname }}
                                    </td>
                                    <td class="text-left">{{ e.department_name }}</td>
                                    <td class="text-left">
                                        ₦{{ ((currentGradeLevel(e.employee_id) ? currentGradeLevel(e.employee_id).salary :
                                            0) || 0) | number_with_commas }}
                                    </td>
                                    <td class="text-left">
                                        ₦{{ totalEmployeesFlexibleAllowances(e.employee_id) | number_with_commas }}
                                    </td>
                                    <td v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                        e.employee_id
                                    )" :key="'breakdown-3-' + deduction.id" class="text-left">
                                        ₦{{ parseFloat(deduction.deduction_name.toLowerCase() === "pension" ?
                                            getCompanyPensionForEmployee(e.employee_id) :
                                            getCompanyNHISForEmployee(e.employee_id)) | number_with_commas }}
                                    </td>
                                    <td v-for="deduction in trueEmployeesFlexibleStatutoryDeductions(
                                        e.employee_id
                                    )" :key="'breakdown-2-' + deduction.id" class="text-left">
                                        ₦{{ parseFloat(deduction.deduction_amount) | number_with_commas }}
                                    </td>
                                    <td v-if="!isFlexiblePayroll" class="text-left">
                                        ₦{{ totalTrueEmployeesFlexibleStatutoryDeductions(e.employee_id) |
                                            number_with_commas }}
                                    </td>
                                    <td class="text-left">
                                        ₦{{ totalTrueEmployeesFlexibleDeductions(e.employee_id) | number_with_commas }}
                                    </td>
                                    <td class="text-left">
                                        ₦{{ finalTaxEmployeeNetPay((currentGradeLevel(e.employee_id) ?
                                            currentGradeLevel(e.employee_id).salary : 0) || 0, e.employee_id) |
                                            number_with_commas }}
                                    </td>
                                </tr>
                                <tr style="border-top: 1px solid #ddd;">
                                    <th class="text-left"></th>
                                    <th class="text-left" style="border-top: none;" scope="col"></th>
                                    <th class="text-left" style="border-top: none;" scope="col"></th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Pay Basic
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Total Allowances
                                    </th>
                                    <th v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                        employeesInPayCalendar(
                                            currentPayCalendar ? currentPayCalendar.id : null
                                        )[0].employee_id
                                    )" :key="'company-breakdown-' + deduction.id" class="text-left"
                                        style="border-top: none;" scope="col">
                                        Employer's {{ deduction.deduction_name }} Contribution
                                    </th>
                                    <th v-for="deduction in trueEmployeesFlexibleStatutoryDeductions(
                                        employeesInPayCalendar(
                                            currentPayCalendar ? currentPayCalendar.id : null
                                        )[0].employee_id
                                    )" :key="'breakdown-' + deduction.id" class="text-left" style="border-top: none;"
                                        scope="col">
                                        Employee's {{ deduction.deduction_name }} Contribution
                                    </th>
                                    <th v-if="!isFlexiblePayroll" class="text-left" style="border-top: none;" scope="col">
                                        Total Statutory Deductions
                                    </th>
                                    <th v-if="isFlexiblePayroll" class="text-left" style="border-top: none;" scope="col">
                                        Total Deductions
                                    </th>
                                    <th v-else class="text-left" style="border-top: none;" scope="col">
                                        Total Non-Statutory Deductions
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Net Pay
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-left"></th>
                                    <th class="text-left"></th>
                                    <th class="text-left">TOTAL</th>
                                    <th class="text-left">
                                        ₦{{ allPayBasic | number_with_commas }}
                                    </th>
                                    <th class="text-left">
                                        ₦{{ allTotalAllowances | number_with_commas }}
                                    </th>
                                    <th v-for="deduction in trueEmployersFlexibleStatutoryDeductions(
                                        employeesInPayCalendar(
                                            currentPayCalendar ? currentPayCalendar.id : null
                                        )[0].employee_id
                                    )" :key="'breakdown-3-' + deduction.id" class="text-left">
                                        <span v-if="deduction.deduction_name.toLowerCase() === 'pension'
                                            ">
                                            ₦{{ allCompanyPensionDeductions | number_with_commas }}
                                        </span>
                                        <span v-if="deduction.deduction_name.toLowerCase() === 'nhis'">₦{{
                                            allCompanyNHISDeductions | number_with_commas }}</span>
                                    </th>
                                    <th v-for="deduction in trueEmployeesFlexibleStatutoryDeductions(
                                        employeesInPayCalendar(
                                            currentPayCalendar ? currentPayCalendar.id : null
                                        )[0].employee_id
                                    )" :key="'breakdown-2-' + deduction.id" class="text-left">
                                        <span v-if="deduction.deduction_name.toLowerCase() === 'paye'">₦{{ allTotalPAYE |
                                            number_with_commas }}</span>
                                        <span v-if="deduction.deduction_name.toLowerCase() === 'pension'
                                            ">
                                            ₦{{ allTotalPension | number_with_commas }}
                                        </span>
                                        <span v-if="deduction.deduction_name.toLowerCase() === 'nhis'">₦{{ allTotalNHIS |
                                            number_with_commas }}</span>
                                        <span v-if="deduction.deduction_name.toLowerCase() === 'nhf'">₦{{ allTotalNHF |
                                            number_with_commas }}</span>
                                        <span v-if="deduction.deduction_name.toLowerCase() === 'nsitf'">₦{{ allTotalNSITF |
                                            number_with_commas }}</span>
                                    </th>
                                    <th v-if="!isFlexiblePayroll" class="text-left">
                                        ₦{{ allTotalStatutoryDeduction | number_with_commas }}
                                    </th>
                                    <th class="text-left">
                                        ₦{{ allTotalNonStatutoryDeduction | number_with_commas }}
                                    </th>
                                    <th class="text-left">
                                        ₦{{ allTotalNetPay | number_with_commas }}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- DOCUMENT TO PRINT -->
            <!-- DOCUMENT TO PRINT -->
            <div id="printCompanyTransaction" class="d-none d-print-block card my-3" style="border-radius: 10px;">
                <div class="card-header px-3" style="background: #fff;">
                    <v-row>
                        <v-col cols="12" md="4"></v-col>
                        <v-col cols="12" md="4" class="text-left text-md-center mb-4">
                            <img :src="authCompanyData.logo
                                ? host_url + authCompanyData.logo
                                : '/img/kylian-logo-2.png'
                                " alt="" class="w-50" style="height: inherit;" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <h4>Company Transactions</h4>
                        </v-col>
                        <v-col cols="12" md="9">
                            <h6 class="mt-2 mb-3">
                                <span style="font-weight: bold;">As at: {{ dateNow }}</span>
                            </h6>
                        </v-col>
                        <v-col cols="12" md="3">
                            <h3 class="text-left text-md-right">
                                {{ authCompanyData.company_name }}
                            </h3>
                        </v-col>
                    </v-row>
                </div>
                <div class="card-body px-1 py-1 pt-0 pr-0">
                    <div :style="$vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                        ? '/*height: 30vh;*/'
                        : '/*height: 30vh;*/'
                        " style="border-radius: 10px; padding-top: 0; padding-bottom: 0;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" style="border-top: none;">S/N</th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Type
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Amount
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Currency
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Balance Before
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Balance After
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Date
                                    </th>

                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Remarks
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Sent Currency
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Rate Used
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Sent Amount
                                    </th>
                                    <th class="text-left" style="border-top: none;" scope="col">
                                        Statement Type
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(e, index) in companyTransactionData" :key="'transaction' + index">
                                    <th class="text-left">{{ index + 1 }}</th>
                                    <td class="text-left">{{ e.type }}</td>
                                    <td class="text-left">₦{{ e.amount }}</td>
                                    <td class="text-left">{{ e.currency }}</td>
                                    <td class="text-left">₦{{ e.balance_before }}</td>
                                    <td class="text-left">₦{{ e.balance_after }}</td>
                                    <td class="text-left">
                                        {{ e.date }}
                                    </td>
                                    <td class="text-left">{{ e.remarks }}</td>

                                    <td class="text-left">
                                        {{ e.sent_currency }}
                                    </td>
                                    <td class="text-left">
                                        {{ e.rate_used }}
                                    </td>
                                    <td class="text-left">₦{{ e.sent_amount }}</td>
                                    <td class="text-left">
                                        {{ e.statement_type }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-left">
                            <h4 class="pt-5">
                                Signature: ____________________
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <!-- DOCUMENT TO PRINT -->
        </div>
    </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
// import currencies from "@/plugins/currencies";
// import html2pdf from 'html2pdf.js'
import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import CompanyTransactions from "../CompanyTransactions.vue";

export default {
    components: { CompanyTransactions },
    data() {
        return {
            attendanceRecords: [],
            paymentSuspended: false,
            companyTransactionsSearch: "",
            fetchingTransaction: false,
            transactionFetched: false,
            batchIdExists: false,
            batchId: null,
            payrollPaymentUsers: [],
            fetchingEmployeesForPayment: false,
            employeesDataInCalendar: [],
            allChecked: false,
            employeesCheckedForPayment: [],
            FLBalanceInterval: null,
            initialPaymentStatusInterval: null,
            paymentStatusInterval: null,
            subaccountAvailableBalance: 0,
            subaccountLedgerBalance: 0,
            excelSheetName: "Payrun Breakdown",
            companyTransactionExcelSheetName: "Company Transactions",
            currentPayCalendarId: null,
            allEmployees: [],
            employee_name: "",
            steps: 1,
            companyTransactionData: [],
            steps2: 1,
            payrunView: 1,
            pay_salaries_text: "Pay Salaries",
            pay_salaries_loading: false,
            add_payrun_text: "Next",
            add_payrun_submit: false,
            approve_payrun_text: "Approve",
            approve_payrun_submit: false,
            disapprove_payrun_text: "Undo Approval",
            disapprove_payrun_submit: false,
            payrunForm: {
                from_date: "",
                to_date: "",
                payment_date: "",
            },
            editPayrunForm: {
                payrun_id: "",
                from_date: "",
                to_date: "",
                payment_date: "",
            },
            menu: false,
            menu2: false,
            menu3: false,
            menu4: false,
            currentPayRunId: null,
            dateNow: "",
            flexibleAllowanceForm: {
                allowance_name: "",
                allowance_amount: "",
                note: "",
            },
            flexibleDeductionForm: {
                deduction_name: "",
                deduction_amount: "",
                note: "",
            },
            add_flexible_allowance_submit: false,
            add_flexible_deduction_submit: false,
            allFlexibleAllowances: [
                "Travel Allowance",
                "Accomodation Allowance",
                "City Compensatory Allowance",
                "Pay Adjustment",
                "Overtime",
                "Variable Pay",
                "Bonus",
                "Holiday Pay",
                "Service Charge",
                "Christmas Bonus",
                "Punctuality Bonus",
                "Loan Request",
                "CEO's Gift",
                "Bereavement",
                "Wardrobe",
                "Other (Specify in note)",
            ],
            allFlexibleDeductions: ["Provident Fund", "Loan", "Advance Pay", "Advance", "Miscelleneous Deduction", "Give as you earn", "Lateness Penalty", "AWOL Penalty", "NHF Contribution", "Asset Damages", "Other (Specify in note)"],
        };
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
        },
        menu2(val) {
            val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
        },
        menu3(val) {
            val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
        },
        menu4(val) {
            val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
        },
        checkedEmployeeIds(newVal) {
            const _this = this;
            if (newVal.length === _this.verifiedEmployeesToBePaid.length) {
                _this.allChecked = true;
            } else {
                _this.allChecked = false;
            }
        },
        // employeesCheckedForPayment() {
        //   if (this.employeesInPayCalendar(this.currentPayCalendar ? this.currentPayCalendar.id : 0).length > 0) {
        //     this.allChecked =  this.employeesInPayCalendar(this.currentPayCalendar ? this.currentPayCalendar.id : 0).length === this.employeesCheckedForPayment.length;
        //   } else {
        //     this.allChecked = false;
        //   }
        // }
    },
    computed: {
        ...mapState(["minimumWage", "companyTransactions"]),
        ...mapGetters([
            "validEmployeeId",
            "userType",
            "authCompanyData",
            "authCompanyEmployees",
            "authEmployeeDataForCompany",
            "true_employees",
            "authPayCalendars",
            "authEmployeePayCalendars",
            "authFlexibleAllowances",
            "authFlexibleDeductions",
            "authCompanyPayRun",
            "authCompanyDepartments",
            "authDesignations",
            "authPayrollConfig",
            "authCompanyLoans",
            "authCompanyPayGradeHistories",
            "authCompanyPayGrades",
            "authCompanyGradeLevels",
            "authCompanyEmoluments",
            "authStatutoryDeductions",
            "authCompanyStatutoryDeductions",
            "allBanks",
            "authCompanySubaccount",
            "authCompanyTransactions",
            "authCompanyHolidays",
            "authCompanyApprovedLeave",
            "authCompanyAllCheckin",
            "authCheckinAllTime",
            "authCompanyAttendanceDeductionSettings"
        ]),
        attendance() {
            return this.authCompanyAllCheckin.attendance;
        },
        subscribedToAttendance() {
            return this.check_all_services("Attendance");
        },
          datesBetweenPayrunStartAndEnd() {
              const _this = this;
              const payrunStartDate = new Date(_this.currentPayRun.from_date || "1");
              const payrunEndDate = new Date(_this.currentPayRun.to_date || "1").addHours(1).addMinutes(1);
              const payrunDates = _this.getDatesBetweenDates(payrunStartDate, payrunEndDate);
              let workDates = payrunDates.filter((date) => {
                  const weekDay = _this.getDayOfWeek(date.getDay());
                  return _this.mixinCompanyWorkDays.indexOf(weekDay) !== -1;
              });
              workDates = workDates.map((workDate) => workDate.toLocaleDateString());

              let holidayDates = [];
              _this.authCompanyHolidays.forEach((holiday) => {
                  const holidayStartDate = new Date(holiday.start || "1");
                  const holidayEndDate = new Date(holiday.end || "1").addHours(1).addMinutes(1);
                  holidayDates = [...holidayDates, ..._this.getDatesBetweenDates(holidayStartDate, holidayEndDate)];
              });
              holidayDates = holidayDates.map((holidayDate) => holidayDate.toLocaleDateString());

              const holidayFreeWorkDates = workDates.filter((workDate) => {
                  return holidayDates.indexOf(workDate) === -1;
              });

              return holidayFreeWorkDates;
          },
        datesBetweenPayrunStartAndPaymentDate() {
            const _this = this;
            let payrunStartDate = '';
            let payrunPaymentDate = '';
            payrunStartDate = new Date(_this.payrunForm.from_date || "1");
            payrunPaymentDate = new Date(_this.payrunForm.payment_date || "1").addHours(1).addMinutes(1);
            const payrunDates = _this.getDatesBetweenDates(payrunStartDate, payrunPaymentDate);
            let workDates = payrunDates.filter((date) => {
                const weekDay = _this.getDayOfWeek(date.getDay());
                return _this.mixinCompanyWorkDays.indexOf(weekDay) !== -1;
            });
            workDates = workDates.map((workDate) => workDate.toLocaleDateString());

            let holidayDates = [];
            _this.authCompanyHolidays.forEach((holiday) => {
                const holidayStartDate = new Date(holiday.start || "1");
                const holidayEndDate = new Date(holiday.end || "1").addHours(1).addMinutes(1);
                holidayDates = [...holidayDates, ..._this.getDatesBetweenDates(holidayStartDate, holidayEndDate)];
            });
            holidayDates = holidayDates.map((holidayDate) => holidayDate.toLocaleDateString());

            const holidayFreeWorkDates = workDates.filter((workDate) => {
                return holidayDates.indexOf(workDate) === -1;
            });

            return holidayFreeWorkDates;
        },
        usersTransactionIds() {
            const _this = this;
            return _this.payrollPaymentUsers
                .map((user) => {
                    return {
                        transaction_id: user.transaction_id,
                    };
                })
                .filter((user) => user.transaction_id);
        },

        // someoneHasBeenPaid() {
        //   return this.payrollPaymentUsers.filter(e => e == 2).length >= 1;
        // },
        checkedEmployeeIds() {
            const _this = this,
                checkedEmployees = _this.payrollPaymentUsers.filter((e) => e.checked);
            return checkedEmployees.map((e) => e.employee_id);
        },
        employeesToBePaid() {
            const _this = this;
            let paymentList = [],
                payCalendarEmployees = _this.employeesDataInCalendar;
            // let verifiedPayCalendarEmployees = payCalendarEmployees.filter(
            //   (employee) => {
            //     const employeeObject = _this.singleEmployeeObject(
            //       employee.employee_id
            //     );
            //     if (employeeObject) {
            //       return employeeObject.account_status == 1; //&& employeeObject.bank_name && employeeObject.account_number
            //       };
            //   });
            // console.log(verifiedPayCalendarEmployees.length)
            // Add all pay calendar employees for payment
            // Can be tweaked to add only employees with verified accounts
            paymentList = payCalendarEmployees.map((employee) => {
                const date = new Date();
                const newEmployeeObject = _this.singleEmployeeObject(employee.employee_id);
                const employeePaymentAmount = _this.finalTaxEmployeeNetPay((_this.currentGradeLevel(employee.employee_id) ? _this.currentGradeLevel(employee.employee_id).salary : 0) || 0, employee.employee_id);
                const reference = `BULK_TRANSFERS_${_this.currentPayCalendarId}_${_this.currentPayRunId}_${employee.employee_id}_${date.getTime().toString().slice(8)}`;
                const paymentDate = _this.currentPayRun ? new Date(_this.currentPayRun.payment_date) : null;
                const narration = paymentDate ? `Salary for ${_this.getMonthWithIndex(paymentDate.getMonth())} ${paymentDate.getFullYear()}` : `Salary payment`;
                return {
                    employee_id: newEmployeeObject.id,
                    bank_code: newEmployeeObject.bank_code,
                    bank_name: newEmployeeObject.bank_name,
                    is_verified: newEmployeeObject.account_status,
                    account_number: newEmployeeObject.account_number,
                    amount: employeePaymentAmount,
                    currency: "NGN",
                    narration,
                    reference,
                    debit_subaccount: _this.authCompanySubaccount.account_reference,
                    first_name: newEmployeeObject.employee_fristname,
                    last_name: newEmployeeObject.employee_lastname,
                    email: newEmployeeObject.employee_email,
                    payrun_id: _this.currentPayRunId,
                    mobile_number: newEmployeeObject.employee_phone1 || "",
                    recipient_address: newEmployeeObject.employee_address || "",
                };
            });
            return paymentList;
        },
        verifiedEmployeesToBePaid() {
            const _this = this;
            return _this.payrollPaymentUsers.filter((e) => e.is_verified == 1 && e.payment_status != 1 && e.payment_status != 2 && _this.nameIsValid(e));
        },
        trueEmployeesToBePaid() {
            const _this = this;
            return _this.verifiedEmployeesToBePaid.filter((e) => _this.checkedEmployeeIds.indexOf(e.employee_id) !== -1);
        },
        trueAmountToBePaid() {
            const _this = this;
            let value = 0;
            _this.trueEmployeesToBePaid.forEach((e) => {
                value += parseFloat(e.amount);
            });
            return value;
        },
        companyHasSubaccount() {
            return this.authCompanySubaccount !== null;
        },
        json_data: function () {
            return this.payrunBreakdown;
        },
        json_fields: function () {
            return {
                "S/N": "count",
                Employee: "employee",
                Department: "department",
                "Pay Basic (₦)": "payBasic",
                "Total Allowances (₦)": "totalAllowances",
                "Total Statutory Deductions (₦)": "totalStatutoryDeductions",
                "Total Non-Statutory Deductions (₦)": "totalNonStatutoryDeductions",
                "Net Pay (₦)": "netPay",
                Bank: "bankName",
                "Account Number": "accountNumber",
                "Bank Code": "bankCode",
            };
        },
        payrunBreakdown() {
            let _this = this,
                result = [];
            let employees = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null);
            employees.forEach((employee, index) => {
                let payrunBreakdown = {};
                let employeeObject = _this.singleEmployeeObject(employee.employee_id);
                let employeeBankInfo = null;
                if (employeeObject) {
                    employeeBankInfo = _this.getBankByName(employeeObject.bank_name);
                }
                payrunBreakdown.count = index + 1;
                payrunBreakdown.employee = employee.employee_fristname + " " + employee.employee_lastname;
                payrunBreakdown.department = employee.department_name;
                payrunBreakdown.payBasic = (_this.currentGradeLevel(employee.employee_id) ? _this.currentGradeLevel(employee.employee_id).salary : 0) || 0;
                payrunBreakdown.totalAllowances = _this.totalEmployeesFlexibleAllowances(employee.employee_id);
                payrunBreakdown.totalStatutoryDeductions = _this.isFlexiblePayroll === false ? _this.totalTrueEmployeesFlexibleStatutoryDeductions(employee.employee_id) : 0;
                payrunBreakdown.totalNonStatutoryDeductions = _this.totalTrueEmployeesFlexibleDeductions(employee.employee_id);
                payrunBreakdown.netPay = _this.finalTaxEmployeeNetPay((_this.currentGradeLevel(employee.employee_id) ? _this.currentGradeLevel(employee.employee_id).salary : 0) || 0, employee.employee_id);

                payrunBreakdown.bankCode = employeeBankInfo ? (employeeBankInfo.bank_code ? `'${employeeBankInfo.bank_code}` : "Bank not specified") : "Bank not specified";
                payrunBreakdown.bankName = employeeBankInfo ? (employeeBankInfo.bank_name ? employeeBankInfo.bank_name : "Bank not specified") : "Bank not specified";
                payrunBreakdown.accountNumber = employeeObject ? (employeeObject.account_number ? `'${employeeObject.account_number}` : "Account number not added") : "Account number not added";

                result = [...result, payrunBreakdown];
            });
            return result;
        },
        employeesInPayCalendarNames() {
            let _this = this,
                result = [];
            result = _this.employeesInPayCalendar(_this.currentPayCalendarId).map((e) => {
                return e.employee_fristname + " " + e.employee_lastname;
            });
            return result;
        },
        isFlexiblePayroll() {
            let _this = this,
                truth = true,
                employee_id = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null)[0].employee_id;
            if (_this.employeesFlexibleDeductions(employee_id).length) {
                _this.employeesFlexibleDeductions(employee_id).forEach((deduction) => {
                    if (
                        deduction.deduction_name.toLowerCase() == "nhis" ||
                        deduction.deduction_name.toLowerCase() == "nhf" ||
                        deduction.deduction_name.toLowerCase() == "pension" ||
                        deduction.deduction_name.toLowerCase() == "paye" ||
                        deduction.deduction_name.toLowerCase() == "nsitf"
                    ) {
                        truth = false;
                    }
                });
            }
            return truth;
        },
        allUnapprovedCompanyPensionDeductions() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += _this.getEmployerPensionDeduction(_this.getPensionDeduction((_this.currentGradeLevel(detail.employee_id) ? _this.currentGradeLevel(detail.employee_id).salary : 0) || 0));
            });
            return value;
        },
        allUnapprovedCompanyNHISDeductions() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += _this.getEmployerNHISDeduction(_this.getNHISDeduction((_this.currentGradeLevel(detail.employee_id) ? _this.currentGradeLevel(detail.employee_id).salary : 0) || 0));
            });
            return value;
        },
        allCompanyPensionDeductions() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                pension_deductions = [],
                value = 0;
            employeeDetails.forEach((detail) => {
                pension_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(detail.employee_id).filter((deduction) => {
                    return deduction.deduction_name.toLowerCase() == "pension";
                });
                pension_deductions.forEach((d) => {
                    value += _this.getEmployerPensionDeduction(parseFloat(d.deduction_amount));
                });
            });
            return value;
        },
        allCompanyNHISDeductions() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                nhis_deductions = [],
                value = 0;
            employeeDetails.forEach((detail) => {
                nhis_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(detail.employee_id).filter((deduction) => {
                    return deduction.deduction_name.toLowerCase() == "nhis";
                });
                nhis_deductions.forEach((d) => {
                    value += _this.getEmployerNHISDeduction(parseFloat(d.deduction_amount));
                });
            });
            return value;
        },
        allPayBasic() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += (_this.currentGradeLevel(detail.employee_id) ? parseFloat(_this.currentGradeLevel(detail.employee_id).salary) : 0) || 0;
            });
            return value;
        },
        allTotalAllowances() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.totalEmployeesFlexibleAllowances(detail.employee_id));
            });
            return value;
        },
        allUnapprovedTotalPAYE() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.getPAYEDeduction((_this.currentGradeLevel(detail.employee_id) ? _this.currentGradeLevel(detail.employee_id).salary : 0) || 0));
            });
            return value;
        },
        allUnapprovedTotalPension() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.getPensionDeduction((_this.currentGradeLevel(detail.employee_id) ? _this.currentGradeLevel(detail.employee_id).salary : 0) || 0));
            });
            return value;
        },
        allUnapprovedTotalNHIS() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.getNHISDeduction((_this.currentGradeLevel(detail.employee_id) ? _this.currentGradeLevel(detail.employee_id).salary : 0) || 0));
            });
            return value;
        },
        allUnapprovedTotalNHF() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.getNHFDeduction((_this.currentGradeLevel(detail.employee_id) ? _this.currentGradeLevel(detail.employee_id).salary : 0) || 0));
            });
            return value;
        },
        allUnapprovedTotalNSITF() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.getNSITFDeduction((_this.currentGradeLevel(detail.employee_id) ? _this.currentGradeLevel(detail.employee_id).salary : 0) || 0));
            });
            return value;
        },
        allTotalPAYE() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                paye_deductions = [],
                value = 0;
            employeeDetails.forEach((detail) => {
                paye_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(detail.employee_id).filter((deduction) => {
                    return deduction.deduction_name.toLowerCase() == "paye";
                });
                paye_deductions.forEach((d) => {
                    value += parseFloat(d.deduction_amount);
                });
            });
            return value;
        },
        allTotalPension() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                pension_deductions = [],
                value = 0;
            employeeDetails.forEach((detail) => {
                pension_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(detail.employee_id).filter((deduction) => {
                    return deduction.deduction_name.toLowerCase() == "pension";
                });
                pension_deductions.forEach((d) => {
                    value += parseFloat(d.deduction_amount);
                });
            });
            return value;
        },
        allTotalNHIS() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                nhis_deductions = [],
                value = 0;
            employeeDetails.forEach((detail) => {
                nhis_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(detail.employee_id).filter((deduction) => {
                    return deduction.deduction_name.toLowerCase() == "nhis";
                });
                nhis_deductions.forEach((d) => {
                    value += parseFloat(d.deduction_amount);
                });
            });
            return value;
        },
        allTotalNHF() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                nhf_deductions = [],
                value = 0;
            employeeDetails.forEach((detail) => {
                nhf_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(detail.employee_id).filter((deduction) => {
                    return deduction.deduction_name.toLowerCase() == "nhf";
                });
                nhf_deductions.forEach((d) => {
                    value += parseFloat(d.deduction_amount);
                });
            });
            return value;
        },
        allTotalNSITF() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                nsitf_deductions = [],
                value = 0;
            employeeDetails.forEach((detail) => {
                nsitf_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(detail.employee_id).filter((deduction) => {
                    return deduction.deduction_name.toLowerCase() == "nsitf";
                });
                nsitf_deductions.forEach((d) => {
                    value += parseFloat(d.deduction_amount);
                });
            });
            return value;
        },
        allUnapprovedTotalStatutoryDeduction() {
            let _this = this,
                value = 0,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null);
            employeeDetails.forEach((detail) => {
                value += parseFloat(
                    _this.taxTotalEmployeeDeductions((_this.currentGradeLevel(detail.employee_id) ? _this.currentGradeLevel(detail.employee_id).salary : 0) || 0, detail.employee_id) -
                    _this.totalEmployeesFlexibleDeductions(detail.employee_id)
                );
            });
            return value;
        },
        allUnapprovedTotalNonStatutoryDeduction() {
            let _this = this,
                value = 0,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null);
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.totalEmployeesFlexibleDeductions(detail.employee_id));
            });
            return value;
        },
        allTotalStatutoryDeduction() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.totalTrueEmployeesFlexibleStatutoryDeductions(detail.employee_id));
            });
            return value;
        },
        allTotalNonStatutoryDeduction() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.totalTrueEmployeesFlexibleDeductions(detail.employee_id));
            });
            return value;
        },
        allUnapprovedTotalNetPay() {
            let _this = this,
                value = 0;
            value = _this.allPayBasic + _this.allTotalAllowances - _this.allUnapprovedTotalStatutoryDeduction - _this.allUnapprovedTotalNonStatutoryDeduction;
            return value;
        },
        allTotalNetPay() {
            let _this = this,
                employeeDetails = _this.employeesInPayCalendar(_this.currentPayCalendar ? _this.currentPayCalendar.id : null),
                value = 0;
            employeeDetails.forEach((detail) => {
                value += parseFloat(_this.finalTaxEmployeeNetPay((_this.currentGradeLevel(detail.employee_id) ? parseFloat(_this.currentGradeLevel(detail.employee_id).salary) : 0) || 0, detail.employee_id));
            });
            return value;
        },

        basicSalaryPercentage() {
            let _this = this,
                result,
                value = 0;
            result = _this.authCompanyEmoluments.filter((emolument) => emolument.emolument_name.toLowerCase() == "basic");
            if (result.length) {
                result = result[0];
                value = parseFloat(result.emolument_percentage);
            }
            return value;
        },
        housingSalaryPercentage() {
            let _this = this,
                result,
                value = 0;
            result = _this.authCompanyEmoluments.filter((emolument) => emolument.emolument_name.toLowerCase() == "housing");
            if (result.length) {
                result = result[0];
                value = parseFloat(result.emolument_percentage);
            }
            return value;
        },
        transportSalaryPercentage() {
            let _this = this,
                result,
                value = 0;
            result = _this.authCompanyEmoluments.filter((emolument) => emolument.emolument_name.toLowerCase() == "transport");
            if (result.length) {
                result = result[0];
                value = parseFloat(result.emolument_percentage);
            }
            return value;
        },
        allStatutoryDeductions() {
            let _this = this,
                result = [];
            result = _this.authCompanyStatutoryDeductions.map((d) => {
                let newObj = _this.getStatutoryDeduction(d.statutory_deduction_id);
                if (newObj) {
                    return newObj;
                }
            });
            return result;
        },
        host_url() {
            return ASSETS_URL;
        },
        totalNetPay() {
            let _this = this,
                result = 0;
            _this.employeesInPayCalendar(_this.currentPayCalendar.id).forEach((e) => {
                result += parseFloat(_this.finalTaxEmployeeNetPay((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0, e.employee_id));
            });
            return result;
        },
        companyAllowances() {
            let _this = this,
                results = [];
            if (_this.authPayrollConfig.length >= 1) {
                results = _this.authPayrollConfig.filter((pc) => pc.payment_type.toLowerCase() == "allowance");
                results = results.map((r) => {
                    return r.payment_item;
                });
            }
            return results;
        },
        companyDeductions() {
            let _this = this,
                results = [];
            if (_this.authPayrollConfig.length >= 1) {
                results = _this.authPayrollConfig.filter((pc) => pc.payment_type.toLowerCase() == "deduction");
                results = results.map((r) => {
                    return r.payment_item;
                });
            }
            return results;
        },
        payslipEmployees() {
            let _this = this,
                result = [];
            if (_this.currentPayCalendarId) {
                let employeesInCalendar = _this.employeesInPayCalendar(_this.currentPayCalendarId);
                result = employeesInCalendar.map((e) => {
                    let singleObject = _this.singleEmployeeObject(e.employee_id);
                    let payslipDeductions = [];
                    _this.employeesFlexibleDeductions(e.employee_id).forEach((fd) => {
                        payslipDeductions.splice(payslipDeductions.length - 1, 0, {
                            company_id: _this.authCompanyData.id,
                            employee_id: e.employee_id,
                            payrun_id: _this.currentPayRunId,
                            deduction: fd.deduction_name,
                            amount: fd.deduction_amount,
                        });
                    });

                    _this.allStatutoryDeductions.forEach(d => {
                        if (d.name.toLowerCase() == 'pension') {
                            const actualDeduction = _this.getPensionDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0);
                            payslipDeductions.splice(payslipDeductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: e.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'Pension deduction',
                                amount: actualDeduction
                            });
                        } else if (d.name.toLowerCase() == 'nhf') {
                            const actualDeduction = _this.getNHFDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0);
                            payslipDeductions.splice(payslipDeductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: e.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'NHF deduction',
                                amount: actualDeduction
                            });
                        } else if (d.name.toLowerCase() == 'nhis') {
                            const actualDeduction = _this.getNHISDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0);
                            payslipDeductions.splice(payslipDeductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: e.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'NHIS deduction',
                                amount: actualDeduction
                            });
                        } else if (d.name.toLowerCase() == 'nsitf') {
                            const actualDeduction = _this.getNSITFDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0);
                            payslipDeductions.splice(payslipDeductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: e.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'NSITF deduction',
                                amount: actualDeduction
                            });
                        } else if (d.name.toLowerCase() == 'paye') {
                            const actualDeduction = _this.getPAYEDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0);
                            payslipDeductions.splice(payslipDeductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: e.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'PAYE deduction',
                                amount: actualDeduction
                            });
                        }
                    });


                    let employeeDeductions = payslipDeductions.filter((deduction) => deduction.employee_id == e.employee_id);
                    let employeeDeductionsValue = 0;
                    employeeDeductions.forEach((ED) => {
                        employeeDeductionsValue += parseFloat(ED.amount);
                    });
                    return {
                        company_id: _this.authCompanyData.id,
                        employee_id: e.employee_id,
                        payrun_id: _this.currentPayRunId,
                        employee_name: e.employee_fristname + " " + e.employee_lastname,
                        employee_address: singleObject ? singleObject.employee_address : "",
                        department: e.department_name,
                        designation: singleObject ? singleObject.employee_designation : "",
                        period: _this.currentPayRun.from_date ? _this.currentPayRun.from_date + " - " + _this.currentPayRun.to_date : _this.payrunForm.from_date + " - " + _this.payrunForm.to_date,
                        payment_date: _this.currentPayRun.payment_date ? _this.currentPayRun.payment_date : _this.payrunForm.payment_date,
                        bank_name: singleObject ? singleObject.bank_name : "",
                        account_number: singleObject ? singleObject.account_number : "",
                        payment_method: singleObject ? singleObject.payment_method : "",
                        total_payment: parseFloat(_this.currentGradeLevel(e.employee_id).salary) + (_this.taxTotalEmployeeAllowances(e.employee_id) || 0),
                        total_deduction: employeeDeductionsValue,
                        total_tax: 0,
                        // net_payX: _this.finalTaxEmployeeNetPay(
                        //     (
                        //         (
                        //             _this.currentGradeLevel(e.employee_id)
                        //             ?
                        //             _this.currentGradeLevel(e.employee_id).salary
                        //             :
                        //             0
                        //         )
                        //         ||
                        //         0
                        //     ),
                        //     e.employee_id
                        // ),
                        net_pay: _this.newFinalEmployeeGrossPay((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0, e.employee_id) - employeeDeductionsValue,
                    };
                });
            }
            return result;
        },
        payslipDeductions() {
            let _this = this,
                deductions = [];
            if (_this.payslipEmployees.length >= 1) {
                _this.payslipEmployees.forEach((pe) => {
                    _this.employeesFlexibleDeductions(pe.employee_id).forEach((fd) => {
                        deductions.splice(deductions.length - 1, 0, {
                            company_id: _this.authCompanyData.id,
                            employee_id: pe.employee_id,
                            payrun_id: _this.currentPayRunId,
                            deduction: fd.deduction_name,
                            amount: fd.deduction_amount,
                        });
                    });
                    // const statutoryDeductions = (_this.taxTotalEmployeeDeductions((_this.currentGradeLevel(pe.employee_id) ? _this.currentGradeLevel(pe.employee_id).salary : 0) || 0, pe.employee_id) - _this.totalEmployeesFlexibleDeductions(pe.employee_id));
                    // statutoryDeductions.forEach((fd) => {
                    //     deductions.splice(deductions.length - 1, 0, {
                    //         company_id: _this.authCompanyData.id,
                    //         employee_id: pe.employee_id,
                    //         payrun_id: _this.currentPayRunId,
                    //         deduction: fd.deduction_name,
                    //         amount: fd.deduction_amount,
                    //     });
                    // });
                    _this.allStatutoryDeductions.forEach(d => {
                        if (d.name.toLowerCase() == 'pension') {
                            const actualDeduction = _this.getPensionDeduction((_this.currentGradeLevel(pe.employee_id) ? _this.currentGradeLevel(pe.employee_id).salary : 0) || 0);
                            deductions.splice(deductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: pe.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'Pension deduction',
                                amount: actualDeduction
                            });
                        } else if (d.name.toLowerCase() == 'nhf') {
                            const actualDeduction = _this.getNHFDeduction((_this.currentGradeLevel(pe.employee_id) ? _this.currentGradeLevel(pe.employee_id).salary : 0) || 0);
                            deductions.splice(deductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: pe.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'NHF deduction',
                                amount: actualDeduction
                            });
                        } else if (d.name.toLowerCase() == 'nhis') {
                            const actualDeduction = _this.getNHISDeduction((_this.currentGradeLevel(pe.employee_id) ? _this.currentGradeLevel(pe.employee_id).salary : 0) || 0);
                            deductions.splice(deductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: pe.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'NHIS deduction',
                                amount: actualDeduction
                            });
                        } else if (d.name.toLowerCase() == 'nsitf') {
                            const actualDeduction = _this.getNSITFDeduction((_this.currentGradeLevel(pe.employee_id) ? _this.currentGradeLevel(pe.employee_id).salary : 0) || 0);
                            deductions.splice(deductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: pe.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'NSITF deduction',
                                amount: actualDeduction
                            });
                        } else if (d.name.toLowerCase() == 'paye') {
                            const actualDeduction = _this.getPAYEDeduction((_this.currentGradeLevel(pe.employee_id) ? _this.currentGradeLevel(pe.employee_id).salary : 0) || 0);
                            deductions.splice(deductions.length - 1, 0, {
                                company_id: _this.authCompanyData.id,
                                employee_id: pe.employee_id,
                                payrun_id: _this.currentPayRunId,
                                deduction: 'PAYE deduction',
                                amount: actualDeduction
                            });
                        }
                    });
                });
            }
            return deductions;
        },
        payslipAllowances() {
            let _this = this,
                allowances = [];
            if (_this.payslipEmployees.length >= 1) {
                _this.payslipEmployees.forEach((pe) => {
                    allowances.splice(0, 0, {
                        company_id: _this.authCompanyData.id,
                        employee_id: pe.employee_id,
                        payrun_id: _this.currentPayRunId,
                        allowance: "Pay Basic",
                        amount: (_this.currentGradeLevel(pe.employee_id) ? _this.currentGradeLevel(pe.employee_id).salary : 0) || 0,
                    });
                    _this.employeesFlexibleAllowances(pe.employee_id).forEach((fa) => {
                        allowances.splice(allowances.length - 1, 0, {
                            company_id: _this.authCompanyData.id,
                            employee_id: pe.employee_id,
                            payrun_id: _this.currentPayRunId,
                            allowance: fa.allowance_name,
                            amount: fa.allowance_amount,
                        });
                    });
                });
            }
            return allowances;
        },
        employee_id: function () {
            var t = this;
            if (t.employee_name) {
                if (t.employee_name.toLowerCase() != "all") {
                    return t.true_employees && t.employee_name
                        ? t.true_employees.filter(function (e) {
                            return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
                        })[0].id
                        : null;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        currentEmployee: function () {
            var t = this;
            if (t.employee_name) {
                if (t.employee_name.toLowerCase() != "all") {
                    return t.true_employees && t.employee_name
                        ? t.true_employees.filter(function (e) {
                            return e.id == t.employee_id;
                        })[0]
                        : null;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        currentPayRun() {
            let _this = this,
                result = null;
            result = _this.authCompanyPayRun.filter((pr) => pr.id == _this.currentPayRunId);
            if (result.length >= 1) {
                result = result[0];
            } else {
                result = { approved: null };
            }
            return result;
        },
        currentPayCalendar() {
            let _this = this,
                result = null;
            if (_this.authPayCalendars.length >= 1) {
                result = _this.authPayCalendars.filter((pc) => {
                    return pc.id == _this.currentPayCalendarId;
                })[0];
            }
            return result;
        },
    },
    methods: {
        ...mapActions([
            "fetch_company_transactions",
            "fetch_all_pay_calendar",
            "fetch_all_employees_pay_calendar",
            "fetch_company_subaccount",
            "fetch_all_company_payrun",
            "fetch_all_flexible_allowances",
            "fetch_all_flexible_deductions",
            "fetch_all_payroll_configuration",
            "fetch_all_company_loans",
            "fetch_company_pay_grade_histories",
            "fetch_company_pay_grades",
            "fetch_company_grade_levels",
            "fetch_company_emoluments",
            "fetch_statutory_deductions",
            "fetch_company_statutory_deductions",
            "fetch_company_emoluments",
            "fetch_statutory_deductions",
            "fetch_company_statutory_deductions",
            "fetch_active_company_employees",
            "fetch_all_approved_leave",
            "fetch_all_check_in_all_time",
        ]),
          loadAttendanceRecords(payCalendarId, payrunId) {
              const _this = this;
              const employeesDataInCalendar = _this.employeesInPayCalendar(payCalendarId);
              let attendanceRecords = employeesDataInCalendar.map((e) => e.employee_id);
              attendanceRecords = attendanceRecords.map((employeeId) => {
                  const employeeApprovedLeaves = _this.authCompanyApprovedLeave.filter((leave) => leave.employee_id == employeeId);
                  let employeeLeaveDays = [];
                  employeeApprovedLeaves.forEach((leave) => {
                      const leaveStartDate = new Date(leave.leave_start || "1");
                      const leaveEndDate = new Date(leave.leave_end || "1").addHours(1).addMinutes(1);
                      const employeeLeaveDates = _this.getDatesBetweenDates(leaveStartDate, leaveEndDate);
                      employeeLeaveDays = [...employeeLeaveDays, ...employeeLeaveDates];
                  });

                  employeeLeaveDays = employeeLeaveDays.map(leaveDay => leaveDay.toLocaleDateString());

                  const validAttendanceDays = _this.datesBetweenPayrunStartAndPaymentDate.filter((payrunDate) => {
                      return employeeLeaveDays.indexOf(payrunDate) === -1;
                  });

                  const attendanceRecords = validAttendanceDays.map((day) => {
                      const [match] = _this.authCheckinAllTime.filter((checkin) => {
                          return checkin.employee_id == employeeId && day == new Date(checkin).toLocaleDateString();
                      });
                      if (match) {
                        if (match.status == 'late') {
                          return {
                              day,
                            punch_in_note: match.punch_in_note,
                              status: match.status,
                              active: true,
                          };
                        } else {
                            return {
                                day,
                            punch_in_note: null,
                                status: "absent",
                                active: true,
                            }
                          };
                      } else {
                          return {
                              day,
                            punch_in_note: null,
                              status: "absent",
                              active: true,
                          };
                      }
                  });

                  const finalAttendanceRecords = attendanceRecords.filter((record) => record.status == "late" || record.status == "absent");

                  return {
                      employee_id: employeeId,
                      attendance_records: finalAttendanceRecords,
                  };
              });

              _this.attendanceRecords = attendanceRecords;

              _this.add_attendance_deductions_as_flexible_deductions(attendanceRecords, payrunId);
          },
        nameIsValid(paymentDetails) {
            const _this = this;
            const paymentName = paymentDetails.account_name || "A B";
            const paymentNames = paymentName.split(" ");
            const employeeObject = _this.singleEmployeeObject(paymentDetails.employee_id);
            const employeeFirstName = employeeObject.employee_fristname || "";
            const employeeLastName = employeeObject.employee_lastname || "";
            const employeeMiddleName = employeeObject.employee_middle_name || "";
            const paymentFirstName = paymentNames[0];
            const paymentLastName = paymentNames[1];
            const paymentMiddleName = paymentNames.length >= 3 ? paymentNames[2] : "";

            return (
                // First name is paymentNames[0]
                // Last name is paymentNames[1]
                // Middle name is paymentNames[2]
                // If all names are less than 3
                paymentNames.length < 3
                    ? (paymentFirstName.toLowerCase() === employeeFirstName.toLowerCase() && paymentLastName.toLowerCase() === employeeLastName.toLowerCase()) ||
                    (paymentFirstName.toLowerCase() === employeeLastName.toLowerCase() && paymentLastName.toLowerCase() === employeeFirstName.toLowerCase())
                    : // If all names are not less than 3
                    (paymentFirstName.toLowerCase() === employeeFirstName.toLowerCase() && paymentLastName.toLowerCase() === employeeLastName.toLowerCase() && paymentMiddleName.toLowerCase() === employeeMiddleName.toLowerCase()) ||
                    (paymentFirstName.toLowerCase() === employeeFirstName.toLowerCase() &&
                        paymentLastName.toLowerCase() === employeeMiddleName.toLowerCase() &&
                        paymentMiddleName.toLowerCase() === employeeLastName.toLowerCase()) ||
                    (paymentFirstName.toLowerCase() === employeeLastName.toLowerCase() &&
                        paymentLastName.toLowerCase() === employeeFirstName.toLowerCase() &&
                        paymentMiddleName.toLowerCase() === employeeMiddleName.toLowerCase()) ||
                    (paymentFirstName.toLowerCase() === employeeLastName.toLowerCase() &&
                        paymentLastName.toLowerCase() === employeeMiddleName.toLowerCase() &&
                        paymentMiddleName.toLowerCase() === employeeFirstName.toLowerCase()) ||
                    (paymentFirstName.toLowerCase() === employeeMiddleName.toLowerCase() &&
                        paymentLastName.toLowerCase() === employeeFirstName.toLowerCase() &&
                        paymentMiddleName.toLowerCase() === employeeLastName.toLowerCase()) ||
                    (paymentFirstName.toLowerCase() === employeeMiddleName.toLowerCase() && paymentLastName.toLowerCase() === employeeLastName.toLowerCase() && paymentMiddleName.toLowerCase() === employeeFirstName.toLowerCase())
            );
        },
        storePayrunEmployeesForPayments(payrun_id) {
            const _this = this;
            axios
                .post(`${BASE_URL}storepayrollpaymentusers`, {
                    company_id: _this.authCompanyData.id,
                    payrun_id,
                    paydata: _this.employeesToBePaid,
                })
                .then(function () {
                    // _this.payrunView = 1;
                    // Fetch something
                })
                .catch(function () {
                    //
                })
                .then(function () {
                    //
                });
        },
        // fetchCompanyTransactionId(payrun_id) {
        //   const _this = this;
        //   axios.post(`${BASE_URL}fetchcompanytransactionid`, {
        //     company_id: _this.authCompanyData.id,
        //     payrun_id
        //   })
        //   .then(function (RESPONSE) {
        //     _this.batchId = RESPONSE.data.batch_id;
        //     if (RESPONSE.data.batch_id) {
        //       _this.batchIdExists = true;
        //       _this.transactionFetched = true;
        //     } else {
        //       _this.batchIdExists = false;
        //       _this.transactionFetched = false;
        //     }
        //   })
        //   .catch(function () {
        //     //
        //   })
        //   .then(function () {
        //     _this.fetchPayrunPaymentUsers(payrun_id);
        //   });
        // },
        // fetchTransactionByBatchId(batch_id) {
        //   const _this = this;
        //   if (_this.transactionFetched) {
        //     // _this.requeryTransactionByBatchId(batch_id);
        //     _this.requeryTransaction();
        //   } else {
        //     _this.fetchingTransaction = true;
        //     axios.post(`${BASE_URL}fetchtransactionbybatchid`, {
        //       batch_id
        //     })
        //     .then(function (RESPONSE) {
        //       if (RESPONSE.data.status == 1) {
        //         _this.transactionFetched = true;
        //       } else {
        //         _this.transactionFetched = false;
        //       }
        //     })
        //     .catch(function () {
        //       //
        //     })
        //     .then(function () {
        //       _this.fetchingTransaction = false;
        //       _this.fetchPayrunPaymentUsers(_this.currentPayRunId);
        //     });
        //   }
        // },
        requeryTransaction() {
            const _this = this;
            _this.fetchingTransaction = true;
            axios
                .post(`${BASE_URL}singlerequery`, {
                    paydata: _this.usersTransactionIds,
                })
                .then(function () {
                    //
                })
                .catch(function () {
                    //
                })
                .then(function () {
                    _this.fetchingTransaction = false;
                    _this.fetchPayrunPaymentUsers(_this.currentPayRunId);
                });
        },
        // requeryTransactionByBatchId(batch_id) {
        //   const _this = this;
        //   _this.fetchingTransaction = true;
        //   axios.post(`${BASE_URL}requery`, {
        //     batch_id
        //   })
        //   .then(function () {
        //     //
        //   })
        //   .catch(function () {
        //     //
        //   })
        //   .then(function () {
        //       _this.fetchingTransaction = false;
        //     _this.fetchPayrunPaymentUsers(_this.currentPayRunId);
        //   });
        // },
        fetchPayrunPaymentUsers(payrun_id) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}fetchpayrollpaymentusers`, {
                    company_id: _this.authCompanyData.id,
                    payrun_id,
                })
                .then(function (RESPONSE) {
                    _this.payrollPaymentUsers = RESPONSE.data.payrollpaymentusers.map((e) => {
                        return {
                            ...e,
                            paymentuser_id: e.id,
                            account_bank: e.bank_code,
                            checked: false,
                            pay_salary_text: "Pay",
                            pay_salary_loading: false,
                        };
                    });
                    _this.fetchingEmployeesForPayment = false;
                })
                .catch(function () {
                    Toast.fire({
                        icon: "error",
                        title: `Employees for payment couldn't be fetched. Try again.`,
                    });
                    _this.viewAllPayruns();
                })
                .then(function () {
                    //
                });
        },
        startInterval() {
            const _this = this;
            _this.FLBalanceInterval = setInterval(() => {
                _this.fetchSubaccountBalance();
            }, 30000);
        },
        startInitialPaymentStatusInterval() {
            const _this = this;
            _this.initialPaymentStatusInterval = setInterval(() => {
                // if (_this.batchIdExists) {
                _this.requeryTransaction();
                // }
            }, 30000);
        },
        // startPaymentStatusInterval(batch_id) {
        //   const _this = this;
        //   _this.paymentStatusInterval = setInterval(() => {
        //     if (_this.fetchingTransaction === false) {
        //       _this.fetchTransactionByBatchId(batch_id);
        //     }
        //   }, 30000);
        // },
        allCheckboxesFunc() {
            const _this = this;
            if (_this.allChecked) {
                _this.payrollPaymentUsers.forEach((e) => (e.checked = true));
            } else {
                _this.payrollPaymentUsers.forEach((e) => (e.checked = false));
            }
        },
        fetchSubaccountBalance() {
            const _this = this;
            if (_this.companyHasSubaccount) {
                axios
                    .post(`${BASE_URL}fetchsubaccountbalance`, {
                        account_reference: _this.authCompanySubaccount.account_reference,
                    })
                    .then(function (RESPONSE) {
                        _this.subaccountCurrency = RESPONSE.data.subaccount.data.currency;
                        _this.subaccountAvailableBalance = RESPONSE.data.subaccount.data.available_balance;
                        _this.subaccountLedgerBalance = RESPONSE.data.subaccount.data.ledger_balance;
                    })
                    .catch(function (/*error*/) {
                        // Toast.fire({
                        //     icon: "error",
                        //     title: `The subaccount couldn't be fetched. Try again.`,
                        // });
                        // console.log(error)
                    })
                    .then(function () {
                        //
                    });
            }
        },
        getBankByName(bankName) {
            const _this = this;
            const [bank] = _this.allBanks.filter((bankParam) => bankParam.bank_name == bankName);
            return bank;
        },
        exportToExcel(payrun) {
            let _this = this;
            _this.employeesInPayCalendar(payrun.paycalendar_id).forEach(function (e) {
                _this.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
            });
            _this.employee_name = _this.allEmployees[0];
            _this.currentPayRunId = payrun.id;
            _this.currentPayCalendarId = payrun.paycalendar_id;
            setTimeout(() => {
                _this.excelSheetName = "Payrun Breakdown for " + _this.currentPayRun.month + ", " + _this.currentPayRun.year;
                setTimeout(() => {
                    document.getElementById("export-button").click();
                }, 1000);
            }, 1000);
        },
        exportCompanyPaymentsToExcel() {
            let _this = this;
            var currentDate = new Date().toUTCString().substring(0, 16);

            setTimeout(() => {
                _this.companyTransactionExcelSheetName = "Company Transactions as at " + currentDate;

                setTimeout(() => {
                    document.getElementById("export-company-transaction-button").click();
                }, 1000);
            }, 1000);
        },
        launchSalaryPayment(payrun) {
            const _this = this;
            _this.currentPayRunId = payrun.id;
            _this.currentPayCalendarId = payrun.paycalendar_id;
            _this.fetchingEmployeesForPayment = true;
            _this.payrunView = 5;
            _this.fetchPayrunPaymentUsers(payrun.id);
            _this.startInitialPaymentStatusInterval();
            // _this.fetchCompanyTransactionId(payrun.id);
        },
        paySalaries() {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.pay_salaries_text = "Processing ";
            _this.pay_salaries_loading = true;
            axios
                .post(`${BASE_URL}singlepayment`, {
                    company_id: _this.authCompanyData.id,
                    payrun_id: _this.currentPayRunId,
                    paydata: _this.trueEmployeesToBePaid,
                })
                .then(function (RESPONSE) {
                    Toast.fire({
                        icon: "success",
                        title: `Salary payment successful.`,
                    });
                    _this.fetchingEmployeesForPayment = true;
                    // Fetch something
                    _this.batchId = RESPONSE.data.transaction_id;
                    _this.requeryTransaction();
                    // _this.fetchTransactionByBatchId(_this.batchId);
                    // clearInterval(_this.initialPaymentStatusInterval);
                    // _this.startPaymentStatusInterval(RESPONSE.data.transaction_id);
                    _this.paymentSuspended = true;
                    setTimeout(() => {
                        _this.paymentSuspended = false;
                    }, 60000);
                })
                .catch(function () {
                    Toast.fire({
                        icon: "error",
                        title: `Payment couldn't be processed. Try again.`,
                    });
                })
                .then(function () {
                    _this.pay_salaries_loading = false;
                    _this.pay_salaries_text = "Pay Salaries";
                });
        },
        paySingleSalary(employeeId, index) {
            const _this = this;
            // _this.payrollPaymentUsers[index];
            const employeeToBePaid = _this.verifiedEmployeesToBePaid.filter((e) => e.employee_id == employeeId)[0];
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.payrollPaymentUsers[index].pay_salary_text = "";
            _this.pay_salaries_loading = true;
            axios
                .post(`${BASE_URL}singlepayment`, {
                    company_id: _this.authCompanyData.id,
                    payrun_id: _this.currentPayRunId,
                    paydata: [employeeToBePaid],
                })
                .then(function (RESPONSE) {
                    Toast.fire({
                        icon: "success",
                        title: `Salary payment successful.`,
                    });
                    _this.fetchingEmployeesForPayment = true;
                    // Fetch something
                    _this.batchId = RESPONSE.data.transaction_id;
                    _this.requeryTransaction();
                    // _this.fetchTransactionByBatchId(_this.batchId);
                    // clearInterval(_this.initialPaymentStatusInterval);
                    // _this.startPaymentStatusInterval(RESPONSE.data.transaction_id);
                    _this.paymentSuspended = true;
                    setTimeout(() => {
                        _this.paymentSuspended = false;
                    }, 60000);
                })
                .catch(function () {
                    Toast.fire({
                        icon: "error",
                        title: `Payment couldn't be processed. Try again.`,
                    });
                })
                .then(function () {
                    _this.payrollPaymentUsers[index].pay_salary_loading = false;
                    _this.payrollPaymentUsers[index].pay_salary_text = "Pay";
                });
        },
        triggerPayrunSummaryAltPrint(payrun) {
            let _this = this;
            _this.employeesInPayCalendar(payrun.paycalendar_id).forEach(function (e) {
                _this.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
            });
            _this.employee_name = _this.allEmployees[0];
            _this.currentPayRunId = payrun.id;
            _this.currentPayCalendarId = payrun.paycalendar_id;
            setTimeout(() => {
                _this.printPayrunSummaryAlt();
            }, 500);
        },
        triggerCompanyTransactionPrint() {
            let _this = this;
            _this.dateNow = new Date().toUTCString().substring(0, 16);
            setTimeout(() => {
                _this.printCompanyTransaction();
            }, 500);
        },
        async printPayrunSummaryAlt() {
            let _this = this;
            await _this.$htmlToPaper("printPayrunSummaryAlt");
        },
        async printCompanyTransaction() {
            let _this = this;
            await _this.$htmlToPaper("printCompanyTransaction");
        },
        getEmployerPensionDeduction(amount) {
            let initFigure = 0,
                value = 0;
            initFigure = (amount * 100) / 8;
            value = (10 / 100) * initFigure;
            return value;
        },
        getEmployerNHISDeduction(amount) {
            let initFigure = 0,
                value = 0;
            initFigure = (amount * 100) / 5;
            value = (10 / 100) * initFigure;
            return value;
        },
        viewPayrunInfo(payrun) {
            let _this = this;
            _this.employeesInPayCalendar(payrun.paycalendar_id).forEach(function (e) {
                _this.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
            });
            _this.employee_name = _this.allEmployees[0];
            _this.currentPayRunId = payrun.id;
            _this.currentPayCalendarId = payrun.paycalendar_id;
            _this.payrunView = 4;
        },
        statutoryDeductionExists(name) {
            let _this = this,
                result = [],
                value = false;
            result = _this.allStatutoryDeductions.filter((d) => d.name.toLowerCase() == name.toLowerCase());
            if (result.length) {
                value = true;
            }
            return value;
        },
        getPensionDeduction(levelSalary) {
            let _this = this,
                statutoryDeduction = null,
                basicSalary = 0,
                housingSalary = 0,
                transportSalary = 0;
            let deductableFigure = 0,
                finalDeduction = 0,
                annualSalary = levelSalary * 12,
                monthlyDeduction = 0;

            statutoryDeduction = _this.allStatutoryDeductions.filter((d) => d.name.toLowerCase() == "pension");
            if (statutoryDeduction.length) {
                statutoryDeduction = statutoryDeduction[0];

                basicSalary = (_this.basicSalaryPercentage / 100) * parseFloat(annualSalary);
                housingSalary = (_this.housingSalaryPercentage / 100) * parseFloat(annualSalary);
                transportSalary = (_this.transportSalaryPercentage / 100) * parseFloat(annualSalary);

                deductableFigure = basicSalary + housingSalary + transportSalary;

                finalDeduction = (parseFloat(statutoryDeduction.percentage) / 100) * deductableFigure;

                monthlyDeduction = finalDeduction / 12;

                return monthlyDeduction;
            } else {
                return 0;
            }
        },
        getNHFDeduction(levelSalary) {
            let _this = this,
                statutoryDeduction = null,
                basicSalary = 0;
            let deductableFigure = 0,
                finalDeduction = 0,
                annualSalary = levelSalary * 12,
                monthlyDeduction = 0;

            statutoryDeduction = _this.allStatutoryDeductions.filter((d) => d.name.toLowerCase() == "nhf");
            if (statutoryDeduction.length) {
                statutoryDeduction = statutoryDeduction[0];

                basicSalary = (_this.basicSalaryPercentage / 100) * parseFloat(annualSalary);

                deductableFigure = basicSalary;

                finalDeduction = (parseFloat(statutoryDeduction.percentage) / 100) * deductableFigure;

                monthlyDeduction = finalDeduction / 12;

                return monthlyDeduction;
            } else {
                return 0;
            }
        },
        getNHISDeduction(levelSalary) {
            let _this = this,
                statutoryDeduction = null,
                basicSalary = 0;
            let deductableFigure = 0,
                finalDeduction = 0,
                annualSalary = levelSalary * 12,
                monthlyDeduction = 0;

            statutoryDeduction = _this.allStatutoryDeductions.filter((d) => d.name.toLowerCase() == "nhis");
            if (statutoryDeduction.length) {
                statutoryDeduction = statutoryDeduction[0];

                basicSalary = (_this.basicSalaryPercentage / 100) * parseFloat(annualSalary);

                deductableFigure = basicSalary;

                finalDeduction = (parseFloat(statutoryDeduction.percentage) / 100) * deductableFigure;

                monthlyDeduction = finalDeduction / 12;

                return monthlyDeduction;
            } else {
                return 0;
            }
        },
        getNSITFDeduction(levelSalary) {
            let _this = this,
                statutoryDeduction = null,
                basicSalary = 0,
                housingSalary = 0,
                transportSalary = 0;
            let deductableFigure = 0,
                finalDeduction = 0,
                annualSalary = levelSalary * 12,
                monthlyDeduction = 0;

            statutoryDeduction = _this.allStatutoryDeductions.filter((d) => d.name.toLowerCase() == "nsitf");
            if (statutoryDeduction.length) {
                statutoryDeduction = statutoryDeduction[0];

                basicSalary = (_this.basicSalaryPercentage / 100) * parseFloat(annualSalary);
                housingSalary = (_this.housingSalaryPercentage / 100) * parseFloat(annualSalary);
                transportSalary = (_this.transportSalaryPercentage / 100) * parseFloat(annualSalary);

                deductableFigure = basicSalary + housingSalary + transportSalary;

                finalDeduction = (parseFloat(statutoryDeduction.percentage) / 100) * deductableFigure;

                monthlyDeduction = finalDeduction / 12;

                return monthlyDeduction;
            } else {
                return 0;
            }
        },
        getPAYEDeduction(levelSalary /*, employee_id*/) {
            let _this = this,
                statutoryDeduction = null,
                basicSalary = 0,
                housingSalary = 0,
                transportSalary = 0;
            let taxableFigure = 0,
                taxPayable = 0,
                annualSalary = levelSalary * 12,
                monthlyDeduction = 0,
                CRA = 0;

            statutoryDeduction = _this.allStatutoryDeductions.filter((d) => d.name.toLowerCase() == "paye");
            // CHECK IF BASIC SALARY EMOLUMENT IS LESS THAN THE MINIMUM WAGE (30000)
            if (statutoryDeduction.length && (_this.basicSalaryPercentage / 100) * parseFloat(levelSalary) >= _this.minimumWage) {
                statutoryDeduction = statutoryDeduction[0];

                basicSalary = (_this.basicSalaryPercentage / 100) * parseFloat(annualSalary);
                housingSalary = (_this.housingSalaryPercentage / 100) * parseFloat(annualSalary);
                transportSalary = (_this.transportSalaryPercentage / 100) * parseFloat(annualSalary);

                taxableFigure = basicSalary + housingSalary + transportSalary;

                // START PAYE COMPUTATION

                _this.allStatutoryDeductions.forEach((deduction) => {
                    if (deduction.name.toLowerCase() == "pension") {
                        taxableFigure -= _this.getPensionDeduction(levelSalary) * 12;
                    } else if (deduction.name.toLowerCase() == "nhf") {
                        taxableFigure -= _this.getNHFDeduction(levelSalary) * 12;
                    } else if (deduction.name.toLowerCase() == "nhis") {
                        taxableFigure -= _this.getNHISDeduction(levelSalary) * 12;
                    } else if (deduction.name.toLowerCase() == "nsitf") {
                        taxableFigure -= _this.getNSITFDeduction(levelSalary) * 12;
                    } else {
                        taxableFigure -= 0;
                    }
                });

                // REMOVE FLEXIBLE DEDUCTIONS
                // taxableFigure -= _this.totalEmployeesFlexibleDeductions(employee_id);

                // REMOVE 20% OF GROSS INCOME FOR CONSOLIDATED RELIEF ALLOWANCE
                CRA = (20 / 100) * taxableFigure;
                // REMOVE HIGHER OF 200000 OR 1% OF GROSS INCOME FOR CONSOLIDATED RELIEF ALLOWANCE
                CRA += 200000 > (1 / 100) * taxableFigure ? 200000 : (1 / 100) * taxableFigure;
                // REMOVE CONSOLIDATED RELIEF ALLOWANCE
                // USE 1000000 AS EXAMPLE
                taxableFigure -= CRA;

                // 1ST COMPUTATION
                if (taxableFigure >= 1) {
                    if (taxableFigure >= 300000) {
                        taxPayable += (7 / 100) * 300000;
                        taxableFigure -= 300000;
                    } else {
                        taxPayable += (7 / 100) * taxableFigure;
                        taxableFigure -= taxableFigure;
                    }
                }

                // 2ND COMPUTATION
                if (taxableFigure >= 1) {
                    if (taxableFigure >= 300000) {
                        taxPayable += (11 / 100) * 300000;
                        taxableFigure -= 300000;
                    } else {
                        taxPayable += (11 / 100) * taxableFigure;
                        taxableFigure -= taxableFigure;
                    }
                }

                // 3RD COMPUTATION
                if (taxableFigure >= 1) {
                    if (taxableFigure >= 500000) {
                        taxPayable += (15 / 100) * 500000;
                        taxableFigure -= 500000;
                    } else {
                        taxPayable += (15 / 100) * taxableFigure;
                        taxableFigure -= taxableFigure;
                    }
                }

                // 4TH COMPUTATION
                if (taxableFigure >= 1) {
                    if (taxableFigure >= 500000) {
                        taxPayable += (19 / 100) * 500000;
                        taxableFigure -= 500000;
                    } else {
                        taxPayable += (19 / 100) * taxableFigure;
                        taxableFigure -= taxableFigure;
                    }
                }

                // 5TH COMPUTATION
                if (taxableFigure >= 1) {
                    if (taxableFigure >= 1600000) {
                        taxPayable += (21 / 100) * 1600000;
                        taxableFigure -= 1600000;
                    } else {
                        taxPayable += (21 / 100) * taxableFigure;
                        taxableFigure -= taxableFigure;
                    }
                }

                // 6TH COMPUTATION
                if (taxableFigure >= 1) {
                    taxPayable += (24 / 100) * taxableFigure;
                    taxableFigure -= taxableFigure;
                }

                // END PAYE COMPUTATION

                monthlyDeduction = taxPayable / 12;

                return monthlyDeduction;
            } else {
                return 0;
            }
        },
        getStatutoryDeduction(id) {
            let _this = this,
                result = null;
            result = _this.authStatutoryDeductions.filter((d) => d.id == id);
            if (result.length) {
                result = result[0];
            }
            return result;
        },
        currentPayGrade(employee_id) {
            let _this = this,
                result = null;
            result = _this.authCompanyPayGrades.filter((pg) => {
                return _this.singleEmployeeObject(employee_id) ? _this.singleEmployeeObject(employee_id).paygrade_id == pg.id : false;
            });
            if (result.length) {
                return result[0];
            }
            return result;
        },
        currentGradeLevel(employee_id) {
            let _this = this,
                result = null;
            result = _this.authCompanyGradeLevels.filter((level) => {
                return _this.singleEmployeeObject(employee_id) ? _this.singleEmployeeObject(employee_id).gradelevel_id == level.id : false;
            });
            if (result.length) {
                return result[0];
            }
            return result;
        },
        getEmployeeLoans(employee_id) {
            let _this = this,
                result = [];
            result = _this.authCompanyLoans.filter((loan) => loan.employee_id == employee_id && loan.status == 1 && loan.monthly_repayable_amount != 0 && loan.loan_amount != 0 && _this.loanIsValidForDeduction(loan.fromdate));
            return result;
        },
        loanIsValidForDeduction(loanStartDate) {
            const loanStartDateArray = loanStartDate.split("-");
            const newLoanStartDate = `${loanStartDateArray[0]}-${loanStartDateArray[1]}-01`;
            const currentDateArray = new Date().toISOString().split("-");
            const newCurrentDate = `${currentDateArray[0]}-${currentDateArray[1]}-01`;
            return new Date(newCurrentDate) >= new Date(newLoanStartDate);
        },
        async print() {
            // Pass the element id here
            await this.$htmlToPaper("printMe");
        },
        async printSummary() {
            // Pass the element id here
            await this.$htmlToPaper("printPayrunSummary");
        },
        hasHistory: function () {
            return window.history.length > 2;
        },
        save: function (t) {
            this.$refs.menu.save(t);
        },
        save2: function (t) {
            this.$refs.menu2.save(t);
        },
        save3: function (t) {
            this.$refs.menu3.save(t);
        },
        save4: function (t) {
            this.$refs.menu4.save(t);
        },
        viewAllPayruns() {
            this.payrunView = 2;
            this.steps = 1;
            clearInterval(this.initialPaymentStatusInterval);
        },
        viewCompanyTrasactions() {
            this.payrunView = 6;
            this.steps = 1;
            clearInterval(this.initialPaymentStatusInterval);
        },
        // exportToPDF () {
        //   html2pdf(this.$refs.PDFDocument, {
        //     margin: 1,
        //     filename: 'document.pdf',
        //     image: { type: 'jpeg', quality: 0.98 },
        //     html2canvas: { dpi: 192, letterRendering: true },
        //     jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
        //   })
        // },
        get_dept_name(dept_id) {
            let _this = this,
                dept;
            if (_this.authCompanyDepartments) {
                dept = _this.authCompanyDepartments.filter((dept) => {
                    return dept.id == dept_id;
                });
            }
            if (dept.length >= 1) {
                return dept[0].department_name;
            }
            return null;
        },
        totalAllowanceToPay(flexibles, salary) {
            let result = 0;
            if (flexibles.length >= 1) {
                flexibles.forEach((fa) => {
                    result += parseFloat(fa.allowance_amount);
                });
            }
            result += parseFloat(salary);
            return result;
        },
        delete_flexible_allowance(id) {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}removeemployeeallowance`, {
                    allowance_id: id,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Flexible allowance removed successfully",
                    });
                    _this.fetch_all_flexible_allowances();
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.allowance_id
                                    ? errors.allowance_id[0]
                                    : null
                            : null || error.response.data.error || error.response.data.message || "The flexible allowance couldn't be removed",
                    });
                })
                .then(function () {
                    //
                });
        },
        delete_flexible_deduction(id) {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}removeemployeededuction`, {
                    deduction_id: id,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Flexible deduction removed successfully",
                    });
                    _this.fetch_all_flexible_deductions();
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.deduction_id
                                    ? errors.deduction_id[0]
                                    : null
                            : null || error.response.data.error || error.response.data.message || "The flexible deduction couldn't be removed",
                    });
                })
                .then(function () {
                    //
                });
        },
        trueApprovePayrun() {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}approvepayrun`, {
                    payrun_id: _this.currentPayRunId,
                    total_net_pay: _this.totalNetPay,
                })
                .then(function () {
                    _this.storePayrunEmployeesForPayments(_this.currentPayRunId);
                    _this.add_loan_repayments();
                    _this.addPayslipData();
                    _this.viewAllPayruns();
                })
                .catch(function (error) {
                    let errors = (error.response ? error.response.data.error : null) || null;
                    Toast.fire({
                        icon: "error",
                        title: errors ? (typeof errors === "string" ? errors : null || errors.payrun_id ? errors.payrun_id[0] : null) : null || error.response.data.error || error.response.data.message || "Payrun could not be approved",
                    });
                })
                .then(function () {
                    //
                });
        },
        add_statutory_deductions_as_flexible_deductions() {
            let _this = this,
                employeesInCalendar = _this.employeesInPayCalendar(_this.currentPayCalendar.id);

            employeesInCalendar.forEach((e, employeeIndex) => {
                if (employeeIndex == employeesInCalendar.length - 1 && _this.allStatutoryDeductions.length == 0) {
                    _this.trueApprovePayrun();
                } else {
                    _this.allStatutoryDeductions.forEach((deduction, index) => {
                        axios
                            .post(`${BASE_URL}createemployeededuction`, {
                                company_id: _this.authCompanyData.id,
                                paycalendar_id: _this.currentPayCalendar.id,
                                payrun_id: _this.currentPayRunId,
                                employee_id: e.employee_id,
                                deduction_name: deduction.name,
                                statutory_deduction: deduction.name,
                                deduction_amount:
                                    deduction.name.toLowerCase() == "pension"
                                        ? _this.getPensionDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0)
                                        : deduction.name.toLowerCase() == "nhf"
                                            ? _this.getNHFDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0)
                                            : deduction.name.toLowerCase() == "nhis"
                                                ? _this.getNHISDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0)
                                                : deduction.name.toLowerCase() == "nsitf"
                                                    ? _this.getNSITFDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0)
                                                    : deduction.name.toLowerCase() == "paye"
                                                        ? _this.getPAYEDeduction((_this.currentGradeLevel(e.employee_id) ? _this.currentGradeLevel(e.employee_id).salary : 0) || 0)
                                                        : 0,
                                note: deduction.description,
                            })
                            .then(function () {
                                //
                            })
                            .catch(function () {
                                // let errors = error.response.data.error || null;
                            })
                            .then(function () {
                                if (employeeIndex == employeesInCalendar.length - 1 && index == _this.allStatutoryDeductions.length - 1) {
                                    _this.trueApprovePayrun();
                                }
                            });
                    });
                }
            });
        },
        add_loan_repayments() {
            let _this = this,
                employeesInCalendar = _this.employeesInPayCalendar(_this.currentPayCalendar.id);
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            employeesInCalendar.forEach((e, employeeIndex) => {
                let employeeLoans = _this.employeesFlexibleDeductions(e.employee_id).filter(deduction => deduction.deduction_name == 'Loan Repayment');
                if (employeeIndex == employeesInCalendar.length - 1 && employeeLoans.length == 0) {
                    _this.approve_payrun_submit = false;
                    _this.approve_payrun_text = "Approve";
                    Toast.fire({
                        icon: "success",
                        title: "Payrun approved successfully.",
                    });
                } else {
                    if (employeeLoans.length >= 1) {
                        employeeLoans.forEach((loan, loanIndex) => {
                            const trueLoan = _this.authCompanyLoans.filter(l => {
                                return (
                                    l.employee_id == loan.employee_id
                                    &&
                                    l.purpose == loan.note
                                    &&
                                    l.status == 1
                                    &&
                                    l.loan_amount != 0
                                );
                            })[0];
                            axios
                                .post(`${BASE_URL}addloanrepayment`, {
                                    company_id: _this.authCompanyData.id,
                                    payrun_id: _this.currentPayRunId,
                                    employee_id: e.employee_id,
                                    amount: loan.deduction_amount,
                                    loan_id: trueLoan.id,
                                })
                                .then(function () {
                                    _this.fetch_all_company_loans();
                                })
                                .catch(function () {
                                    // let errors = error.response.data.error || null;
                                })
                                .then(function () {
                                    if (employeeIndex == employeesInCalendar.length - 1 && loanIndex == employeeLoans.length - 1) {
                                        _this.approve_payrun_submit = false;
                                        _this.approve_payrun_text = "Approve";
                                        Toast.fire({
                                            icon: "success",
                                            title: "Payrun approved successfully.",
                                        });
                                    }
                                });
                        });
                    } else {
                        if (employeeIndex == employeesInCalendar.length - 1) {
                            _this.approve_payrun_submit = false;
                            _this.approve_payrun_text = "Approve";
                            Toast.fire({
                                icon: "success",
                                title: "Payrun approved successfully.",
                            });
                        }
                    }
                }
            });
        },
        add_flexible_deduction() {
            let _this = this;
            _this.add_flexible_deduction_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}createemployeededuction`, {
                    company_id: _this.authCompanyData.id,
                    paycalendar_id: _this.currentPayCalendar.id,
                    payrun_id: _this.currentPayRunId,
                    employee_id: _this.employee_id,
                    deduction_name: _this.flexibleDeductionForm.deduction_name,
                    deduction_amount: _this.flexibleDeductionForm.deduction_amount,
                    note: _this.flexibleDeductionForm.note,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Flexible deduction added successfully",
                    });
                    _this.fetch_all_flexible_deductions();
                    _this.flexibleDeductionForm.deduction_name = "";
                    _this.flexibleDeductionForm.deduction_amount = "";
                    _this.flexibleDeductionForm.note = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.deduction_name
                                    ? errors.deduction_name[0]
                                    : null || errors.deduction_amount
                                        ? errors.deduction_amount[0]
                                        : null
                            : null || error.response.data.error || error.response.data.message || "The flexible deduction couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_flexible_deduction_submit = false;
                });
        },
        add_flexible_allowance() {
            let _this = this;
            _this.add_flexible_allowance_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}createemployeeallowance`, {
                    company_id: _this.authCompanyData.id,
                    paycalendar_id: _this.currentPayCalendar.id,
                    payrun_id: _this.currentPayRunId,
                    employee_id: _this.employee_id,
                    allowance_name: _this.flexibleAllowanceForm.allowance_name,
                    allowance_amount: _this.flexibleAllowanceForm.allowance_amount,
                    note: _this.flexibleAllowanceForm.note,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Flexible allowance added successfully",
                    });
                    _this.fetch_all_flexible_allowances();
                    _this.flexibleAllowanceForm.allowance_name = "";
                    _this.flexibleAllowanceForm.allowance_amount = "";
                    _this.flexibleAllowanceForm.note = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.allowance_name
                                    ? errors.allowance_name[0]
                                    : null || errors.allowance_amount
                                        ? errors.allowance_amount[0]
                                        : null
                            : null || error.response.data.error || error.response.data.message || "The flexible allowance couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_flexible_allowance_submit = false;
                });
        },
        addPayslipData() {
            let _this = this;
            axios
                .post(`${BASE_URL}addemployeepayslipdata`, {
                    employeepayslips: _this.payslipEmployees,
                    deductions: _this.payslipDeductions,
                    allowances: _this.payslipAllowances,
                    taxes: [],
                })
                .then(function () {
                    _this.fetch_all_company_payrun();
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    _this.approve_payrun_submit = false;
                    _this.approve_payrun_text = "Approve";
                });
        },
        approvePayRun() {
            let _this = this;

            _this.$swal
                .fire({
                    title: "Are you sure you want to approve this payrun?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Approve`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        _this.approve_payrun_text = "";
                        _this.approve_payrun_submit = true;
                        _this.add_statutory_deductions_as_flexible_deductions();
                    }
                });
        },
        disapprovePayRun() {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.$swal
                .fire({
                    title: "Are you sure you want to undo approval?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Undo`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        _this.disapprove_payrun_text = "";
                        _this.disapprove_payrun_submit = true;

                        axios
                            .post(`${BASE_URL}disapprovepayrun`, {
                                company_id: _this.authCompanyData.id,
                                payrun_id: _this.currentPayRunId,
                            })
                            .then(function (response) {
                                console.log(response.data);
                                Toast.fire({
                                    icon: "success",
                                    title: "Payrun disapproved successfully.",
                                });
                                _this.fetch_all_company_payrun();
                                _this.payrunView = 2;
                                // _this.steps = 1;
                            })
                            .catch(function (error) {
                                let errors = (error.response ? error.response.data.error : null) || null;
                                Toast.fire({
                                    icon: "error",
                                    title: errors
                                        ? typeof errors === "string"
                                            ? errors
                                            : null || errors.payrun_id
                                                ? errors.payrun_id[0]
                                                : null
                                        : null || error.response.data.error || error.response.data.message || "Payrun could not be approved",
                                });
                            })
                            .then(function () {
                                _this.disapprove_payrun_submit = false;
                                _this.disapprove_payrun_text = "Undo Approval";
                            });
                    }
                });
        },
        async add_payrun() {
            let _this = this;
            const valid = await _this.$refs.form1.validate();
            if (!valid) {
                return;
            }

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (_this.currentPayRunId) {
                _this.employeesInPayCalendar(_this.currentPayCalendar.id).forEach(function (e) {
                    _this.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
                });
                _this.employee_name = _this.allEmployees[0];
                _this.steps = 2;
            } else {
                _this.$swal
                    .fire({
                        title: "Are you sure you want to create this payrun?",
                        showCancelButton: true,
                        confirmButtonText: `Yes, Create`,
                    })
                    .then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            _this.add_payrun_text = "";
                            _this.add_payrun_submit = true;

                            axios
                                .post(`${BASE_URL}createpayrun`, {
                                    company_id: _this.authCompanyData.id,
                                    paycalendar_id: _this.currentPayCalendar.id,
                                    from_date: _this.payrunForm.from_date,
                                    to_date: _this.payrunForm.to_date,
                                    payment_date: _this.payrunForm.payment_date,
                                })
                                .then(function (response) {
                                    _this.add_loans_as_flexible_deductions(response.data.payruns.id);
                                    _this.currentPayRunId = response.data.payruns.id;
                                    _this.fetch_all_company_payrun();
                                    _this.employeesInPayCalendar(_this.currentPayCalendar.id).forEach(function (e) {
                                        _this.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
                                    });
                                    _this.employee_name = _this.allEmployees[0];

                                    if (_this.subscribedToAttendance && _this.authCompanyAttendanceDeductionSettings) {
                                        _this.loadAttendanceRecords(_this.currentPayCalendarId, response.data.payruns.id);
                                    } else {
                                        Toast.fire({
                                            icon: "success",
                                            title: "Payrun was created successfully.",
                                        });
                                        _this.steps = 2;
                                    }
                                })
                                .catch(function (error) {
                                    let errors = error.response.data.error || null;
                                    Toast.fire({
                                        icon: "error",
                                        title: errors
                                            ? typeof errors === "string"
                                                ? errors
                                                : null || errors.from_date
                                                    ? errors.from_date[0]
                                                    : null || errors.to_date
                                                        ? errors.to_date[0]
                                                        : null || errors.payment_date
                                                            ? errors.payment_date[0]
                                                            : null
                                            : null || error.response.data.error || error.response.data.message || "Payrun could not be created",
                                    });
                                })
                                .then(function () {
                                    if (!_this.subscribedToAttendance || !_this.authCompanyAttendanceDeductionSettings) {
                                        _this.add_payrun_submit = false;
                                        _this.add_payrun_text = "Next";
                                    }
                                });
                        }
                    });
            }
        },
        deletePayRun(p) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to delete this payrun?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Delete`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deletepayrun`, {
                                payrun_id: p.id,
                            })
                            .then(function (response) {
                                console.log(response.data);
                                Toast.fire({
                                    icon: "success",
                                    title: "Payrun was deleted successfully.",
                                });
                                _this.fetch_all_company_payrun();
                            })
                            .catch(function (error) {
                                let errors = error.response.data.error || null;
                                Toast.fire({
                                    icon: "error",
                                    title: errors
                                        ? typeof errors === "string"
                                            ? errors
                                            : null || errors.payrun_id
                                                ? errors.payrun_id[0]
                                                : null
                                        : null || error.response.data.error || error.response.data.message || "Payrun could not be deleted",
                                });
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        startPayrun(pc) {
            let _this = this;
            _this.payrunForm.from_date = "";
            _this.payrunForm.to_date = "";
            _this.payrunForm.payment_date = "";
            _this.currentPayRunId = null;
            _this.currentPayCalendarId = pc.id;
            _this.employeesDataInCalendar = _this.employeesInPayCalendar(pc.id);
            _this.payrunView = 3;
        },
        editPayRun(p) {
            let _this = this;
            _this.payrunView = 3;
            _this.currentPayCalendarId = p.paycalendar_id;
            _this.currentPayRunId = p.id;
            _this.payrunForm.from_date = p.from_date;
            _this.payrunForm.to_date = p.to_date;
            _this.payrunForm.payment_date = p.payment_date;
            _this.employeesDataInCalendar = _this.employeesInPayCalendar(p.paycalendar_id);
        },
        add_loans_as_flexible_deductions(payrunId) {
            let _this = this,
                employeesInCalendar = _this.employeesInPayCalendar(_this.currentPayCalendar.id);

            employeesInCalendar.forEach((e, employeeIndex) => {
                let employeeLoans = _this.getEmployeeLoans(e.employee_id);
                if (employeeIndex == employeesInCalendar.length - 1 && employeeLoans.length == 0) {
                    _this.fetch_all_flexible_deductions();
                } else {
                    if (employeeLoans.length >= 1) {
                        employeeLoans.forEach((loan, loanIndex) => {
                            axios
                                .post(`${BASE_URL}createemployeededuction`, {
                                    company_id: _this.authCompanyData.id,
                                    paycalendar_id: _this.currentPayCalendar.id,
                                    payrun_id: payrunId,
                                    employee_id: e.employee_id,
                                    deduction_name: "Loan Repayment",
                                    deduction_amount: loan.loan_amount == 0 ? 0 : parseFloat(loan.liquidation_amount) || parseFloat(loan.monthly_repayable_amount) || 0,
                                    note: loan.purpose,
                                })
                                .then(function () {
                                    //
                                })
                                .catch(function () {
                                    // let errors = error.response.data.error || null;
                                })
                                .then(function () {
                                    if (employeeIndex == employeesInCalendar.length - 1 && loanIndex == employeeLoans.length - 1) {
                                        _this.fetch_all_flexible_deductions();
                                    }
                                });
                        });
                    } else {
                        if (employeeIndex == employeesInCalendar.length - 1) {
                            _this.fetch_all_flexible_deductions();
                        }
                    }
                }
            });
        },
        add_attendance_deductions_as_flexible_deductions(attendanceRecords, payrunId) {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            attendanceRecords.forEach((record, recordIndex) => {
                let latenessFee = 0, absenceFee = 0;
                if (_this.authCompanyAttendanceDeductionSettings.fixed_deduction == 1) {
                    // If the deductions are fixed
                    latenessFee = _this.authCompanyAttendanceDeductionSettings.lateness_penalty;
                    absenceFee = _this.authCompanyAttendanceDeductionSettings.absence_penalty;
                } else {
                    // If the deductions are flexible
                    const employeeSalary = _this.employeeNetPay(record.employee_id);
                    latenessFee = (_this.authCompanyAttendanceDeductionSettings.lateness_penalty / 100) * employeeSalary;
                    absenceFee = (_this.authCompanyAttendanceDeductionSettings.absence_penalty / 100) * employeeSalary;
                }
                record.attendance_records.forEach((attendanceRecord, attendanceRecordIndex) => {
                    const feeToPay = attendanceRecord.status === 'late' ? latenessFee : absenceFee;
                    if (feeToPay > 0) {
                        axios.post(`${BASE_URL}createemployeededuction`, {
                            company_id: _this.authCompanyData.id,
                            paycalendar_id: _this.currentPayCalendar.id,
                            payrun_id: payrunId,
                            employee_id: record.employee_id,
                            deduction_name: (attendanceRecord.status === 'late' ? 'Lateness Penalty' : 'Absence Penalty') + ' (' + attendanceRecord.day + ')',
                            deduction_amount: feeToPay,
                            note: attendanceRecord.punch_in_note
                        })
                        .then(function () {
                            //
                        })
                        .catch(function () {
                            //
                        })
                        .then(function () {
                            if (recordIndex == attendanceRecords.length - 1 && attendanceRecordIndex == record.attendance_records.length - 1) {
                                _this.fetch_all_flexible_deductions().then(() => {
                                    _this.add_payrun_submit = false;
                                    _this.add_payrun_text = "Next";
                                    Toast.fire({
                                        icon: "success",
                                        title: "Payrun was created successfully.",
                                    });
                                    _this.steps = 2;
                                });
                            }
                        });
                    } else {
                        if (recordIndex == attendanceRecords.length - 1 && attendanceRecordIndex == record.attendance_records.length - 1) {
                            _this.fetch_all_flexible_deductions().then(() => {
                                _this.add_payrun_submit = false;
                                _this.add_payrun_text = "Next";
                                Toast.fire({
                                    icon: "success",
                                    title: "Payrun was created successfully.",
                                });
                                _this.steps = 2;
                            });
                        }
                    }
                });
            });
        },
        getCompanyPensionForEmployee(employee_id) {
            let _this = this,
                pension_deductions = [],
                value = 0;
            pension_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(employee_id).filter((deduction) => {
                return deduction.deduction_name.toLowerCase() == "pension";
            });
            pension_deductions.filter((d) => d.payrun_id == _this.currentPayRunId);
            pension_deductions.forEach((d) => {
                value += _this.getEmployerPensionDeduction(parseFloat(d.deduction_amount));
            });
            return value;
        },
        getCompanyNHISForEmployee(employee_id) {
            let _this = this,
                nhis_deductions = [],
                value = 0;
            nhis_deductions = _this.trueEmployeesFlexibleStatutoryDeductions(employee_id).filter((deduction) => {
                return deduction.deduction_name.toLowerCase() == "nhis";
            });
            nhis_deductions.filter((d) => d.payrun_id == _this.currentPayRunId);
            nhis_deductions.forEach((d) => {
                value += _this.getEmployerNHISDeduction(parseFloat(d.deduction_amount));
            });
            return value;
        },
        totalEmployeesFlexibleAllowances(employee_id) {
            let _this = this,
                allowances = _this.employeesFlexibleAllowances(employee_id),
                value = 0;
            allowances.forEach((a) => {
                value += parseFloat(a.allowance_amount);
            });
            return value;
        },
        employeesFlexibleAllowances(employee_id) {
            let _this = this,
                result = [];
            if (_this.authFlexibleAllowances.length >= 1) {
                result = _this.authFlexibleAllowances.filter((fa) => {
                    return fa.employee_id == employee_id && fa.payrun_id == _this.currentPayRunId && fa.paycalendar_id == _this.currentPayCalendar.id;
                });
            }
            return result;
        },
        totalEmployeesFlexibleDeductions(employee_id) {
            let _this = this,
                deductions = _this.employeesFlexibleDeductions(employee_id),
                value = 0;
            deductions.forEach((d) => {
                if (_this.currentPayRun) {
                    if (_this.currentPayRun.approved == 1) {
                        value += parseFloat(d.deduction_amount);
                    } else {
                        if (!d.statutory_deduction) {
                            value += parseFloat(d.deduction_amount);
                        }
                    }
                }
            });
            return value;
        },
        employeesFlexibleDeductions(employee_id) {
            let _this = this,
                result = [];
            if (_this.authFlexibleDeductions.length >= 1) {
                result = _this.authFlexibleDeductions.filter((fd) => {
                    return fd.employee_id == employee_id && fd.payrun_id == _this.currentPayRunId && fd.paycalendar_id == _this.currentPayCalendar.id;
                });
            }
            return result;
        },
        trueEmployeesFlexibleDeductions(employee_id) {
            let _this = this,
                result = [];
            result = _this.employeesFlexibleDeductions(employee_id).filter((deduction) => {
                return !!deduction.statutory_deduction === false;
            });
            return result;
        },
        totalTrueEmployeesFlexibleDeductions(employee_id) {
            let _this = this,
                deductions = _this.trueEmployeesFlexibleDeductions(employee_id),
                value = 0;
            deductions.forEach((d) => {
                value += parseFloat(d.deduction_amount);
            });
            return value;
        },
        trueEmployeesFlexibleStatutoryDeductions(employee_id) {
            let _this = this,
                result = [];
            result = _this.employeesFlexibleDeductions(employee_id).filter((deduction) => {
                return !!deduction.statutory_deduction === true;
            });
            result.sort((a, b) => (a.deduction_name > b.deduction_name ? 1 : b.deduction_name > a.deduction_name ? -1 : 0));
            return result;
        },
        trueEmployersFlexibleStatutoryDeductions(employee_id) {
            let _this = this,
                result = [];
            result = _this.employeesFlexibleDeductions(employee_id).filter((deduction) => {
                return !!deduction.statutory_deduction === true && (deduction.deduction_name.toLowerCase() == "pension" || deduction.deduction_name.toLowerCase() == "nhis");
            });
            result.sort((a, b) => (a.deduction_name > b.deduction_name ? 1 : b.deduction_name > a.deduction_name ? -1 : 0));
            return result;
        },
        totalTrueEmployeesFlexibleStatutoryDeductions(employee_id) {
            let _this = this,
                deductions = _this.trueEmployeesFlexibleStatutoryDeductions(employee_id),
                value = 0;
            deductions.forEach((d) => {
                value += parseFloat(d.deduction_amount);
            });
            return value;
        },
        employeesInPayCalendar(id) {
            let _this = this,
                result = 0;
            if (_this.authEmployeePayCalendars.length >= 1) {
                result = _this.authEmployeePayCalendars.filter((pc) => pc.paycalendar_id == id);
            }
            return result;
        },
        singleEmployeeObject(id) {
            let _this = this,
                result = null;
            if (!id) {
                return result;
            }
            if (_this.true_employees.length >= 1) {
                result = _this.true_employees.filter((te) => te.id == id)[0];
            }
            return result;
        },
        taxEmployeeNetPay(levelSalary, employee_id) {
            let _this = this,
                value = 0;
            value =
                levelSalary -
                _this.getPensionDeduction(parseFloat(levelSalary)) -
                _this.getNHFDeduction(parseFloat(levelSalary)) -
                _this.getNHISDeduction(parseFloat(levelSalary)) -
                _this.getNSITFDeduction(parseFloat(levelSalary)) -
                _this.getPAYEDeduction(parseFloat(levelSalary), employee_id);
            return value;
        },
        taxTotalEmployeeDeductions(levelSalary, employee_id) {
            let _this = this,
                value = 0;
            if (_this.currentPayRun) {
                if (_this.currentPayRun.approved == 1) {
                    value = _this.totalEmployeesFlexibleDeductions(employee_id);
                } else {
                    value =
                        _this.totalEmployeesFlexibleDeductions(employee_id) +
                        _this.getPensionDeduction(parseFloat(levelSalary)) +
                        _this.getNHFDeduction(parseFloat(levelSalary)) +
                        _this.getNHISDeduction(parseFloat(levelSalary)) +
                        _this.getNSITFDeduction(parseFloat(levelSalary)) +
                        _this.getPAYEDeduction(parseFloat(levelSalary), employee_id);
                }
            }
            return value;
        },
        taxTotalEmployeeAllowances(employee_id) {
            let _this = this,
                value = 0;
            value = _this.totalEmployeesFlexibleAllowances(employee_id);
            return value;
        },
        finalTaxEmployeeNetPay(levelSalary, employee_id) {
            let _this = this,
                value = 0;
            value = parseFloat(levelSalary) + _this.taxTotalEmployeeAllowances(employee_id) - _this.taxTotalEmployeeDeductions(levelSalary, employee_id);
            return value;
        },
        newFinalEmployeeGrossPay(levelSalary, employee_id) {
            let _this = this,
                value = 0;
            value = parseFloat(levelSalary) + _this.taxTotalEmployeeAllowances(employee_id);
            return value;
        },
    },
    mounted() {
        document.title = "Kylian ERP - Payroll";
        if (this.$route.query.view == "list") {
            this.payrunView = 2;
        }
        // if (this.userType == 1 || this.permissionIsAssigned('travel-read')) {

        this.fetch_all_pay_calendar();

        this.fetch_all_employees_pay_calendar();
        this.fetch_company_subaccount();
        this.fetch_all_company_payrun();
        this.fetch_all_flexible_allowances();
        this.fetch_all_flexible_deductions();
        this.fetch_all_payroll_configuration();
        this.fetch_all_company_loans();
        this.fetch_company_pay_grade_histories();
        this.fetch_company_pay_grades();
        this.fetch_company_grade_levels();
        this.fetch_company_emoluments();
        this.fetch_statutory_deductions();
        this.fetch_company_statutory_deductions();

        // this.fetchSubaccountBalance();

        this.fetch_active_company_employees();
        this.fetch_all_approved_leave();
        this.fetch_all_check_in_all_time();

        this.startInterval();

        this.fetch_company_transactions();
    },
    beforeDestroy() {
        const _this = this;
        clearInterval(_this.FLBalanceInterval);
        // clearInterval(_this.paymentStatusInterval);
        clearInterval(_this.initialPaymentStatusInterval);
    },
};
</script>

<style scoped>
body {
    background: #fff !important;
}

.noShowOnPrint {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.underline:hover {
    text-decoration: underline;
    cursor: pointer;
}

.lead {
    font-size: 130%;
}

th,
td {
    text-align: center;
}
</style>

<style>
@media print {
    body {
        overflow: auto;
        height: auto;
    }

    .noShowOnPrint {
        display: none !important;
    }
}

.upload-medical-bill div div,
.upload-medical-bill-2 div div {
    cursor: pointer !important;
}

.mdi-calendar-month-outline {
    margin-right: 10px;
    color: #069 !important;
}

.remove-mb div .v-text-field__details {
    margin-bottom: 0px !important;
}
</style>
