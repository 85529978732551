<template>
  <v-card class="right-card pt-10 pb-10">
    <p class="card-p text-center">You selected</p>
    <h3 style="color: #069 !important;">30-day trial</h3>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
<style></style>
